import axios from "axios";
import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { Login } from "./Login";
import { Home } from "./Properties";
import { Property } from "./Property";
import { Reports } from "./Reports";
import { useEffect, useState } from "react";

import appImage from "./app.svg";

const PrivateRoute = ({ element }) => {
  const navigate = useNavigate();

  const [tokenExists, setTokenExists] = useState(false);

  useEffect(() => {
    if (!navigate) return;

    const token = localStorage.getItem("token");

    if (token) {
      try {
        (async () => {
          try {
            const response = await axios.get(
              "https://api.propertyjot.com/ping",
              {
                headers: { Authorization: token },
              }
            );

            if (response.status !== 200) {
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              navigate("/login");

              return;
            } else {
              if (response.data) {
                if (
                  response?.data?.email &&
                  typeof response?.data?.email === "string" &&
                  response?.data?.email?.endsWith("@huntleys.net") &&
                  response?.data?.name &&
                  typeof response?.data?.name === "string"
                ) {
                  localStorage.setItem("user", JSON.stringify(response.data));
                }
              }

              setTokenExists(true);
            }
          } catch (err) {
            console.error(err);
          }
        })();
      } catch (err) {
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");

          navigate("/login");

          return;
        }
      }

      setTokenExists(true);
    } else {
      navigate("/login");

      return;
    }
  }, [navigate]);

  if (!tokenExists) {
    return <div className="w-screen h-screen bg-stone-50" />;
  }

  return element;
};

const router = createBrowserRouter([
  {
    path: "/properties",
    element: <PrivateRoute element={<Home />} />,
  },
  {
    path: "/reports",
    element: <PrivateRoute element={<Reports />} />,
  },
  {
    path: "/property/:id",
    element: <PrivateRoute element={<Property />} />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "*",
    element: <Navigate to="/login" />,
  },
]);

function App() {
  return (
    <div className="w-screen h-screen">
      <RouterProvider router={router} />
      <div className="fixed flex-col top-0 left-0 lg:hidden bottom-0 right-0 z-[9999] bg-stone-50 bg-opacity-50 backdrop-blur-xl flex items-center justify-center">
        <div className="px-24 sm:px-32 md:px-36">
          <img
            alt="Person"
            src={appImage}
            className="w-full mx-auto aspect-square"
          />
        </div>
        <div className="w-full max-w-screen-sm px-12 text-left sm:px-32 sm:w-auto">
          <h1 className="mt-6 text-xl font-semibold text-stone-950">
            It's cramped in here...
          </h1>
          <p className="mt-2 text-sm text-stone-500">
            Most human errors occur on small screens so you'll need to use a
            larger screen to access PropertyJot. Sorry, not sorry.
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
