import { useEffect, useState } from "react";
import { Link, useNavigation } from "react-router-dom";
import { Menu } from "./Menu";
import axios from "axios";
import Fuse from "fuse.js";
import validator from "validator";

export function Home() {
  const navigate = useNavigation();

  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const [properties, setProperties] = useState([]);
  const [results, setResults] = useState([]);

  const [query, setQuery] = useState("");

  useEffect(() => {
    if (query?.trim() === "") {
      setResults(properties);
    } else {
      let keys = [
        "address_line_one",
        "address_line_two",
        "address_line_three",
        "address_line_four",
        "address_line_five",
        "address_line_six",
        "postcode",
        "id",
      ];

      if (validator.isPostalCode(query, "GB")) {
        setResults(
          properties.filter(
            property =>
              property.postcode?.toLowerCase() === query?.toLowerCase()
          )
        );
        return;
      }

      const options = {
        threshold: 0.375,
        keys: keys,
      };

      const fuse = new Fuse(properties, options);
      const results = fuse.search(query);
      setResults(results.map(result => result.item));
    }
  }, [query, properties]);

  // const [isPinged, setIsPinged] = useState(false);

  // useEffect(() => {
  //   if (!navigate) return;
  //   const token = localStorage.getItem("token");

  //   if (!isPinged) {
  //     let handler = async () => {
  //       try {
  //         const response = await axios.get("https://api.propertyjot.com/ping", {
  //           headers: { Authorization: token },
  //         });

  //         if (response.status !== 200) {
  //           localStorage.removeItem("token");
  //           localStorage.removeItem("user");
  //           navigate("/login");

  //           return;
  //         } else {
  //         }
  //       } catch (err) {
  //         console.error(err);

  //         if (err?.response?.status === 401 || err?.response?.status === 403) {
  //           localStorage.removeItem("token");
  //           localStorage.removeItem("user");

  //           navigate("/login");

  //           return;
  //         }
  //       }

  //       setIsPinged(true);
  //     };

  //     let timeout = setTimeout(handler, 500);

  //     return () => {
  //       if (timeout) {
  //         clearTimeout(timeout);
  //       }
  //     };
  //   }
  // }, [isPinged, navigate]);

  useEffect(() => {
    if (!navigate) return;

    async function getProperties() {
      try {
        const response = await axios.get(
          "https://api.propertyjot.com/properties",
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        const allProperties = response.data;

        const props = allProperties
          ?.sort((a, b) => b.id - a.id)
          ?.map(prop => {
            const newProp = {
              ...prop,
              address_line_one: prop.address.line_one,
              address_line_two: prop.address.line_two,
              address_line_three: prop.address.line_three,
              address_line_four: prop.address.line_four,
              address_line_five: prop.address.line_five,
              address_line_six: prop.address.line_six,
              postcode: prop.address.postcode,
            };

            return newProp;
          });
        setProperties(props);
        setResults(props);
      } catch (err) {
        console.error(err);

        if (err?.response?.status === 401 || err?.response?.status === 403) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");

          if (navigate && typeof navigate === "function") {
            navigate("/login");
          } else {
            if (typeof window !== "undefined") {
              window.location.href = "/login";
            } else {
              window.location.reload();
            }
          }

          return;
        }
      }
    }

    getProperties();
  }, [navigate]);

  const [isBlurred, setIsBlurred] = useState(false);

  return (
    <div className="min-h-screen bg-stone-50 no-scrollbar">
      <Menu />
      <div className="flex justify-between px-4 py-10 no-scrollbar">
        {/* <p>{JSON.stringify(properties[0]?.thumbnail_url)}</p> */}
        <div className="justify-between w-full max-w-screen-sm gap-3 mx-auto md:max-w-screen-md lg:max-w-screen-lg no-scrollbar">
          <h1 className="text-3xl font-medium text=stone-950">Properties</h1>
          <p className="mt-2 text-sm text-stone-500">
            Here's a list of all your properties. You can search for a property
            by its address, postcode or ID.
          </p>
          <div
            className={[
              "flex items-center flex-1 gap-2 p-3.5 mt-4 text-sm bg-stone-200 rounded relative",
              isSearchFocused ? "text-stone-950" : "text-stone-600",
            ].join(" ")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-4 h-4"
            >
              <path
                fillRule="evenodd"
                d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z"
                clipRule="evenodd"
              />
            </svg>
            <input
              autoCorrect="off"
              value={query}
              onChange={e => setQuery(e.target.value)}
              className="flex-1 font-medium bg-transparent focus:outline-none placeholder:text-stone-600 text-stone-950"
              onBlur={() => {
                setQuery(query => query.trim());
                setIsSearchFocused(false);
              }}
              onFocus={() => setIsSearchFocused(true)}
              placeholder="Search for properties, reports, people and more..."
            />
            {isSearchFocused && (
              <div className="absolute top-0 bottom-0 left-0 right-0 flex border-2 rounded pointer-events-none border-stone-950" />
            )}
            <div className="absolute top-0 bottom-0 flex items-center right-4">
              <div className="flex items-center justify-center w-6 h-6 font-bold text-white bg-pink-500 border-pink-600 rounded-full shadow-sm">
                <p
                  className={`${
                    results?.length > 99 ? "text-[0.5rem]" : "text-[0.625rem]"
                  }`}
                >
                  {results?.length || 0}
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 mt-6 sm:grid-cols-2 lg:grid-cols-3">
            {(() => {
              if (!properties) {
                return [];
              }

              if (results?.length === properties?.length) {
                return results?.slice(0, 12);
              }

              return results || [];
            })()?.map((property, index) => (
              <Link
                to={`/property/${property?.id}`}
                key={index}
                className="flex items-center px-4 py-3 overflow-hidden text-left bg-white border rounded shadow-sm border-stone-300"
                onClick={() => setIsBlurred(true)}
              >
                <div className="flex h-12 overflow-hidden border rounded-full bg-stone-300 border-stone-300 aspect-square">
                  {property?.has_thumbnail ? (
                    <img
                      alt="Property"
                      src={`https://api.propertyjot.com/thumbnails/${property?.id}.jpg`}
                      className="object-cover w-full h-full"
                    />
                  ) : (
                    <div className="flex items-center justify-center flex-1 w-full h-full text-stone-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path d="M14.916 2.404a.75.75 0 01-.32 1.012l-.596.31V17a1 1 0 01-1 1h-2.26a.75.75 0 01-.75-.75v-3.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.5a.75.75 0 01-.75.75h-3.5a.75.75 0 010-1.5H2V9.957a.75.75 0 01-.596-1.372L2 8.275V5.75a.75.75 0 011.5 0v1.745l10.404-5.41a.75.75 0 011.012.32zM15.861 8.57a.75.75 0 01.736-.025l1.999 1.04A.75.75 0 0118 10.957V16.5h.25a.75.75 0 110 1.5h-2a.75.75 0 01-.75-.75V9.21a.75.75 0 01.361-.64z" />
                      </svg>
                    </div>
                  )}
                </div>
                <div className="ml-3">
                  <div className="mt-1">
                    <h1 className="text-sm font-medium text-stone-950">
                      {property?.address?.line_one}
                    </h1>
                    <p className="text-xs tracking-wide text-stone-500 mt-0.5">
                      {property?.address?.line_four}
                      {", "}
                      {property?.address?.postcode}
                    </p>
                  </div>
                </div>
                {/* <div className="ml-auto">
                  <button className="flex items-center justify-center w-10 h-10 rounded-full hover:bg-stone-300 aspect-square bg-stone-200">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        fillRule="evenodd"
                        d="M19.5 21a3 3 0 003-3V9a3 3 0 00-3-3h-5.379a.75.75 0 01-.53-.22L11.47 3.66A2.25 2.25 0 009.879 3H4.5a3 3 0 00-3 3v12a3 3 0 003 3h15zm-6.75-10.5a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V10.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div> */}
              </Link>
            ))}
          </div>
        </div>
      </div>
      {isBlurred && (
        <div
          className="absolute top-0 bottom-0 left-0 right-0 backdrop-blur-sm"
          onClick={() => setIsBlurred(false)}
        ></div>
      )}
    </div>
  );
}
