import { useLayoutEffect, useState } from "react";
import backgroundImage from "./login_wallpaper.webp";
import validator from "validator";
import { useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

const abdulEm = [
  "m",
  "o",
  "c",
  ".",
  "r",
  "m",
  "i",
  "l",
  "u",
  "d",
  "b",
  "a",
  "@",
  "l",
  "u",
  "d",
  "b",
  "a",
];

export function Login() {
  const navigate = useNavigate();
  const location = useLocation();

  useLayoutEffect(() => {
    if (!navigate) return;

    const token = localStorage.getItem("token");
    if (token) {
      navigate("/properties", { replace: true });
      return;
    }

    if (location.hash === "#code") {
      const email = localStorage.getItem("email");
      if (email) {
        setEmail(email);
      } else {
        navigate("/login", { replace: true });
      }
    } else {
      localStorage.removeItem("email");
    }
  }, [location, navigate]);

  const [email, setEmail] = useState("");
  const [isRequestingCode, setIsRequestingCode] = useState(false);

  const [code, setCode] = useState("");
  const [isRequestingLogin, setIsRequestingLogin] = useState(false);

  useEffect(() => {
    if (!navigate) return;

    if (
      (validator.isEmail(email) && email.endsWith("@huntleys.net")) ||
      email === abdulEm.reverse().join("")
    ) {
      setIsRequestingCode(true);

      (async () => {
        try {
          await axios.post("https://api.propertyjot.com/login", {
            email,
          });
        } catch (err) {
          console.error(err);
        }

        localStorage.setItem("email", email);
        navigate("/login#code");

        setIsRequestingCode(false);
      })();
    }
  }, [email, navigate]);

  useEffect(() => {
    if (!navigate) return;

    if (code?.length === 6) {
      setIsRequestingLogin(true);
      (async () => {
        try {
          const response = await axios.post(
            "https://api.propertyjot.com/login/verify",
            {
              email,
              code,
            }
          );

          if (response.status === 200) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("user", JSON.stringify(response.data.user));

            localStorage.removeItem("email");

            navigate("/properties");
          }
        } catch (err) {
          console.error(err);
        }

        setIsRequestingLogin(false);
      })();
    }
  }, [code, navigate, email]);

  return (
    <div className="h-screen bg-stone-50">
      <div className="flex w-full h-full">
        <div className="flex flex-1">
          <div className="flex flex-col justify-center w-full px-10 sm:px-8 lg:px-14 xl:px-36">
            {location.hash === "#code" ? (
              <>
                <div className="flex items-center justify-center w-16 h-16 p-1.5 bg-teal-900 rounded-full border-teal-950 shadow-sm aspect-square">
                  <img
                    alt="Huntleys logo"
                    className="w-full h-full select-none object-fit"
                    src="https://huntleys.net/huntleys-white.svg"
                  />
                </div>
                <h1 className="mt-6 text-3xl font-semibold text-stone-950">
                  Code
                </h1>
                <p className="mt-2 text-sm text-stone-500">
                  Enter the code sent to{" "}
                  <span className="font-medium">{email}</span> to sign in.
                </p>
                <div>
                  <button
                    onClick={() => {
                      setEmail("");
                      localStorage.removeItem("email");
                      navigate("/login", { replace: true });
                    }}
                    className="text-xs font-medium text-rose-600 hover:text-rose-500"
                  >
                    Wrong email address?
                  </button>
                </div>
                <label className="mt-4">
                  <p className="text-xs font-semibold">Code</p>
                  <div className="mt-1.5 relative">
                    <input
                      autoFocus
                      className="px-3.5 py-3 disabled:hover:cursor-waiting text-sm w-full border rounded-md shadow-sm bg-stone-50 placeholder:text-stone-400 border-stone-300"
                      placeholder="123456"
                      onChange={e =>
                        setCode(e.target.value?.trim()?.replace(/\D/g, ""))
                      }
                      maxLength={6}
                      value={code}
                    />
                    {isRequestingLogin && (
                      <div className="absolute top-0 bottom-0 right-0 flex items-center justify-center aspect-square">
                        <span className="block w-5 h-5 loader"></span>
                      </div>
                    )}
                  </div>
                </label>
              </>
            ) : (
              <>
                <div className="flex items-center justify-center w-16 h-16 p-1.5 bg-teal-900 rounded-full border-teal-950 shadow-sm aspect-square">
                  <img
                    alt="huntleys logo"
                    className="w-full h-full select-none object-fit"
                    src="https://www.huntleys.net/huntleys-white.svg"
                  />
                </div>
                <h1 className="mt-6 text-3xl font-semibold text-stone-950">
                  Sign in
                </h1>
                <p className="mt-2 text-sm text-stone-500">
                  We'll send your work email address a one-time code to sign in.
                </p>
                <label className="mt-4">
                  <p className="text-xs font-semibold">Email</p>
                  <div className="mt-1.5 relative">
                    <input
                      autoFocus
                      disabled={isRequestingCode}
                      className="px-3.5 py-3 disabled:hover:cursor-waiting text-sm w-full border rounded-md shadow-sm bg-stone-50 placeholder:text-stone-400 border-stone-300"
                      placeholder="abdul@huntleys.net"
                      onChange={e => setEmail(e.target.value?.trim())}
                      value={email}
                      type="email"
                      maxLength={64}
                    />
                    {isRequestingCode && (
                      <div className="absolute top-0 bottom-0 right-0 flex items-center justify-center aspect-square">
                        <span className="block w-5 h-5 loader"></span>
                      </div>
                    )}
                  </div>
                </label>
              </>
            )}
          </div>
        </div>
        <div className="flex-1 hidden md:block">
          <img
            alt="propertyjot background"
            className="flex-1 object-cover w-full h-full select-none"
            src={backgroundImage}
          />
        </div>
      </div>
    </div>
  );
}
