import { Popover } from "@headlessui/react";
import axios from "axios";
import { useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export function Menu() {
  const navigate = useNavigate();

  const [token, setToken] = useState();
  const [user, setUser] = useState({ name: "", email: "" });
  useLayoutEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    } else {
      localStorage.removeItem("user");
      navigate("/login");
    }

    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUser(user);
    }
  }, [navigate]);

  // const [_close, _setClose] = useState();

  // useEffect(() => {
  //   const l = () => {
  //     if (_close) {
  //       _close();
  //     }
  //   };

  //   window.addEventListener("scroll", l);

  //   return () => {
  //     window.removeEventListener("scroll", l);
  //   };
  // }, []);

  return (
    <div className="top-0 left-0 right-0 z-50 border-b border-stone-200 bg-stone-50">
      <div className="flex justify-between p-4">
        <div className="flex items-center justify-between w-full max-w-screen-sm gap-3 mx-auto md:max-w-screen-md lg:max-w-screen-lg">
          <Link to="/properties">
            <div className="h-6">
              <img
                alt="logo"
                className="object-contain w-full h-full"
                src="https://www.huntleys.net/huntleys-charcoal.svg"
              />
            </div>
          </Link>
          {/* <div
            className={[
              "flex items-center flex-1 gap-2 p-3.5 text-sm bg-stone-200 rounded relative",
              isSearchFocused ? "text-stone-950" : "text-stone-600",
            ].join(" ")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-4 h-4"
            >
              <path
                fillRule="evenodd"
                d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z"
                clipRule="evenodd"
              />
            </svg>
            <input
              className="flex-1 font-medium bg-transparent focus:outline-none placeholder:text-stone-600 text-stone-950"
              onBlur={() => setIsSearchFocused(false)}
              onFocus={() => setIsSearchFocused(true)}
              placeholder="Search for properties, reports, people and more..."
            />
            {isSearchFocused && (
              <div className="absolute top-0 bottom-0 left-0 right-0 border-2 pointer-events-none border-stone-950"></div>
            )}
          </div> */}
          <Popover className="relative">
            <Popover.Button className="transition-none shadow-sm flex items-center justify-center h-full px-2.5 py-1.5 gap-1 focus:outline-none rounded bg-stone-900 border-stone-950 hover:cursor-pointer">
              <p className="text-sm font-semibold tracking-wide text-white">
                {user?.name || "Abdul Anwar"}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-4 h-4 -mr-1 text-stone-100"
              >
                <path
                  fillRule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clipRule="evenodd"
                />
              </svg>
            </Popover.Button>
            <Popover.Panel className="absolute right-0 z-10 mt-2 min-w-[20rem] translate-z-0">
              <div className="mx-auto overflow-hidden bg-white border rounded shadow border-stone-300">
                <div className="p-3">
                  <h1 className="font-medium text-stone-950">
                    {user?.name || "Abdul Anwar"}
                  </h1>
                  <p className="text-xs mt-0.5 text-stone-500">
                    {user?.email || "abdul@huntleys.net"}
                  </p>
                </div>
                {/* <div className="w-full h-px bg-stone-300"></div>
                <Link
                  className="flex w-full gap-3 px-3 py-4 text-xs font-semibold text-stone-500 hover:text-stone-950 hover:bg-stone-100 "
                  to="/settings"
                >
                  <div className="w-12 h-12 aspect-square bg-stone-300"></div>
                  <div>
                    <p>Feedback</p>
                    <p className="mt-1 font-normal">
                      If something could be improved, let us know.
                    </p>
                  </div>
                </Link> */}
                {/* <div className="w-full h-px bg-stone-300"></div>
                <Link
                  className="flex w-full gap-3 px-3 py-4 text-xs font-semibold text-stone-500 hover:text-stone-950 hover:bg-stone-100 "
                  to="/settings"
                >
                  <div className="w-12 h-12 aspect-square bg-stone-300"></div>
                  <div>
                    <p>Billing</p>
                    <p className="mt-1 font-normal">
                      If something could be improved, let us know.
                    </p>
                  </div>
                </Link> */}
                {/* <div className="w-full h-px bg-stone-300"></div>
                <Link
                  className="flex w-full gap-3 px-3 py-4 text-xs font-semibold text-stone-500 hover:text-stone-950 hover:bg-stone-100 "
                  to="/settings"
                >
                  <div className="w-12 h-12 aspect-square bg-stone-300"></div>
                  <div>
                    <p>Settings</p>
                    <p className="mt-1 font-normal">
                      If something could be improved, let us know.
                    </p>
                  </div>
                </Link> */}
                {/* {user?.email === "andrew@huntleys.net" && (
                  <>
                    <div className="w-full h-px bg-stone-300"></div>
                    <Link
                      to="/inventories"
                      className="block w-full px-3 py-4 text-xs font-semibold text-left text-stone-500 hover:text-stone-950 hover:bg-stone-100"
                    >
                      Inventories
                    </Link>
                  </>
                )} */}
                <div className="w-full h-px bg-stone-300"></div>
                <Link
                  to="/reports"
                  className="block w-full px-3 py-4 text-xs font-semibold text-left text-stone-500 hover:text-stone-950 hover:bg-stone-100"
                >
                  Reports
                </Link>
                <div className="w-full h-px bg-stone-300"></div>
                <button
                  className="block w-full px-3 py-4 text-xs font-semibold text-left text-stone-500 hover:text-stone-950 hover:bg-stone-100"
                  onClick={async () => {
                    try {
                      await axios.post(
                        "https://api.propertyjot.com/logout",
                        undefined,
                        {
                          headers: {
                            Authorization: token,
                          },
                        }
                      );
                    } catch (err) {
                      console.error(err);
                    }

                    localStorage.removeItem("token");
                    localStorage.removeItem("user");

                    navigate("/login");
                  }}
                >
                  Sign out
                </button>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      </div>
    </div>
  );
}
