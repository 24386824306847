import axios from "axios";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Menu } from "./Menu.js";
import { useParams } from "react-router";
import { useDropzone } from "react-dropzone";
import imageCompression from "browser-image-compression";
import { DndProvider, DragPreviewImage, useDrag, useDrop } from "react-dnd";
import Creatable from "react-select/creatable";
import { HTML5Backend } from "react-dnd-html5-backend";
import { v4 as uuidv4 } from "uuid";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import convertor from "number-to-words";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Link } from "react-router-dom";
import heic2any from "heic2any";
import PacmanLoader from "react-spinners/PacmanLoader";
import CurrencyInput from "react-currency-input-field";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { pdfjs, Document, Page } from "react-pdf";

import "react-datepicker/dist/react-datepicker.css";
import "react-circular-progressbar/dist/styles.css";

import inventoryImage from "./inventory.svg";
import checkoutImage from "./checkout.svg";
import dropFilesImage from "./add-files.svg";
import uploadFilesImage from "./upload-files.svg";
import processingFilesImage from "./processing-files.svg";
import qrCodeImage from "./qrcode.png";
import emptyImage from "./empty.svg";

const formatCurrencyInput = value => {
  const regex = /^\d+(\.\d{0,2})?$/;
  const match = value.match(regex);

  if (match) {
    return match[0];
  } else {
    return "";
  }
};

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const folderOptions = [
  "Exterior",
  "Reception",
  "Hallway",
  "Kitchen",
  "Bedroom",
  "Bathroom",
  "Ensuite",
  "Utility",
  "Landing",
  "Stairs",
  "Porch",
  "Basement",
  "Garage",
  "Keys",
]?.map(option => ({ value: option, label: option }));

export function Property() {
  const navigate = useNavigate();

  const [disputeIssueSelectedFolders, setDisputeIssueSelectedFolders] = useState(new Map());

  const [clerkComments, setClerkComments] = useState("");
  const [isImplicitCheckout, setIsImplicitCheckout] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isVerifyingImages, setIsVerifyingImages] = useState(false);
  const [isReuploadingImage, setIsReuploadingImage] = useState(false);

  const [checkoutIssueAmounts, setCheckoutIssueAmounts] = useState(new Map());
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [checkoutDate, setCheckoutDate] = useState(new Date());
  const [checkoutInventory, setCheckoutInventory] = useState();
  const [wasTenantPresent, setWasTenantPresent] = useState({
    value: false,
    label: "No",
  });
  const [tenantArrears, setTenantArrears] = useState(0);
  const [earlyRelease, setEarlyRelease] = useState(0);

  const [checkoutFolders, setCheckoutFolders] = useState([]);
  const [checkoutFolderIssues, setCheckoutFolderIssues] = useState(new Map());
  const [checkoutIssuesLive, setCheckoutIssuesLive] = useState(new Map());
  const [checkoutIssuePics, setCheckoutIssuePics] = useState(new Map());
  const [checkoutIssueExcludedPics, setCheckoutIssueExcludedPics] = useState(new Map());
  const [checkoutIssueComments, setCheckoutIssueComments] = useState(new Map());
  const [checkoutIssueClauses, setCheckoutIssueClauses] = useState(new Map());

  const [checkoutSaves, setCheckoutSaves] = useState([]);

  const [checkoutIssueDocuments, setCheckoutIssueDocuments] = useState(new Map());

  const [isCheckingOutFolders, setIsCheckingOutFolders] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [foldersMap, setFoldersMap] = useState(new Map());
  const [currentFolder, setCurrentFolder] = useState();
  const [foldersCommentMap, setFoldersCommentMap] = useState(new Map());
  const [isForming, setIsForming] = useState(false);

  const [isBlurVisible, setIsBlurVisible] = useState(false);
  const [lastSelectedUUID, setLastSelectedUUID] = useState();
  const [isShiftPressed, setIsShiftPressed] = useState(false);
  const [excludedPhotos, setExcludedPhotos] = useState([]);
  const [draggingUUID, setDraggingUUID] = useState();
  const [isCreatedInventory, setIsCreatedInventory] = useState(false);

  const [mailReport, setMailReport] = useState();
  const [reportPeople, setReportPeople] = useState();
  const [isFurnished, setIsFurnished] = useState(false);
  const [isFurnishingFireSafe, setIsFurnishingFireSafe] = useState();

  const [selectedCheckoutUUIDs, setSelectedCheckoutUUIDs] = useState([]);

  const [selectedFileUUIDs, setSelectedFileUUIDs] = useState([]);

  const [processingIndex, setProcessingIndex] = useState(0);
  const [processingFilesCount, setProcessingFilesCount] = useState(0);

  const [customFolders, setCustomFolders] = useState([]);

  const [isContinuing, setIsContinuing] = useState(false);

  const [files, setFiles] = useState([]);
  const [compressedFiles, setCompressedFiles] = useState([]);

  const [isProcessing, setIsProcessing] = useState(false);

  const [property, setProperty] = useState();

  const [uploadingIndex, setUploadingIndex] = useState(0);

  const [address, setAddress] = useState(property?.address);
  const [fullAddress, setFullAddress] = useState([]);

  const [isCreatingInventory, setIsCreatingInventory] = useState(false);
  const [isSorting, setIsSorting] = useState(false);

  const [postcode, setPostcode] = useState(property?.postcode);

  const [isUploading, setIsUploading] = useState(false);

  const [inventoryDate, setInventoryDate] = useState(new Date());

  const [isPreviewing, setIsPreviewing] = useState(false);

  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const [dragging, setDragging] = useState(false);
  const [selectionBox, setSelectionBox] = useState({
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  });

  const [floors, setFloors] = useState([
    {
      name: "Ground Floor",
      alarms: [
        {
          isPresent: false,
          isWorking: false,
          isTested: false,
          location: "",
          comment: "",
        },
      ],
    },
  ]);
  const [extraRooms, setExtraRooms] = useState([]);

  const [meters, setMeters] = useState([
    {
      name: "Electric",
      location: "",
      readings: ["", "", ""],
      comment: "",
      isSmart: false,
      isPrepaid: false,
    },
  ]);

  useEffect(() => {
    if (isVerifying) {
      document.body.classList.add("overflow-hidden");

      setIsVerifyingImages(true);

      async function verifyImages() {
        const folderKeys = [...new Set([...extraRooms, ...foldersMap.keys()])];

        let UUIDs = [];
        for (const folderKey of folderKeys) {
          const photos = foldersMap.get(folderKey);

          if (photos) {
            for (const photo of photos) {
              UUIDs = [...UUIDs, photo];
            }
          }
        }

        let imagesVerifiedCount = 0;
        for (const uuid of UUIDs) {
          const URL = `https://static.propertyjot.com/${uuid}.jpg`;

          try {
            const response = await axios.head(URL);
            if (response.status === 200) {
              console.log("Image verified");
              imagesVerifiedCount += 1;

              {
                const file = files.find(file => file.uuid === uuid);

                const formData = new FormData();
                formData.append("photo", file);
                formData.append("name", uuid);

                console.log(file, uuid);
              }
            }
          } catch (err) {
            setIsReuploadingImage(true);
            console.error(err);

            let isUploadedNow = false;

            while (!isUploadedNow) {
              try {
                console.log("Reuploading image");
                const file = files.find(file => file.uuid === uuid);

                const formData = new FormData();
                formData.append("photo", file);
                formData.append("name", uuid);

                let _isUploaded = false;

                while (!_isUploaded) {
                  try {
                    const response = await axios.put("https://api.propertyjot.com/upload", formData, {
                      headers: {
                        Authorization: localStorage.getItem("token"),
                      },
                    });

                    if (response.status === 200) {
                      _isUploaded = true;
                    }
                  } catch (err) {
                    console.log("Failed to upload image", uuid);
                    console.error(err);
                    await new Promise(resolve => setTimeout(resolve, 2.5e3));
                  }
                }
              } catch (err) {
                console.error(err);
              }

              await new Promise(resolve => setTimeout(resolve, 350));
            }

            setIsReuploadingImage(false);
          }
        }

        console.log(imagesVerifiedCount, UUIDs.length);
        if (imagesVerifiedCount === UUIDs.length) {
          console.log("All images verified");
          setIsVerified(true);
          setIsVerifyingImages(false);
          setIsVerifying(false);
        }
      }

      try {
        verifyImages();
      } catch (err) {
        console.error(err);
      }
    } else if (!isVerifying) {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isVerifying, extraRooms, files, foldersMap]);

  useEffect(() => {
    if (checkoutInventory) {
      const folders = JSON.parse(checkoutInventory?.data)?.folders;
      setCheckoutFolders(Object.keys(folders));
    }
  }, [checkoutInventory]);

  const [checkoutMeters, setCheckoutMeters] = useState(
    checkoutInventory?.data ? JSON.parse(checkoutInventory?.data)?.meters : []
  );

  useEffect(() => {
    if (!checkoutInventory) return;

    const newCheckoutMeters = checkoutInventory?.data ? JSON.parse(checkoutInventory?.data)?.meters : [];

    setCheckoutMeters(newCheckoutMeters);
  }, [checkoutInventory]);

  useEffect(() => {
    if (isCheckingOut && checkoutMeters.length > 0) {
      let meters_ = [];
      for (const meter of checkoutMeters) {
        const meter_ = {
          name: meter.name,
          location: meter.location,
          readings: meter.readings,
          comment: meter.comment,
          isSmart: meter.is_smart,
          isPrepaid: meter.is_prepaid || undefined,
        };

        meters_ = [...meters_, meter_];
      }

      setMeters(meters_);
    }
  }, [isCheckingOut, checkoutMeters]);

  useEffect(() => {
    if (!isFurnished) {
      setIsFurnishingFireSafe(undefined);
    }
  }, [isFurnished]);

  useEffect(() => {
    if (mailReport) {
      document?.body?.classList?.add("overflow-hidden");
    } else {
      document?.body?.classList?.remove("overflow-hidden");
    }

    if (mailReport) {
      async function getReport() {
        try {
          const response = await axios.get(`https://api.propertyjot.com/emails/${mailReport?.uuid}`, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          });

          if (response?.data) {
            setReportPeople(response?.data);
          }
        } catch (err) {
          console.error(err);
        }
      }

      getReport();
    }
  }, [mailReport]);

  useEffect(() => {
    const keyDownListener = event => {
      if (event.code === "ShiftLeft") {
        setIsShiftPressed(true);
      }
    };

    window.addEventListener("keydown", keyDownListener);

    const keyUpListener = event => {
      if (event.code === "ShiftLeft") {
        setIsShiftPressed(false);
      }
    };

    window.addEventListener("keyup", keyUpListener);

    return () => {
      window.removeEventListener("keydown", keyDownListener);
      window.removeEventListener("keyup", keyUpListener);
    };
  }, []);

  const onDrop = useCallback(
    async acceptedFiles => {
      if (isProcessing || isUploading) return;

      setIsSorting(true);
      setIsProcessing(true);

      const options = {
        initialQuality: 0,
        maxSizeMB: 5e5,
        maxWidthOrHeight: 512,
        useWebWorker: true,
      };

      let allowedFiles = acceptedFiles
        .filter(file => file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/heic")
        ?.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

      setProcessingFilesCount(allowedFiles.length);

      var f = [];

      for (let i = 0; i < allowedFiles.length; i++) {
        let newFiles = [];
        let newFileDragPreviews = [];
        const file = allowedFiles[i];

        let compressedFile, dragPreviewFile;

        try {
          let convertedFile;
          if (file.type === "image/heic") {
            try {
              convertedFile = await heic2any({
                blob: file,
                toType: "image/jpeg",
                quality: 0.7,
              });
            } catch (err) {
              console.error("Error converting HEIC file");
            }
          }

          if (convertedFile) {
            convertedFile = await imageCompression(convertedFile, options);
            newFiles.push(convertedFile);

            dragPreviewFile = await imageCompression(convertedFile, {
              ...options,
              maxWidthOrHeight: 128,
            });
          } else {
            compressedFile = await imageCompression(file, options);
            newFiles.push(compressedFile);

            dragPreviewFile = await imageCompression(file, {
              ...options,
              maxWidthOrHeight: 128,
            });
          }
        } catch (err) {
          console.error(err);
        }

        newFileDragPreviews.push(dragPreviewFile);

        setProcessingIndex(prev => prev + 1);

        setFiles(prev => {
          let h = [];

          newFiles.forEach(async (file, index) => {
            let convertedFile;
            if (file.type === "image/heic") {
              try {
                convertedFile = await heic2any({
                  blob: file,
                  toType: "image/jpeg",
                  multiple: false,
                  quality: 0.7,
                });
              } catch (err) {
                console.error(err);
              }
            }

            if (convertedFile) {
              convertedFile = new File(convertedFile, allowedFiles[index].name, {
                type: "image/jpeg",
              });

              Object.assign(convertedFile, {
                preview: URL.createObjectURL(convertedFile),
              });

              Object.assign(convertedFile, {
                uuid: uuidv4(),
              });

              Object.assign(convertedFile, {
                dragPreview: URL.createObjectURL(newFileDragPreviews[index]),
              });

              h = [...h, convertedFile];
            } else {
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              });

              Object.assign(file, {
                uuid: uuidv4(),
              });

              Object.assign(file, {
                dragPreview: URL.createObjectURL(newFileDragPreviews[index]),
              });

              h = [...h, file];
            }
          });

          f = [...prev, ...h];

          return f;
        });
      }

      setIsProcessing(false);
      setProcessingFilesCount(0);
      setProcessingIndex(0);

      async function uploadFiles() {
        setIsUploading(true);

        for (let i = 0; i < allowedFiles.length; i++) {
          const file = allowedFiles[i];

          let compressedFile;

          try {
            if (file.type !== "image/heic") {
              compressedFile = await imageCompression(file, {
                ...options,
                initialQuality: 0.7,
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
              });

              const formData = new FormData();
              formData.append("photo", compressedFile);
              formData.append("name", f[i].uuid);

              let canContinue = false;

              try {
                const response = await axios.put("https://api.propertyjot.com/upload", formData, {
                  headers: {
                    Authorization: localStorage.getItem("token"),
                  },
                });

                if (response.status === 200) {
                  canContinue = true;
                }
              } catch (err) {
                console.error(err);

                if (err?.response?.status === 401 || err?.response?.status === 403) {
                  localStorage.removeItem("token");
                  localStorage.removeItem("user");
                  localStorage.clear();

                  navigate("/login");
                  return;
                }

                await new Promise(resolve => setTimeout(resolve, 2500));

                while (!canContinue) {
                  try {
                    const response = await axios.put("https://api.propertyjot.com/upload", formData, {
                      headers: {
                        Authorization: localStorage.getItem("token"),
                      },
                    });

                    if (response.status === 200) {
                      canContinue = true;
                    }
                  } catch (err) {
                    console.error(err);

                    if (err?.response?.status !== 200) {
                      if (err?.response?.status || err?.response?.status === 403) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("user");
                        localStorage.clear();

                        navigate("/login");
                        return;
                      }
                    }

                    await new Promise(resolve => setTimeout(resolve, 2500));
                  }
                }
              }

              canContinue = false;

              while (!canContinue) {
                const URL = `https://static.propertyjot.com/${f[i].uuid}.jpg`;
                if (URL) {
                  try {
                    const response = await axios.head(URL);

                    if (response.status === 200) {
                      canContinue = true;
                      break;
                    }
                  } catch (err) {
                    console.error(err);
                    canContinue = false;
                    await new Promise(resolve => setTimeout(resolve, 2500));
                  }
                }

                if (!canContinue) {
                  try {
                    const response = await axios.put("https://api.propertyjot.com/upload", formData, {
                      headers: {
                        Authorization: localStorage.getItem("token"),
                      },
                    });
                  } catch (err) {
                    console.error(err);
                    canContinue = false;
                    await new Promise(resolve => setTimeout(resolve, 2500));
                  }
                }
              }

              if (canContinue) {
                setUploadingIndex(prev => prev + 1);
              }
            } else {
              let convertedFile = await heic2any({
                blob: file,
                toType: "image/jpeg",
                multiple: false,
                quality: 0.7,
              });

              let fileReader = new FileReader();
              fileReader.onload = async event => {
                let result = event.target.result;
                let convertedFile = new File([result], file.name, {
                  type: "image/jpeg",
                });

                compressedFile = await imageCompression(convertedFile, {
                  ...options,
                  initialQuality: 0.7,
                  maxSizeMB: 1,
                  maxWidthOrHeight: 1920,
                });

                const formData = new FormData();
                formData.append("photo", compressedFile);
                formData.append("name", f[i].uuid);

                try {
                  const response = await axios.put("https://api.propertyjot.com/upload", formData, {
                    headers: {
                      Authorization: localStorage.getItem("token"),
                    },
                  });

                  if (response?.status === 401 || response?.status === 403) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    localStorage.clear();

                    navigate("/login");
                    return;
                  }
                } catch (err) {
                  console.error(err);
                }

                setUploadingIndex(prev => prev + 1);
              };

              fileReader.readAsArrayBuffer(convertedFile);
            }
          } catch (err) {
            console.error(err);
          }
        }

        setIsUploading(false);
      }

      try {
        await uploadFiles();
      } catch (err) {
        console.error(err);
        setIsProcessing(false);
        setIsUploading(false);
      }
    },
    [isProcessing, isUploading, navigate]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  let { id } = useParams();

  const [inventoryUUID, setInventoryUUID] = useState();

  const onSortEnd = (oldIndex, newIndex) => {
    const _files = foldersMap.get(currentFolder);
    const newFiles = arrayMoveImmutable(_files, oldIndex, newIndex);

    setFoldersMap(prev => {
      const newFoldersMap = new Map(prev);
      newFoldersMap.set(currentFolder, newFiles);
      return newFoldersMap;
    });
  };

  const onFolderSortEnd = (oldIndex, newIndex) => {
    const newFolders = arrayMoveImmutable(customFolders, oldIndex, newIndex);
    setCustomFolders(newFolders);
  };

  const [inventories, setInventories] = useState([]);

  const [allProperties, setAllProperties] = useState([]);

  useLayoutEffect(() => {
    async function getProperties() {
      try {
        const response = await axios.get("https://api.propertyjot.com/properties", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });

        const allProperties = response.data;

        const props = allProperties
          ?.sort((a, b) => b.id - a.id)
          ?.map(prop => {
            const newProp = {
              ...prop,
              address_line_one: prop.address.line_one,
              address_line_two: prop.address.line_two,
              address_line_three: prop.address.line_three,
              address_line_four: prop.address.line_four,
              address_line_five: prop.address.line_five,
              address_line_six: prop.address.line_six,
              postcode: prop.address.postcode,
            };

            return newProp;
          });
        setAllProperties(props);
      } catch (err) {
        console.error(err);

        if (err?.response?.status === 401 || err?.response?.status === 403) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.clear();

          navigate("/login");

          return;
        }
      }

      setIsLoading(false);
    }

    // async function getProperty() {
    //   const { data } = await axios.get(
    //     `https://v4.salesandlettings.online/pls/huntleys/xml_export.xml?prn=N&preg=Y&pid=${id}`
    //   )

    //   const xml = parser.parse(data)
    //   const images = xml["MULTI_PROPS"]["PROPERTY"]["PHOTOS"][
    //     "PRINTQUALITYIMAGE"
    //   ]
    //     .filter(obj => obj["@_pictype"] != "Floor plan")
    //     .map(obj => obj["#text"])

    //   const address1 = xml?.MULTI_PROPS?.PROPERTY?.ADDRESS?.PROPERTYADDRESS1
    //   const postcode = xml?.MULTI_PROPS?.PROPERTY?.ADDRESS?.PROPERTYPOSTCODE
    //   const photos = xml?.MULTI_PROPS?.PROPERTY?.PHOTOS?.PRINTQUALITYIMAGE
    //   setProperty({ address: address1, postcode, photos })

    //   const fullAddress = [
    //     ...address1?.split(",")?.map(str => str.trim()),
    //     postcode,
    //   ]

    //   setAddress(address1)
    //   setPostcode(postcode)
    //   setFullAddress(fullAddress)
    // }

    getProperties();
  }, [navigate]);

  // useEffect(() => {
  //   async function getInventories() {
  //     console.info("Getting inventories");
  //     try {
  //       const response = await axios.get(
  //         "https://api.propertyjot.com/inventories",
  //         {
  //           headers: { Authorization: localStorage.getItem("token") },
  //         }
  //       );

  //       if (response.status === 200) {
  //         if (
  //           response.data &&
  //           Array.isArray(response.data) &&
  //           response.data.length > 0
  //         ) {
  //           const objs = response.data.map((v, i) => {
  //             return {
  //               id: v.id,
  //               uuid: v.uuid,
  //               data: JSON.parse(v.data),
  //               archived: v.archived,
  //             };
  //           });

  //           objs.forEach((v, i) => {
  //             let count = 0;

  //             if (v.data?.folders) {
  //               const folders = Object.keys(v.data.folders);

  //               for (let i = 0; i < folders.length; i++) {
  //                 count += v.data.folders[folders[i]].length;
  //               }

  //               v.photoCount = count;
  //             }
  //           });

  //           setInventories(objs);
  //           console.log(objs.length);
  //         }
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }

  //   try {
  //     getInventories();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }, []);

  const [timeline, setTimeline] = useState([]);

  useEffect(() => {
    if (allProperties?.length > 0) {
      async function getProperty() {
        const property = allProperties.find(property => property.id === Number.parseInt(id));
        const fullAddress = [];

        for (const line in property?.address) {
          if (line.trim().length > 0) {
            const l = property?.address[line];

            if (typeof l === "string" && l?.trim() !== "") {
              fullAddress.push(l);
            }
          }
        }

        setExtraRooms(
          property?.rooms
            ?.filter(room => !["Parking", "Heating", "Glazing", "Virtual Tour", "EPC", ""].includes(room))
            ?.map(room => ({
              name: room,
              isCombustable: false,
              isPresent: false,
              isWorking: false,
              isTested: false,
            }))
        );
        setCustomFolders(
          property?.rooms.filter(room => !["Parking", "Heating", "Glazing", "Virtual Tour", "EPC"].includes(room))
        );
        setAddress(property?.address?.line_one);
        setPostcode(property?.address?.postcode);
        setFullAddress(fullAddress);
        setProperty(property);
      }

      async function getTimeline() {
        try {
          const response = await axios.get(`https://api.propertyjot.com/property/${id}`, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          });

          if (response?.status === 200) {
            const _timeline = response.data;
            setTimeline(_timeline);
          } else {
            setTimeline([]);
          }
        } catch (err) {
          console.error(err);
        }
      }

      getProperty();
      getTimeline();
    }
  }, [allProperties, id]);

  // const numberOfPhotoPages = useCallback(() => {
  //   const keyPhotoCount = foldersMap.get("Keys")?.length
  //   const metersPhotoCount = foldersMap.get("Meters")?.length

  //   let allFolders = [...foldersMap.keys()]
  //   let count = 0

  //   for (const folder of allFolders) {
  //     const images = foldersMap.get(folder)
  //     if (images.length === 0) continue

  //     count += images.length
  //   }

  //   let totalPhotosCount =
  //     keyPhotoCount && metersPhotoCount
  //       ? keyPhotoCount + metersPhotoCount + count
  //       : count
  //   if (totalPhotosCount === 0) return 0

  //   if (totalPhotosCount <= 12) return 1

  //   return Math.ceil(totalPhotosCount / 12)
  // })()

  const photoPages = useCallback(
    (() => {
      let allFolders = [];

      if (customFolders?.length > 0) {
        allFolders = [...new Set([...customFolders, "Keys", "Meters"] || [])];
      } else {
        allFolders = [...new Set(["Keys", "Meters"] || [])];
      }

      let pages = [[]];
      let numberOfRows = 0;

      for (const folder of allFolders) {
        if (folder === "Trash") continue;

        if (numberOfRows >= 8) {
          numberOfRows = 0;
          pages.push([]);
        }

        const images = foldersMap.get(folder);
        if (!images || images?.length === 0) continue;

        const imageChunks = [];
        for (let i = 0; i < images.length; i += 5) {
          imageChunks.push(images.slice(i, i + 5));
        }

        for (let i = 0; i < imageChunks.length; i++) {
          const imagesChunk = imageChunks[i];

          if (numberOfRows <= 8) {
            pages[pages.length - 1].push({
              folder,
              images: imagesChunk,
              showFolderName: i === 0,
              showComment: i === imageChunks.length - 1,
            });

            if (i === 0) {
              numberOfRows += 1;
            }

            numberOfRows += 1;

            if (i === imageChunks.length - 1 && foldersCommentMap.has(folder)) {
              numberOfRows += 1;
            }
          } else {
            numberOfRows = 0;
            pages.push([]);

            pages[pages.length - 1].push({
              folder,
              images: imagesChunk,
              showFolderName: false,
              showComment: i === imageChunks.length - 1,
            });

            numberOfRows += 1;

            if (i === imageChunks.length - 1 && foldersCommentMap.has(folder)) {
              numberOfRows += 1;
            }
          }
        }
      }

      if (pages?.length === 1 && pages?.[0]?.length === 0) {
        return [];
      }

      return pages;
    })(),
    [foldersMap, foldersCommentMap, customFolders]
  );

  const numberOfPhotoPages = photoPages?.length || 0;

  const showLocationInSmokeAlarmTable = useCallback(() => {
    let show = false;

    for (const floor of floors) {
      for (const alarm of floor.alarms) {
        if (alarm?.location?.trim().length > 0) {
          show = true;
          break;
        }
      }

      if (show) break;
    }

    return show;
  }, [floors])();

  const [emailPeople, setEmailPeople] = useState([]);

  const showCommentInSmokeAlarmTable = useCallback(() => {
    let show = false;

    for (const floor of floors) {
      for (const alarm of floor.alarms) {
        if (alarm.comment.trim().length > 0) {
          show = true;
          break;
        }
      }

      if (show) break;
    }

    return show;
  }, [floors])();

  const showLocationInMeterTable = useCallback(() => {
    let show = false;

    for (const meter of meters) {
      if (meter?.location?.trim().length > 0) {
        show = true;
        break;
      }
    }

    return show;
  })();

  const [tenants, setTenants] = useState("");
  const [tenantIndex, setTenantIndex] = useState(0);
  const [tenancyId, setTenancyId] = useState(0);

  useEffect(() => {
    const id = property?.tenancies?.[0]?.id;
    setTenancyId(id);
  }, [property]);

  useEffect(() => {
    if (tenantIndex && property?.tenancies) {
      const tnts = property?.tenancies?.[tenantIndex]?.tenants?.map(tenant => tenant.agreement_name)?.join(", ");

      if (tnts) {
        setTenants(tnts);
      }
    }
  }, [tenantIndex, property]);

  useEffect(() => {
    if (property?.tenancies) {
      const tnts = property?.tenancies?.[0]?.tenants?.map(tenant => tenant.agreement_name)?.join(", ");

      setTenants(tnts);
    }
  }, [property]);

  const allCheckoutMeters = useCallback(() => {
    if (meters && checkoutMeters) {
      let allMeters = [];

      for (const meter of meters) {
        let meter_ = {
          name: meter.name,
          location: meter.location,
          readings: meter.readings,
          comment: meter.comment,
          isSmart: meter.isSmart,
          isPrepaid: meter.isPrepaid || undefined,
        };

        allMeters = [...allMeters, meter_];
      }

      for (const meter of checkoutMeters) {
        let meter_ = {
          name: meter.name,
          location: meter.location,
          readings: meter.readings,
          comment: meter.comment,
          isSmart: meter.is_smart,
          isPrepaid: meter.is_prepaid || undefined,
        };

        allMeters = [...allMeters, meter_];
      }

      let meterNames = allMeters.map(meter => meter.name);
      let uniqueMeters = [...new Set(meterNames)];

      let meters_ = [];

      for (const meter of uniqueMeters) {
        let meter_ = allMeters.find(m => m.name === meter);
        meters_ = [...meters_, meter_];
      }

      return meters_ || [];
    }

    return [];
  }, [meters, checkoutMeters])();

  const onContinuePress = async () => {
    if (isSorting) {
      if (isForming) {
        setIsForming(false);
        setIsPreviewing(true);
      } else if (isPreviewing) {
        // VERIFY
        if (!isVerified) {
          setIsVerifying(true);
          return;
        }

        if (isImplicitCheckout) {
          console.log("Implicit Checkout");

          let checkout = {
            property: {
              id: property?.id,
              address: fullAddress?.join(", ")?.trim()?.replaceAll("  ", " "),
            },
            date: inventoryDate?.toLocaleDateString("en-GB", {
              year: "numeric",
              month: "long",
              day: "numeric",
            }),
            tenants: tenants?.split ? tenants?.split(", ") : tenants?.value?.split(", "),
            landlords: property?.landlords?.map(({ agreement_name }) => agreement_name),
            folders: {},
          };

          let folderKeysOrder = [...customFolders, "Keys", "Meters"];

          const folderKeys = [...new Set([...extraRooms, ...foldersMap.keys()])];

          for (const folderKey of folderKeys) {
            if (folderKey === "Trash" || folderKey === "Meters" || folderKey === "Keys") continue;

            const photos = foldersMap.get(folderKey);
            if (photos?.length > 0) {
              checkout.folders[folderKey] = photos;
              folderKeysOrder.push(folderKey);
            }
          }

          for (const folderKey of folderKeys) {
            if (folderKey !== "Keys") continue;

            const photos = foldersMap.get(folderKey);
            if (photos?.length > 0) {
              checkout.folders[folderKey] = photos;
              folderKeysOrder.push(folderKey);
            }
          }

          for (const folderKey of folderKeys) {
            if (folderKey !== "Meters") continue;

            const photos = foldersMap.get(folderKey);
            if (photos?.length > 0) {
              checkout.folders[folderKey] = photos;
              folderKeysOrder.push(folderKey);
            }
          }

          if (floors?.length > 0) {
            let _floors = [];

            for (const floor of floors) {
              _floors.push({
                name: floor.name,
                alarms: floor.alarms.map(alarm => {
                  let obj = {
                    is_present: alarm.isPresent,
                    is_tested: alarm.isTested,
                    is_working: alarm.isWorking,
                  };

                  if (alarm.location && alarm.location?.trim() !== "") {
                    obj.location = alarm.location;
                  }

                  if (alarm.comment && alarm?.comment?.trim() !== "") {
                    obj.comments = alarm.comment;
                  }

                  return obj;
                }),
              });
            }

            checkout.floors = _floors;
          }

          const _extraRooms = extraRooms?.filter(room => room.isCombustable);

          if (_extraRooms?.length > 0) {
            let rooms = [];

            for (const extraRoom of _extraRooms) {
              let room = {
                name: extraRoom.name,
                isCombustable: extraRoom.isCombustable,
                isPresent: extraRoom.isPresent,
                isTested: extraRoom.isTested,
                isWorking: extraRoom.isWorking,
                location: extraRoom.location,
                comment: extraRoom.comment,
              };

              rooms = [...rooms, room];
            }

            rooms = rooms.map(room => {
              let newRoom = {
                name: room.name,
                alarms: [
                  {
                    is_combustable: room.isCombustable,
                    is_present: room.isPresent,
                    is_tested: room.isTested,
                    is_working: room.isWorking,
                    location: room.location,
                    comments: room.comment,
                  },
                ],
              };

              if (room.location?.trim() !== "") {
                newRoom.location = room.location;
              }

              if (room.comment?.trim() !== "") {
                newRoom.comments = room.comment;
              }

              return newRoom;
            });

            checkout.rooms = rooms;
          }

          checkout.meters = [];

          if (meters?.length > 0) {
            meters.forEach(meter => {
              let newMeter = {};

              if (meter.name?.trim() === "") {
                return;
              }

              newMeter.name = meter.name;

              if (meter.location?.trim() !== "") {
                newMeter.location = meter.location;
              }

              if (meter?.isSmart) {
                newMeter.is_smart = meter.isSmart;
              }

              if (meter.comment?.trim() !== "") {
                newMeter.comment = meter.comment;
              }

              let rs = meter.readings.filter(reading => reading?.trim() !== "");

              if (meter.isPrepaid) {
                newMeter.is_prepaid = true;

                rs = rs.map(reading =>
                  new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "GBP",
                  }).format(parseFloat(reading))
                );
              } else {
                newMeter.is_prepaid = false;
              }

              if (rs?.length > 0) {
                newMeter.readings = rs;
              }

              checkout.meters.push(newMeter);
            });
          }

          checkout.tenancy_id = tenancyId;
          checkout.number_of_pages = numberOfPhotoPages + 2;
          checkout.order = [...new Set(folderKeysOrder)];

          if (!checkout.rooms) {
            checkout.rooms = [];
          }

          checkout.type = "checkout";

          let folderComments = {};
          for (const key of foldersCommentMap) {
            const name = key[0];
            const comment = key[1];

            folderComments[name] = comment;
          }

          checkout.folder_comments = folderComments;
          checkout.photo_pages = photoPages;

          if (!isFurnished) {
            checkout.soft_furnishings_code = 0;
          } else if (isFurnished && isFurnishingFireSafe === true) {
            checkout.soft_furnishings_code = 1;
          } else if (isFurnished && isFurnishingFireSafe === false) {
            checkout.soft_furnishings_code = 2;
          }

          try {
            const response = await axios.post("https://api.propertyjot.com/checkout-report-2", checkout, {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
              responseType: "blob",
            });

            if (
              response?.status === 200 //&&
              // response.headers["content-type"] ===
              //   "application/pdf"
            ) {
              console.log("200 OK /checkout-report-2");
              window.location.reload();

              // const url = window.URL.createObjectURL(
              //   response.data
              // );
              // const link = document.createElement("a");
              // link.href = url;
              // link.target = "_blank";
              // link.setAttribute(
              //   "download",
              //   `${property?.address?.line_one}.pdf`
              // );

              // document.body.appendChild(link);
              // link.click();
              // document.body.removeChild(link);

              // window.URL.revokeObjectURL(url);
            }
          } catch (err) {
            console.error(err);
          }

          return;
        }

        let inventory = {
          property: {
            id: property?.id,
            address: fullAddress?.join(", ")?.trim()?.replaceAll("  ", " "),
          },
          date: inventoryDate?.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
          tenants: tenants?.split ? tenants?.split(", ") : tenants?.value?.split(", "),
          landlords: property?.landlords?.map(({ agreement_name }) => agreement_name),
          folders: {},
        };

        let folderKeysOrder = [...customFolders, "Keys", "Meters"];

        const folderKeys = [...new Set([...extraRooms, ...foldersMap.keys()])];

        for (const folderKey of folderKeys) {
          if (folderKey === "Trash" || folderKey === "Meters" || folderKey === "Keys") continue;

          const photos = foldersMap.get(folderKey);
          if (photos?.length > 0) {
            inventory.folders[folderKey] = photos;
            folderKeysOrder.push(folderKey);
          }
        }

        for (const folderKey of folderKeys) {
          if (folderKey !== "Keys") continue;

          const photos = foldersMap.get(folderKey);
          if (photos?.length > 0) {
            inventory.folders[folderKey] = photos;
            folderKeysOrder.push(folderKey);
          }
        }

        for (const folderKey of folderKeys) {
          if (folderKey !== "Meters") continue;

          const photos = foldersMap.get(folderKey);
          if (photos?.length > 0) {
            inventory.folders[folderKey] = photos;
            folderKeysOrder.push(folderKey);
          }
        }

        if (floors?.length > 0) {
          let _floors = [];

          for (const floor of floors) {
            _floors.push({
              name: floor.name,
              alarms: floor.alarms.map(alarm => {
                let obj = {
                  is_present: alarm.isPresent,
                  is_tested: alarm.isTested,
                  is_working: alarm.isWorking,
                };

                if (alarm.location && alarm.location?.trim() !== "") {
                  obj.location = alarm.location;
                }

                if (alarm.comment && alarm?.comment?.trim() !== "") {
                  obj.comments = alarm.comment;
                }

                return obj;
              }),
            });
          }

          inventory.floors = _floors;
        }

        const _extraRooms = extraRooms?.filter(room => room.isCombustable);

        if (_extraRooms?.length > 0) {
          let rooms = [];

          for (const extraRoom of _extraRooms) {
            let room = {
              name: extraRoom.name,
              isCombustable: extraRoom.isCombustable,
              isPresent: extraRoom.isPresent,
              isTested: extraRoom.isTested,
              isWorking: extraRoom.isWorking,
              location: extraRoom.location,
              comment: extraRoom.comment,
            };

            rooms = [...rooms, room];
          }

          rooms = rooms.map(room => {
            let newRoom = {
              name: room.name,
              alarms: [
                {
                  is_combustable: room.isCombustable,
                  is_present: room.isPresent,
                  is_tested: room.isTested,
                  is_working: room.isWorking,
                  location: room.location,
                  comments: room.comment,
                },
              ],
            };

            if (room.location?.trim() !== "") {
              newRoom.location = room.location;
            }

            if (room.comment?.trim() !== "") {
              newRoom.comments = room.comment;
            }

            return newRoom;
          });

          inventory.rooms = rooms;
        }

        inventory.meters = [];

        if (meters?.length > 0) {
          meters.forEach(meter => {
            let newMeter = {};

            if (meter.name?.trim() === "") {
              return;
            }

            newMeter.name = meter.name;

            if (meter.location?.trim() !== "") {
              newMeter.location = meter.location;
            }

            if (meter?.isSmart) {
              newMeter.is_smart = meter.isSmart;
            }

            if (meter.comment?.trim() !== "") {
              newMeter.comment = meter.comment;
            }

            let rs = meter.readings.filter(reading => reading?.trim() !== "");

            if (meter.isPrepaid) {
              newMeter.is_prepaid = true;

              rs = rs.map(reading =>
                new Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: "GBP",
                }).format(parseFloat(reading))
              );
            } else {
              newMeter.is_prepaid = false;
            }

            if (rs?.length > 0) {
              newMeter.readings = rs;
            }

            inventory.meters.push(newMeter);
          });
        }

        inventory.tenancy_id = tenancyId;
        inventory.number_of_pages = numberOfPhotoPages + 2;
        inventory.order = [...new Set(folderKeysOrder)];

        if (!inventory.rooms) {
          inventory.rooms = [];
        }

        inventory.type = "inventory";

        let folderComments = {};
        for (const key of foldersCommentMap) {
          const name = key[0];
          const comment = key[1];

          folderComments[name] = comment;
        }

        inventory.folder_comments = folderComments;
        inventory.photo_pages = photoPages;

        if (!isFurnished) {
          inventory.soft_furnishings_code = 0;
        } else if (isFurnished && isFurnishingFireSafe === true) {
          inventory.soft_furnishings_code = 1;
        } else if (isFurnished && isFurnishingFireSafe === false) {
          inventory.soft_furnishings_code = 2;
        }

        try {
          const response = await axios.post("https://api.propertyjot.com/report", inventory, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          });

          if (response?.status === 200) {
            window.location.reload();
          }
        } catch (err) {
          console.error(err);
        }
      } else if (isCreatedInventory) {
        // try {
        //   const response = await axios.post(
        //     `https://api.propertyjot.com/deliver/${inventoryUUID}`,
        //     {
        //       emails: emailPeople,
        //     },
        //     {
        //       headers: {
        //         Authorization: localStorage.getItem("token"),
        //       },
        //     }
        //   )
        //   if (response?.status === 200) {
        //   }
        // } catch (err) {
        //   console.error(err)
        // }
      } else {
        setIsForming(true);
      }
    } else {
      setIsSorting(true);
    }
  };

  useEffect(() => {
    if (isVerified) {
      try {
        onContinuePress();
      } catch (err) {
        console.error(err);
      }
    }
  }, [isVerified]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-screen h-screen no-scrollbar">
        <PacmanLoader color="#e11d48" />
      </div>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="min-h-screen bg-stone-50">
        <Menu />
        <div className="px-4 py-10">
          <div className="w-full max-w-screen-sm mx-auto md:max-w-screen-md lg:max-w-screen-lg">
            <div className="inline-flex items-center w-full gap-3 pb-6 border-b border-stone-300">
              <div className="h-10 overflow-hidden rounded-full aspect-square bg-stone-300">
                {property?.has_thumbnail ? (
                  <img
                    src={`https://api.propertyjot.com/thumbnails/${property?.id}.jpg`}
                    className="object-cover w-full h-full"
                  />
                ) : (
                  <div className="flex items-center justify-center w-10 h-10 bg-stone-300">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                      <path d="M14.916 2.404a.75.75 0 01-.32 1.012l-.596.31V17a1 1 0 01-1 1h-2.26a.75.75 0 01-.75-.75v-3.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.5a.75.75 0 01-.75.75h-3.5a.75.75 0 010-1.5H2V9.957a.75.75 0 01-.596-1.372L2 8.275V5.75a.75.75 0 011.5 0v1.745l10.404-5.41a.75.75 0 011.012.32zM15.861 8.57a.75.75 0 01.736-.025l1.999 1.04A.75.75 0 0118 10.957V16.5h.25a.75.75 0 110 1.5h-2a.75.75 0 01-.75-.75V9.21a.75.75 0 01.361-.64z" />
                    </svg>
                  </div>
                )}
              </div>
              <div className="flex items-center justify-between w-full">
                <div>
                  <div className="relative block font-medium text-left text-stone-950">
                    <h1 className="leading-none">{address?.trim()?.length === 0 ? "." : address}</h1>
                  </div>
                  <div className="flex-1">
                    <div className="relative inline-block text-xs text-stone-500">
                      <p className="">
                        {property?.address?.line_four}, {property?.address?.postcode}
                      </p>
                    </div>
                  </div>
                </div>
                {isCreatingInventory && (
                  <p
                    className={
                      isImplicitCheckout
                        ? "px-3 py-1.5 text-sm font-semibold text-white bg-indigo-700 border rounded-full shadow border-indigo-800"
                        : "px-3 py-1.5 text-sm font-semibold text-white bg-orange-700 border rounded-full shadow border-orange-800"
                    }
                  >
                    {isImplicitCheckout ? "Checkout" : "Inventory"}
                  </p>
                )}
              </div>
            </div>
            {/* <div className="grid grid-cols-3 pb-6 mt-6 border-b border-stone-300">
            <div>
              <p className="text-sm text-stone-500">Beds</p>
              <h1 className="text-base font-medium text=stone-950 mt-1.5">1</h1>
            </div>
            <div>
              <p className="text-sm text-stone-500">Baths</p>
              <h1 className="text-base font-medium text=stone-950 mt-1.5">1</h1>
            </div>
            <div>
              <p className="text-sm text-stone-500">Last Updated</p>
              <h1 className="text-base font-medium text=stone-950 mt-1.5">
                21 September 2023
              </h1>
            </div>
          </div> */}
            {!isCreatingInventory && !isCheckingOut && (
              <>
                <div className="grid grid-cols-2 gap-4 mt-6 sm:grid-cols-3">
                  <button
                    onClick={() => {
                      setIsCreatingInventory(true);
                      setIsSorting(true);
                    }}
                    className="flex flex-col w-full overflow-hidden text-left bg-white border rounded shadow border-stone-300 hover:bg-stone-100"
                  >
                    <div className="p-3">
                      <img src={inventoryImage} className="mx-auto h-36 sm:h-28 md:h-32" />
                      <h1 className="mt-6 font-medium text-stone-950">Inventory</h1>
                      <p className="mt-1 text-xs text-stone-500">
                        A comprehensive record of the condition and contents of a property at the time a new tenant
                        moves in.{" "}
                      </p>
                    </div>
                  </button>
                  <button
                    onClick={() => {
                      setIsCreatingInventory(true);
                      setIsSorting(true);
                      setIsImplicitCheckout(true);
                    }}
                    className="flex flex-col w-full overflow-hidden text-left bg-white border rounded shadow border-stone-300 hover:bg-stone-100"
                  >
                    <div className="p-3">
                      <img src={checkoutImage} className="mx-auto h-36 sm:h-28 md:h-32" />
                      <h1 className="mt-6 font-medium text-stone-950">Checkout</h1>
                      <p className="mt-1 text-xs text-stone-500">
                        A comprehensive record of the condition and contents of a property at the time an existing
                        tenant moves out.{" "}
                      </p>
                    </div>
                  </button>
                  {/* <button
                    className="flex flex-col w-full overflow-hidden text-left bg-white border rounded opacity-50 grayscale border-stone-300 hover:cursor-not-allowed"
                    disabled
                  >
                    <div className="p-3">
                      <img
                        src={disputeImage}
                        className="mx-auto h-36 sm:h-28 md:h-32"
                      />
                      <h1 className="mt-6 font-medium text-stone-950">
                        Dispute
                      </h1>
                      <p className="mt-1 text-xs text-stone-500">
                        A comprehensive assessment conducted at the end of a
                        tenant's occupancy.
                      </p>
                    </div>
                  </button> */}
                </div>
                <div className="px-4 mt-10">
                  {timeline?.map((report, index) => {
                    const data = JSON.parse(report?.data?.trim());
                    const type = data?.type === "inventory" ? "Inventory" : "Checkout";

                    // if (data?.archived === true) {
                    //   return null;
                    // }

                    return (
                      <div key={index}>
                        <ol className={`relative border-l border-stone-200`}>
                          <li className="ml-6">
                            <span className="absolute flex items-center justify-center w-6 h-6 rounded-full bg-stone-200 -left-3 ring-8 ring-stone-50">
                              <svg
                                className="w-3.5 h-3.5 text-stone-950"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                              </svg>
                            </span>
                            <h3 className="flex items-center mb-1 text-lg font-semibold text-stone-950">
                              {type}
                              {index === 0 && (
                                <span className="bg-stone-200 text-stone-950 text-xs rounded-full font-semibold mr-2 px-2.5 py-1 ml-3">
                                  Latest
                                </span>
                              )}
                            </h3>
                            <time className="block text-xs font-normal leading-none text-stone-400">
                              Produced on {data?.date} {inventories.length}
                            </time>
                            {/* <p className="mt-3 mb-4 text-sm font-normal text-stone-500">
                              Get access to over 20+ pages including a dashboard
                              layout, charts, kanban board, calendar, and
                              pre-order E-commerce & Marketing pages.
                            </p> */}
                            <div className="flex gap-2 pb-6">
                              <div>
                                <a
                                  target="_blank"
                                  href={`https://static.propertyjot.com/${report?.uuid}/${
                                    data?.property?.address?.split(",")?.[0]
                                  }.pdf`}
                                  download={address?.trim()?.length === 0 ? "." : address}
                                  className="inline-flex items-center px-4 py-2 mt-3 text-sm font-medium bg-white border rounded-lg text-stone-950 border-stone-200 hover:bg-stone-100 hover:text-stone-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-stone-200 focus:text-stone-700"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5 mr-2"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                                    />
                                  </svg>
                                  View PDF
                                </a>
                              </div>
                              <div>
                                <a
                                  target="_blank"
                                  onClick={() => {
                                    setMailReport(report);
                                  }}
                                  className="inline-flex items-center px-4 py-2 mt-3 text-sm font-medium bg-white border rounded-lg text-stone-950 border-stone-200 hover:bg-stone-100 hover:text-stone-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-stone-200 focus:text-stone-700 hover:cursor-pointer"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="w-5 h-5 mr-2"
                                  >
                                    <path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
                                    <path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
                                  </svg>
                                  Email
                                </a>
                              </div>
                              <div>
                                <a
                                  disabled
                                  className="inline-flex items-center px-4 py-2 mt-3 text-sm font-medium bg-white border rounded-lg opacity-25 pointer-events-none text-stone-950 border-stone-200 hover:bg-stone-100 hover:text-stone-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-stone-200 focus:text-stone-700 hover:cursor-pointer"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="w-5 h-5"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M15.621 4.379a3 3 0 0 0-4.242 0l-7 7a3 3 0 0 0 4.241 4.243h.001l.497-.5a.75.75 0 0 1 1.064 1.057l-.498.501-.002.002a4.5 4.5 0 0 1-6.364-6.364l7-7a4.5 4.5 0 0 1 6.368 6.36l-3.455 3.553A2.625 2.625 0 1 1 9.52 9.52l3.45-3.451a.75.75 0 1 1 1.061 1.06l-3.45 3.451a1.125 1.125 0 0 0 1.587 1.595l3.454-3.553a3 3 0 0 0 0-4.242Z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  Append
                                </a>
                              </div>
                              {type === "Inventory" && (
                                <div>
                                  <a
                                    className="inline-flex items-center px-4 py-2 mt-3 text-sm font-medium bg-white border rounded-lg text-stone-950 border-stone-200 hover:bg-stone-100 hover:text-stone-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-stone-200 focus:text-stone-700 hover:cursor-pointer"
                                    onClick={() => {
                                      const reportData = JSON.parse(report.data);
                                      const newReport = {
                                        ...report,
                                        ...reportData,
                                      };

                                      setCheckoutInventory(newReport);
                                      setIsCheckingOut(true);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="w-5 h-5 mr-2"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M8.25 9.75h4.875a2.625 2.625 0 0 1 0 5.25H12M8.25 9.75 10.5 7.5M8.25 9.75 10.5 12m9-7.243V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185Z"
                                      />
                                    </svg>
                                    Dispute Report
                                  </a>
                                </div>
                              )}
                            </div>
                          </li>
                        </ol>
                      </div>
                    );
                  })}
                </div>
                {/* <div className="grid gap-6 pl-3 mt-6">
                  <div className="px-4 py-3 overflow-hidden text-left bg-white border rounded shadow-sm border-stone-300">
                    <h1 className="text-base font-medium text=stone-950">
                      Inventories
                    </h1>
                    <p className="mt-1 text-sm text-stone-500">
                      There are 2 pending inventories.
                    </p>
                  </div>
                  <ol className="relative border-l border-stone-200">
                    <li className="ml-6">
                      <span className="absolute flex items-center justify-center w-6 h-6 rounded-full bg-stone-200 -left-3 ring-8 ring-stone-50">
                        <svg
                          className="w-3.5 h-3.5 text-stone-950"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                        </svg>
                      </span>
                      <h3 className="flex items-center mb-1 text-lg font-semibold text-stone-950">
                        Checkout{" "}
                        <span className="bg-stone-200 text-stone-950 text-xs rounded-full font-semibold mr-2 px-2.5 py-1 ml-3">
                          Latest
                        </span>
                      </h3>
                      <time className="block text-xs font-normal leading-none text-stone-400">
                        Produced on 21st September, 2023
                      </time>
                      <p className="mt-3 mb-4 text-sm font-normal text-stone-500">
                        Get access to over 20+ pages including a dashboard
                        layout, charts, kanban board, calendar, and pre-order
                        E-commerce & Marketing pages.
                      </p>
                      <a
                        href="#"
                        className="inline-flex items-center px-4 py-2 text-sm font-medium bg-white border rounded-lg text-stone-950 border-stone-200 hover:bg-stone-100 hover:text-stone-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-stone-200 focus:text-stone-700"
                      >
                        <svg
                          className="w-3.5 h-3.5 mr-2.5"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
                          <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                        </svg>{" "}
                        Download ZIP
                      </a>
                    </li>
                    <li className="mt-12 ml-6">
                      <span className="absolute flex items-center justify-center w-6 h-6 rounded-full bg-stone-200 -left-3 ring-8 ring-stone-50">
                        <svg
                          className="w-3.5 h-3.5 text-stone-950"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                        </svg>
                      </span>
                      <h3 className="flex items-center mb-1 text-lg font-semibold text-stone-950">
                        Inventory
                      </h3>
                      <time className="block text-xs font-normal leading-none text-stone-400">
                        Produced on 21st September, 2023
                      </time>
                      <p className="mt-3 mb-4 text-sm font-normal text-stone-500">
                        Get access to over 20+ pages including a dashboard
                        layout, charts, kanban board, calendar, and pre-order
                        E-commerce & Marketing pages.
                      </p>
                      <a
                        href="#"
                        className="inline-flex items-center px-4 py-2 text-sm font-medium bg-white border rounded-lg text-stone-950 border-stone-200 hover:bg-stone-100 hover:text-stone-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-stone-200 focus:text-stone-700"
                      >
                        <svg
                          className="w-3.5 h-3.5 mr-2.5"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
                          <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                        </svg>{" "}
                        Download ZIP
                      </a>
                    </li>
                    <li className="mb-10 ml-6">
                      <span className="absolute flex items-center justify-center w-6 h-6 rounded-full bg-stone-200 -left-3 ring-8 ring-stone-50">
                        <svg
                          className="w-3.5 h-3.5 text-stone-950"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                        </svg>
                      </span>
                      <h3 className="mb-1 text-lg font-semibold text-stone-950">
                        Invebto
                      </h3>
                      <time className="block mb-2 text-sm font-normal leading-none text-stone-400">
                        Released on December 7th, 2021
                      </time>
                      <p className="text-base font-normal text-stone-500">
                        All of the pages and components are first designed in
                        Figma and we keep a parity between the two versions even
                        as we update the project.
                      </p>
                    </li>
                    <li className="ml-6">
                      <span className="absolute flex items-center justify-center w-6 h-6 rounded-full bg-stone-200 -left-3 ring-8 ring-stone-50">
                        <svg
                          className="w-3.5 h-3.5 text-stone-950"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                        </svg>
                      </span>
                      <h3 className="mb-1 text-lg font-semibold text-stone-950">
                        Flowbite Library v1.2.2
                      </h3>
                      <time className="block mb-2 text-sm font-normal leading-none text-stone-400">
                        Released on December 2nd, 2021
                      </time>
                      <p className="text-base font-normal text-stone-500">
                        Get started with dozens of web components and
                        interactive elements built on top of Tailwind CSS.
                      </p>
                    </li>
                  </ol>
                </div> */}
              </>
            )}
          </div>
          {isCheckingOut && (
            <div className="fixed bottom-0 px-4 z-[99999999999999999] left-0 right-0 flex justify-center w-full border-t bg-stone-50 border-stone-300">
              <div className="flex items-center justify-between w-full gap-6 py-3 md:px-0 bg-stone-50 border-stone-300 md:max-w-screen-md lg:max-w-screen-lg">
                <button
                  onClick={() => {
                    if (isCheckingOutFolders) {
                      setIsCheckingOutFolders(false);
                    } else {
                      setIsCheckingOut(false);
                      setIsCheckingOutFolders(false);
                    }
                  }}
                  className="block px-2 py-1.5 text-xs font-semibold text-white border rounded shadow-sm bg-stone-700 border-stone-800"
                >
                  Back
                </button>
                <button
                  onClick={async () => {
                    if (!isCheckingOutFolders) {
                      setIsCheckingOutFolders(true);
                    } else {
                      let checkout_folders = [];

                      let allCheckoutImages = [];

                      for (const folder of ["Property Overview", ...checkoutFolders]) {
                        let f = {
                          name: folder,
                          issues: [],
                          comments: [],
                          clauses: [],
                          checkout_images: [],
                          inventory_images: [],
                        };

                        // console.log("Folder: ", folder);

                        {
                          let keys = [];
                          for (let key of checkoutFolderIssues.keys()) {
                            if (key === folder) {
                              keys.push(key);
                            }
                          }

                          let issues = [];
                          for (const key of keys) {
                            issues.push(checkoutFolderIssues.get(key));
                          }

                          if (keys?.length > 0) {
                            f.issues.push(issues);

                            for (const [index, issueArray] of issues.entries()) {
                              let issues = [];
                              for (const issue of issueArray) {
                                issues.push(issue);
                              }

                              // console.log("Issues:", issues);

                              let comments = [];
                              for (let i = 0; i < issues.length; i++) {
                                const key = `${folder}-${i}`;
                                const comment = checkoutIssueComments.get(key) || [];
                                comments.push(comment);
                              }

                              // console.log("Comments:", comments);
                              f.comments.push(comments);

                              let clauses = [];
                              for (let i = 0; i < issues.length; i++) {
                                const key = `${folder}-${i}`;
                                const clause = checkoutIssueClauses.get(key) || [];
                                clauses.push(clause);
                              }

                              // console.log("Clauses:", clauses);
                              f.clauses.push(clauses);

                              let checkoutImages = [];
                              for (let i = 0; i < issues.length; i++) {
                                const key = `${folder}-${i}`;
                                const imageFiles = checkoutIssuePics.get(key) || [];
                                const images = imageFiles.map(file => file.uuid);
                                checkoutImages.push([...images]);

                                // console.log(
                                //   selectedCheckoutUUIDs?.filter(item =>
                                //     item?.endsWith(`-${folder}-${i}`)
                                //   )
                                // );
                              }

                              // console.log("Checkout Images:", checkoutImages);
                              f.checkout_images.push(checkoutImages);
                              allCheckoutImages.push(...checkoutImages);
                              allCheckoutImages = allCheckoutImages.flat();

                              let inventoryImages = [];
                              for (let i = 0; i < issues.length; i++) {
                                const images = selectedCheckoutUUIDs?.filter(item => item?.endsWith(`-${folder}-${i}`));

                                for (const image of images) {
                                  const imageAsUUID = image.slice(0, 36);
                                  if (!allCheckoutImages.includes(imageAsUUID)) {
                                    inventoryImages.push(imageAsUUID);
                                  }
                                }

                                f.inventory_images.push(inventoryImages);
                                inventoryImages = [];
                              }
                            }

                            // {
                            //   let checkoutImages = [];
                            //   for (const slice of checkoutIssuePics) {
                            //     for (const fileArray of slice) {
                            //       for (const file of fileArray) {
                            //         const uuid = file?.uuid;
                            //         if (uuid) checkoutImages.push(uuid);
                            //       }
                            //     }
                            //   }

                            //   f.checkout_images.push(checkoutImages);

                            //   let inventoryImages = [];
                            //   for (const image of selectedCheckoutUUIDs) {
                            //     const imageAsUUID = image.slice(0, 36);
                            //     let filteredImages = [];

                            //     for (const otherImage of checkoutIssuePics) {
                            //       const uuid = otherImage?.[1]?.[0]?.uuid;
                            //       if (imageAsUUID === otherImage) {
                            //         filteredImages.push(uuid);
                            //       }
                            //     }

                            //     if (!filteredImages.includes(imageAsUUID)) {
                            //       inventoryImages.push(imageAsUUID);
                            //     }
                            //   }

                            //   f.inventory_images.push(
                            //     inventoryImages.filter(
                            //       img => checkoutImages.includes(img) === false
                            //     )
                            //   );
                            // }

                            checkout_folders.push(f);
                          }
                        }
                      }

                      const data = {
                        property: property,
                        inventory_uuid: checkoutInventory?.uuid,
                        folders: checkout_folders,
                        tenancy_id: tenancyId,
                        date: checkoutDate?.toLocaleDateString("en-GB", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }),
                      };

                      try {
                        const response = await axios.post("https://api.propertyjot.com/checkout/generate-pdf", data, {
                          headers: {
                            Authorization: localStorage.getItem("token"),
                          },
                          responseType: "blob",
                        });

                        if (response?.status === 200) {
                          const url = window.URL.createObjectURL(new Blob([response.data]));

                          const link = document.createElement("a");
                          link.href = url;
                          link.target = "_blank";
                          link.setAttribute("download", `${property?.address?.line_one}.pdf`);

                          link.click();
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }
                  }}
                  className="block px-2 py-1.5 text-xs font-semibold
                      text-white border rounded shadow-sm bg-rose-500
                      border-rose-600"
                >
                  Continue
                </button>
              </div>
            </div>
          )}
          {isCreatingInventory && (
            <div className="fixed bottom-0 left-0 right-0 z-50 flex justify-center w-full border-t bg-stone-50 border-stone-300">
              <div className="flex items-center justify-between w-full gap-6 py-3 bg-stone-50 border-stone-300 md:max-w-screen-md lg:max-w-screen-lg">
                {isCreatingInventory ? (
                  <button
                    onClick={() => {
                      if (isForming) {
                        setIsForming(false);
                      } else {
                        if (isPreviewing) {
                          setIsPreviewing(false);
                          setIsForming(true);
                        } else {
                          setIsImplicitCheckout(false);
                          setIsSorting(false);
                          setIsCreatingInventory(false);
                          setCompressedFiles([]);
                          setFiles([]);
                        }
                      }
                    }}
                    className="block px-2 py-1.5 text-xs font-semibold text-white border rounded shadow-sm bg-stone-700 border-stone-800"
                  >
                    Back
                  </button>
                ) : (
                  <Link
                    to="/properties"
                    className="block px-2 py-1.5 text-xs font-semibold text-white border rounded shadow-sm bg-stone-700 border-stone-800"
                  >
                    Back
                  </Link>
                )}
                {isFurnished && isFurnishingFireSafe === false && (
                  <div className="flex items-center justify-center gap-1 p-2 px-3 bg-red-600 border border-red-700 rounded-sm shadow-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="block w-4 h-4 pt-0.5 text-red-50"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495ZM10 5a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 10 5Zm0 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <p className="text-xs font-semibold text-white">
                      As this property has soft furnishings with missing fire resistance labels, it is not legally
                      eligable to be let.
                    </p>
                  </div>
                )}
                {((isProcessing && processingIndex !== processingFilesCount) ||
                  (isUploading && uploadingIndex !== files?.length)) && (
                  <div className="flex-1 max-w-sm">
                    <h1 className="text-[10px] font-medium text-stone-600">
                      {isProcessing ? "Importing..." : `Uploading (${uploadingIndex + 1} of ${files?.length})`}{" "}
                    </h1>
                    <div className="relative h-1.5 mt-1.5 overflow-hidden rounded-full bg-stone-300">
                      <div
                        className="absolute top-0 bottom-0 left-0 bg-rose-500"
                        style={{
                          width: isProcessing
                            ? `${Math.min((processingIndex / files?.length) * 100, 100)}%`
                            : `${Math.min((uploadingIndex / files?.length) * 100, 100)}%`,
                        }}
                      />
                    </div>
                  </div>
                )}
                {((files?.length >= 0 && !isPreviewing) ||
                  (isPreviewing && !isUploading && !isProcessing) ||
                  (isPreviewing && !isNaN(tenancyId))) &&
                ((isFurnished && isFurnishingFireSafe === true) || isFurnishingFireSafe === false || !isFurnished) ? (
                  <button
                    disabled={
                      isPreviewing ? isUploading || isProcessing : isPreviewing && isNaN(tenancyId) ? true : false
                    }
                    onClick={onContinuePress}
                    className="block px-2 py-1.5 text-xs font-semibold text-white border rounded shadow-sm bg-rose-500 border-rose-600"
                  >
                    Continue
                  </button>
                ) : (
                  <button
                    disabled
                    className="block px-2 py-1.5 text-xs font-semibold text-stone-500 border rounded shadow-sm bg-stone-300 border-stone-400 hover:cursor-not-allowed"
                  >
                    Continue
                  </button>
                )}
              </div>
            </div>
          )}
          {isCheckingOut && isCheckingOutFolders && (
            <div className="flex-1 w-full max-w-screen-sm pb-16 mx-auto mt-4 md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-lg">
              {/* <h1 className="mt-6 font-medium text-stone-950">Folders</h1>
              <p className="mt-1 text-xs text-stone-500">
                Here's a list of all the folders that were present in the
                inventory. Click on each folder to add, modify or remove any
                issues.
              </p> */}
              <div className="grid grid-cols-1 gap-10 pb-0 mt-6">
                {["Property Overview", ...checkoutFolders].map((folder, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-col w-full text-left bg-white border rounded shadow border-stone-300"
                    >
                      <div className="w-full p-4">
                        <div className="w-full">
                          <h1 className="text-xl font-medium text-stone-950 border-stone-400">{folder}</h1>
                        </div>
                        <div className="flex flex-col gap-y-6">
                          {checkoutFolderIssues?.get(folder)?.length > 0
                            ? (checkoutFolderIssues?.get(folder) || [])?.map((k, v) => (
                                <div key={v}>
                                  <div className="flex items-center gap-1 pt-4 mt-4 border-t border-stone-300">
                                    <div className="flex items-center w-full gap-1">
                                      {/* <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="w-5 h-5 text-stone-900"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                                            clipRule="evenodd"
                                          />
                                        </svg> */}
                                      <div className="flex items-center justify-between w-full">
                                        <p className="text-lg font-semibold text-rose-700">{k}</p>
                                        {/* {!checkoutSaves?.includes(
                                            `${folder}-${v}`
                                          ) ? (
                                            <button
                                              onClick={() => {
                                                setCheckoutSaves(prev => [
                                                  ...prev,
                                                  `${folder}-${v}`,
                                                ]);
                                              }}
                                              className="block px-2 py-1.5 text-xs font-semibold text-white border rounded shadow-sm bg-rose-500 border-rose-600"
                                            >
                                              Save
                                            </button>
                                          ) : (
                                            <button
                                              onClick={() => {
                                                setCheckoutSaves(prev =>
                                                  prev.filter(
                                                    itm =>
                                                      itm !== `${folder}-${v}`
                                                  )
                                                );
                                              }}
                                              className="block px-2 py-1.5 text-xs font-semibold text-white border rounded shadow-sm bg-rose-500 border-rose-600"
                                            >
                                              Edit
                                            </button>
                                          )} */}
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="flex gap-4 mt-0">
                                      <div className="flex-1 block">
                                        <p className="mt-2 text-sm font-medium">Inventory</p>
                                        {checkoutInventory?.data && (
                                          <div>
                                            <div
                                              className={[
                                                "flex-1 h-72 gap-3 p-4 mt-2 mb-1 overflow-y-scroll bg-white border rounded shadow border-stone-300  no-scrollbar",
                                                checkoutSaves?.includes(`${folder}-${v}`) &&
                                                selectedCheckoutUUIDs?.filter(item => item?.endsWith(`-${folder}-${v}`))
                                                  ?.length === 0
                                                  ? "flex justify-center items-center"
                                                  : " grid-cols-5 grid",
                                              ].join(" ")}
                                            >
                                              {checkoutSaves?.includes(`${folder}-${v}`) &&
                                                selectedCheckoutUUIDs?.filter(item => item?.endsWith(`-${folder}-${v}`))
                                                  ?.length === 0 && (
                                                  <div className="block">
                                                    <div>
                                                      <img
                                                        src={emptyImage}
                                                        className="h-24 mx-auto select-none"
                                                        alt="Empty Image"
                                                      />
                                                    </div>
                                                  </div>
                                                )}

                                              {folder !== "Property Overview" &&
                                                checkoutInventory?.data &&
                                                JSON.parse(checkoutInventory?.data).folders[folder]?.map(
                                                  (photo, index) => {
                                                    // no thumbnail loader picker from API
                                                    return null;

                                                    const isSelected = selectedCheckoutUUIDs.includes(
                                                      `${photo}-${folder}-${v}`
                                                    );

                                                    const isSaved = checkoutSaves.includes(`${folder}-${v}`);

                                                    if ((isSaved && isSelected) || !isSaved) {
                                                      return (
                                                        <div
                                                          className="relative select-none aspect-square"
                                                          target="_blank"
                                                          key={index}
                                                        >
                                                          <LazyLoadImage
                                                            loading="lazy"
                                                            className="block object-cover w-full h-full min-w-0 border rounded-sm shadow-sm hover:cursor-pointer bg-stone-200 border-stone-300"
                                                            src={`https://api.propertyjot.com/image-thumbnail/${photo}`}
                                                            onClick={() => {
                                                              window.open(
                                                                `https://static.propertyjot.com/${photo}.jpg`,
                                                                "_blank"
                                                              );
                                                            }}
                                                          />
                                                          {!isSelected && !isSaved && folder !== "Property Overview" ? (
                                                            <button
                                                              className="absolute w-5 h-5 bg-white border rounded-full shadow-xl focus:outline-none -top-1 -right-1 border-stone-300 hover:bg-stone-100"
                                                              onClick={() => {
                                                                setSelectedCheckoutUUIDs(prev => [
                                                                  ...new Set([...prev, `${photo}-${folder}-${v}`]),
                                                                ]);
                                                              }}
                                                            />
                                                          ) : isSaved || folder === "Property Overview" ? null : (
                                                            <button
                                                              className="absolute flex items-center justify-center w-5 h-5 bg-white border rounded-full shadow-xl focus:outline-none -top-1 -right-1 border-stone-300 hover:bg-stone-100"
                                                              onClick={() => {
                                                                setSelectedCheckoutUUIDs(prev => [
                                                                  ...new Set(
                                                                    prev.filter(
                                                                      uuid => uuid !== `${photo}-${folder}-${v}`
                                                                    )
                                                                  ),
                                                                ]);
                                                              }}
                                                            >
                                                              <div className="w-3.5 h-3.5 rounded-full bg-rose-600"></div>
                                                            </button>
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  }
                                                )}

                                              {disputeIssueSelectedFolders.has(folder) && checkoutInventory?.data && (
                                                <div className="flex items-center justify-center">
                                                  <button
                                                    className="relative rounded-sm p-2.5 hover:bg-stone-100"
                                                    onDoubleClick={() => {
                                                      setDisputeIssueSelectedFolders(prev => {
                                                        const newMap = new Map(prev);
                                                        newMap.delete(folder);

                                                        return newMap;
                                                      });
                                                    }}
                                                  >
                                                    <h1 className="text-4xl">🔙</h1>
                                                    <p className="text-[0.625rem] mt-1 leading-3 text-stone-500">
                                                      Back
                                                    </p>
                                                  </button>
                                                </div>
                                              )}

                                              {!checkoutSaves.includes(`${folder}-${v}`) &&
                                                !disputeIssueSelectedFolders.has(folder) &&
                                                folder === "Property Overview" &&
                                                checkoutInventory?.data &&
                                                Object.keys(JSON.parse(checkoutInventory.data).folders).map(
                                                  (_folder, folderIndex) => {
                                                    // new folder system full overview
                                                    return (
                                                      <div
                                                        className="flex items-center justify-center flex-1"
                                                        key={folderIndex}
                                                      >
                                                        <button
                                                          className="relative p-2.5 rounded-sm hover:bg-stone-100"
                                                          onDoubleClick={() => {
                                                            setDisputeIssueSelectedFolders(prev => {
                                                              const newMap = new Map(prev);
                                                              newMap.set(folder, _folder);

                                                              return newMap;
                                                            });
                                                          }}
                                                        >
                                                          <h1 className="text-4xl">📁</h1>
                                                          <p className="text-[0.625rem] mt-1 leading-3 text-stone-500">
                                                            {_folder}
                                                          </p>
                                                        </button>
                                                      </div>
                                                    );
                                                  }
                                                )}

                                              {disputeIssueSelectedFolders.has(folder) &&
                                                folder === "Property Overview" &&
                                                checkoutInventory?.data &&
                                                Object.keys(JSON.parse(checkoutInventory.data).folders).map(
                                                  (_folder, folderIndex) =>
                                                    JSON.parse(checkoutInventory.data).folders[_folder]?.map(
                                                      (photo, index) => {
                                                        if (_folder !== disputeIssueSelectedFolders.get(folder))
                                                          return null;
                                                        // 2nd api no return thunbnail preview select picker
                                                        // return null;

                                                        const isSelected = selectedCheckoutUUIDs.includes(
                                                          `${photo}-${folder}-${v}`
                                                        );

                                                        const isSaved = checkoutSaves.includes(`${folder}-${v}`);

                                                        if (isSaved && !isSelected) {
                                                          return null;
                                                        }

                                                        return (
                                                          <div
                                                            className="relative select-none aspect-square"
                                                            target="_blank"
                                                            key={index}
                                                          >
                                                            <img
                                                              loading="lazy"
                                                              className="block object-cover w-full h-full min-w-0 border rounded-sm shadow-sm hover:cursor-pointer bg-stone-200 border-stone-300"
                                                              src={`https://api.propertyjot.com/image-thumbnail/${photo}`}
                                                              onClick={() => {
                                                                window.open(
                                                                  `https://static.propertyjot.com/${photo}.jpg`,
                                                                  "_blank"
                                                                );
                                                              }}
                                                            />
                                                            {!isSelected && !isSaved ? (
                                                              <button
                                                                className="absolute w-5 h-5 bg-white border rounded-full shadow-xl focus:outline-none -top-1 -right-1 border-stone-300 hover:bg-stone-100"
                                                                onClick={() => {
                                                                  setSelectedCheckoutUUIDs(prev => [
                                                                    ...new Set([...prev, `${photo}-${folder}-${v}`]),
                                                                  ]);
                                                                }}
                                                              />
                                                            ) : isSaved ? null : (
                                                              <button
                                                                className="absolute flex items-center justify-center w-5 h-5 bg-white border rounded-full shadow-xl focus:outline-none -top-1 -right-1 border-stone-300 hover:bg-stone-100"
                                                                onClick={() => {
                                                                  setSelectedCheckoutUUIDs(prev => [
                                                                    ...new Set(
                                                                      prev.filter(
                                                                        uuid => uuid !== `${photo}-${folder}-${v}`
                                                                      )
                                                                    ),
                                                                  ]);
                                                                }}
                                                              >
                                                                <div className="w-3.5 h-3.5 rounded-full bg-rose-600"></div>
                                                              </button>
                                                            )}
                                                          </div>
                                                        );
                                                      }
                                                    )
                                                )}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div className="relative flex-1">
                                        <div className="flex items-center justify-between w-full mt-2">
                                          <p className="text-sm font-medium">Checkout</p>
                                          {/* <button className="text-sm font-medium text-white rounded border px-2 py-0.5 shadow-sm bg-rose-500 border-rose-600">
                                                Use images from a report
                                              </button> */}
                                        </div>
                                        {Array.from(checkoutIssuePics.entries())
                                          .filter(([key, _]) => key.startsWith(folder))
                                          .flatMap(([_, files]) => files)?.length === 0 ? (
                                          <div
                                            className={[
                                              "justify-center flex-1 h-72 gap-3 p-4 mt-2 mb-1 overflow-hidden bg-white border rounded shadow flexs border-stone-300  no-scrollbar",
                                              checkoutSaves?.find(item => item === `${folder}-${v}`) &&
                                                "items-center flex",
                                            ].join(" ")}
                                            style={{ zIndex: 1 }}
                                          >
                                            <div className="block">
                                              <div>
                                                <img
                                                  src={emptyImage}
                                                  className="h-24 mx-auto select-none"
                                                  alt="Empty Image"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div
                                            className="grid flex-1 grid-cols-5 gap-3 p-4 mt-2 mb-4 overflow-hidden overflow-y-scroll bg-white border rounded shadow h-72 border-stone-300 no-scrollbar"
                                            style={{ zIndex: 1 }}
                                          >
                                            {checkoutIssuePics?.get(`${folder}-${v}`)?.map((file, index) => (
                                              <CheckoutImageThumb
                                                isSaved={checkoutSaves?.find(item => item === `${folder}-${v}`)}
                                                key={index}
                                                file={file}
                                                index={index}
                                                folder={folder}
                                                v={v}
                                                selectedCheckoutUUIDs={selectedCheckoutUUIDs}
                                                setSelectedCheckoutUUIDs={setSelectedCheckoutUUIDs}
                                              />
                                            ))}
                                          </div>
                                        )}

                                        {!checkoutSaves?.find(item => item === `${folder}-${v}`) && (
                                          <div
                                            className="absolute px-4 pb-4 left-[1px] right-[1px] bg-rose-50 bottom-[4px] overflow-hidden rounded-b"
                                            style={{ zIndex: 2 }}
                                          >
                                            <CheckoutImageDrop
                                              checkoutIssuePics={checkoutIssuePics}
                                              checkoutIssueExcludedPics={checkoutIssueExcludedPics}
                                              setCheckoutIssuePics={setCheckoutIssuePics}
                                              folder={folder}
                                              index={v}
                                              setSelectedCheckoutUUIDs={setSelectedCheckoutUUIDs}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    {checkoutIssueComments.get(`${folder}-${v}`)?.length
                                      ? checkoutSaves.includes(`${folder}-${v}`) >= 1 && (
                                          <h1 className="mt-2 font-medium text-stone-950">Comments</h1>
                                        )
                                      : null}

                                    {!checkoutSaves?.includes(`${folder}-${v}`) ? (
                                      <>
                                        <h1 className="mt-2 font-medium text-stone-950">Comments</h1>
                                        <div className="mt-2.5">
                                          <Creatable
                                            isMulti
                                            className="w-full px-3 py-3 text-sm font-medium bg-white rounded shadow-sm focus:outline-none placeholder:text-stone-400 text-stone-950"
                                            options={[
                                              {
                                                value: "Minor scratches or scuffs on walls/flooring",
                                                label: "Minor scratches or scuffs on walls/flooring",
                                              },
                                              {
                                                value: "Stains on carpet or upholstery",
                                                label: "Stains on carpet or upholstery",
                                              },
                                              {
                                                value: "Dusty or dirty surfaces",
                                                label: "Dusty or dirty surfaces",
                                              },
                                              {
                                                value: "Broken or damaged fixtures (light switches, door handles)",
                                                label: "Broken or damaged fixtures (light switches, door handles)",
                                              },
                                              {
                                                value: "Missing or damaged furnishings (chairs, tables)",
                                                label: "Missing or damaged furnishings (chairs, tables)",
                                              },
                                              {
                                                value: "Signs of pests (cockroaches, mice)",
                                                label: "Signs of pests (cockroaches, mice)",
                                              },
                                              {
                                                value: "Strong odors (cigarette smoke, pet smells)",
                                                label: "Strong odors (cigarette smoke, pet smells)",
                                              },
                                              {
                                                value: "Excessive dirt or grime in kitchen or bathroom",
                                                label: "Excessive dirt or grime in kitchen or bathroom",
                                              },
                                              {
                                                value: "Worn or damaged flooring (scratches, tears)",
                                                label: "Worn or damaged flooring (scratches, tears)",
                                              },
                                              {
                                                value: "Damaged walls (holes, cracks)",
                                                label: "Damaged walls (holes, cracks)",
                                              },
                                            ]}
                                            onChange={(value, actionMeta) => {
                                              setCheckoutIssueComments(prevMap => {
                                                const newMap = new Map(prevMap);

                                                newMap.set(
                                                  `${folder}-${v}`,
                                                  value?.map(({ value }) => value)
                                                );

                                                return newMap;
                                              });
                                            }}
                                            value={
                                              checkoutIssueComments.get(`${folder}-${v}`)?.map(item => ({
                                                value: item,
                                                label: item,
                                              })) || []
                                            }
                                            styles={{
                                              container: provided => ({
                                                ...provided,
                                                padding: 0,
                                                zIndex: 999999,
                                              }),
                                              control: provided => ({
                                                ...provided,
                                                borderColor: "#d5d3d1",
                                                zIndex: 9999,
                                                borderWidth: 1,
                                                paddingTop: "0.125rem",
                                                paddingBottom: "0.125rem",
                                                paddingLeft: "0.25rem",
                                                paddingRight: "0.25rem",
                                              }),
                                            }}
                                          />
                                        </div>
                                      </>
                                    ) : checkoutIssueComments.get(`${folder}-${v}`)?.length >= 1 ? (
                                      <div className="mt-1 flex flex-col gap-1.5">
                                        {checkoutIssueComments.get(`${folder}-${v}`)?.map((item, index) => (
                                          <p className="text-xs text-stone-500" key={index}>
                                            {item}
                                          </p>
                                        ))}
                                      </div>
                                    ) : null}

                                    {checkoutIssueClauses?.get(`${folder}-${v}`)?.length >= 1 &&
                                      checkoutSaves?.includes(`${folder}-${v}`) && (
                                        <h1 className="mt-2 font-medium text-stone-950">Clauses</h1>
                                      )}

                                    {checkoutSaves?.includes(`${folder}-${v}`) &&
                                      checkoutIssueClauses.get(`${folder}-${v}`)?.length >= 1 && (
                                        <div className="mt-1 flex flex-col gap-1.5">
                                          {checkoutIssueClauses.get(`${folder}-${v}`)?.map((item, index) => (
                                            <p className="text-xs text-stone-500" key={index}>
                                              {item}
                                            </p>
                                          ))}
                                        </div>
                                      )}

                                    {checkoutSaves?.includes(`${folder}-${v}`) ? null : (
                                      <>
                                        <h1 className="mt-5 font-medium text-stone-950">Clauses</h1>
                                        <div className="mt-2.5 mb-2">
                                          <Creatable
                                            isMulti
                                            className="w-full px-3 py-3 text-sm font-medium bg-white rounded shadow-sm focus:outline-none placeholder:text-stone-400 text-stone-950"
                                            options={[
                                              {
                                                value: "Damage to property beyond normal wear and tear",
                                                label: "Damage to property beyond normal wear and tear",
                                              },
                                              {
                                                value: "Unpaid rent or utilities at the end of the tenancy",
                                                label: "Unpaid rent or utilities at the end of the tenancy",
                                              },
                                              {
                                                value:
                                                  "Cleaning expenses if the property is not returned in the same condition as at the start of the tenancy",
                                                label:
                                                  "Cleaning expenses if the property is not returned in the same condition as at the start of the tenancy",
                                              },
                                              {
                                                value:
                                                  "Costs associated with repairing any damages caused by the tenant or their guests",
                                                label:
                                                  "Costs associated with repairing any damages caused by the tenant or their guests",
                                              },
                                              {
                                                value:
                                                  "Replacement or repair of any missing or broken items listed in the inventory",
                                                label:
                                                  "Replacement or repair of any missing or broken items listed in the inventory",
                                              },
                                              {
                                                value:
                                                  "Fees for late return of keys or failure to return all issued keys",
                                                label:
                                                  "Fees for late return of keys or failure to return all issued keys",
                                              },
                                              {
                                                value:
                                                  "Outstanding charges for any agreed-upon services, such as gardening or cleaning",
                                                label:
                                                  "Outstanding charges for any agreed-upon services, such as gardening or cleaning",
                                              },
                                              {
                                                value:
                                                  "Any breach of the tenancy agreement, such as unauthorized subletting or keeping pets without permission",
                                                label:
                                                  "Any breach of the tenancy agreement, such as unauthorized subletting or keeping pets without permission",
                                              },
                                              {
                                                value:
                                                  "Expenses related to restoring the property to its original state if alterations or decorations were made without permission",
                                                label:
                                                  "Expenses related to restoring the property to its original state if alterations or decorations were made without permission",
                                              },
                                              {
                                                value:
                                                  "Any other reasonable expenses incurred as a result of the tenant's actions or negligence during the tenancy",
                                                label:
                                                  "Any other reasonable expenses incurred as a result of the tenant's actions or negligence during the tenancy",
                                              },
                                            ]}
                                            onChange={(value, actionMeta) => {
                                              setCheckoutIssueClauses(prevMap => {
                                                const newMap = new Map(prevMap);

                                                newMap.set(
                                                  `${folder}-${v}`,
                                                  value?.map(({ value }) => value)
                                                );

                                                return newMap;
                                              });
                                            }}
                                            value={
                                              checkoutIssueClauses.get(`${folder}-${v}`)?.map(item => ({
                                                value: item,
                                                label: item,
                                              })) || []
                                            }
                                            styles={{
                                              container: provided => ({
                                                ...provided,
                                                padding: 0,
                                                zIndex: 999,
                                              }),
                                              control: provided => ({
                                                ...provided,
                                                borderColor: "#d5d3d1",
                                                zIndex: 999,
                                                borderWidth: 1,
                                                paddingTop: "0.125rem",
                                                paddingBottom: "0.125rem",
                                                paddingLeft: "0.25rem",
                                                paddingRight: "0.25rem",
                                              }),
                                            }}
                                          />
                                        </div>
                                      </>
                                    )}

                                    {checkoutIssueAmounts?.get(`${folder}-${v}`)?.length >= 1 &&
                                      checkoutSaves?.includes(`${folder}-${v}`) && (
                                        <h1 className="mt-2 font-medium text-stone-950">Claimed Amount</h1>
                                      )}
                                    {checkoutSaves?.includes(`${folder}-${v}`) ? null : (
                                      <>
                                        <h1 className="mt-5 font-medium text-stone-950">Claimed Amount</h1>
                                      </>
                                    )}

                                    {!checkoutSaves?.includes(`${folder}-${v}`) && (
                                      <>
                                        <h1 className="mt-5 font-medium text-stone-950">Invoices & Receipts</h1>
                                        <div className="mb-4 mt-2.5 flex border rounded shadow border-stone-300">
                                          <div className="flex-1 p-4 bg-red-50">
                                            <CheckoutFileDrop
                                              folder={folder}
                                              index={v}
                                              setCheckoutIssueDocuments={setCheckoutIssueDocuments}
                                            />
                                          </div>
                                          <div className="grid flex-1 grid-cols-5 gap-4 p-4">
                                            {checkoutIssueDocuments?.get(`${folder}-${v}`)?.map((item, idx) => (
                                              <button
                                                key={idx}
                                                className="overflow-hidden border rounded-sm shadow-sm thumbnail-container aspect-square border-stone-300"
                                                onClick={() => {
                                                  const fileURL = URL.createObjectURL(item.file);

                                                  window.open(fileURL);
                                                }}
                                              >
                                                <Document
                                                  className="object-contain w-[100px] h-[100px] overflow-hidden"
                                                  file={item.file}
                                                  onError={console.error}
                                                >
                                                  <Page
                                                    pageNumber={1}
                                                    width={100}
                                                    height={100}
                                                    renderTextLayer={false}
                                                  />
                                                </Document>
                                              </button>
                                            ))}
                                          </div>
                                        </div>
                                      </>
                                    )}

                                    {checkoutIssueDocuments?.get(`${folder}-${v}`)?.length >= 0 &&
                                      checkoutSaves?.includes(`${folder}-${v}`) && (
                                        <>
                                          <h1 className="mt-2 font-medium text-stone-950">Invoices & Receipts</h1>
                                          <div className="mb-4 mt-2.5 flex border rounded shadow border-stone-300">
                                            <div className="grid grid-cols-7 gap-4 p-4">
                                              {checkoutIssueDocuments?.get(`${folder}-${v}`)?.map((item, idx) => (
                                                <button
                                                  key={idx}
                                                  className="overflow-hidden border rounded-sm shadow-sm thumbnail-container aspect-square border-stone-300"
                                                  onClick={() => {
                                                    const fileURL = URL.createObjectURL(item.file);

                                                    window.open(fileURL);
                                                  }}
                                                >
                                                  <Document
                                                    className="object-contain w-[100px] h-[100px] overflow-hidden"
                                                    file={item.file}
                                                    onError={console.error}
                                                  >
                                                    <Page
                                                      pageNumber={1}
                                                      width={100}
                                                      height={100}
                                                      renderTextLayer={false}
                                                    />
                                                  </Document>
                                                </button>
                                              ))}
                                            </div>
                                          </div>
                                        </>
                                      )}

                                    <div className="block mt-4">
                                      <div className="flex justify-between gap-3">
                                        <button
                                          onClick={() => {
                                            setCheckoutFolderIssues(prevMap => {
                                              const _prevMap = new Map(prevMap);

                                              let issues = _prevMap.get(folder);

                                              issues = issues.filter((_, i) => i !== v);

                                              _prevMap.set(folder, issues);

                                              return _prevMap;
                                            });

                                            setSelectedCheckoutUUIDs(prev => {
                                              return [
                                                ...new Set(prev.filter(item => !item.endsWith(`${folder}-${v}`))),
                                              ];
                                            });

                                            setCheckoutIssuePics(prevMap => {
                                              const _prevMap = new Map(prevMap);

                                              _prevMap.delete(`${folder}-${v}`);
                                              return _prevMap;
                                            });

                                            setCheckoutSaves(prev => prev.filter(item => item !== `${folder}-${v}`));
                                          }}
                                          className="px-6 py-2.5 text-sm font-semibold text-white border rounded shadow bg-stone-800 border-stone-950"
                                        >
                                          Delete
                                        </button>
                                        <button
                                          onClick={() => {
                                            if (!checkoutSaves?.includes(`${folder}-${v}`)) {
                                              setCheckoutSaves(prev => [...prev, `${folder}-${v}`]);
                                            } else {
                                              setCheckoutSaves(prev => prev.filter(item => item !== `${folder}-${v}`));
                                            }
                                          }}
                                          className="px-6 py-2.5 text-sm font-semibold text-white border rounded shadow bg-rose-500 border-rose-600"
                                        >
                                          {checkoutSaves?.includes(`${folder}-${v}`) ? "Edit" : "Save"}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                        <div className="flex items-center gap-2 pt-4 mt-3 border-t border-stone-200">
                          <Select
                            className="z-10 w-full max-w-xs px-3 py-3 text-sm font-medium bg-white rounded-md shadow-sm focus:outline-none placeholder:text-stone-400 text-stone-950"
                            options={[
                              {
                                value: 0,
                                label: "No issues",
                              },
                              {
                                value: 1,
                                label: "Cleaning",
                              },
                              {
                                value: 2,
                                label: "Damage to property",
                              },
                              {
                                value: 3,
                                label: "Missing items",
                              },
                              {
                                value: 4,
                                label: "Redecoration",
                              },
                              {
                                value: 5,
                                label: "Gardening",
                              },
                            ]}
                            defaultValue={{
                              value: 0,
                              label: "No issues",
                            }}
                            onChange={v => {
                              setCheckoutIssuesLive(prev => {
                                const _prev = new Map(prev);
                                _prev.set(folder, v.label);
                                return _prev;
                              });
                            }}
                            styles={{
                              container: provided => ({
                                ...provided,
                                padding: 0,
                                zIndex: 9999999 * (Object.entries(checkoutFolders).length - index),
                              }),
                              control: provided => ({
                                ...provided,
                                borderColor: "#d5d3d1",
                                zIndex: 999999 * (Object.entries(checkoutFolders).length - index),
                                borderWidth: 1,
                                paddingTop: "0.125rem",
                                paddingBottom: "0.125rem",
                                paddingLeft: "0.25rem",
                                paddingRight: "0.25rem",
                              }),
                            }}
                          />
                          <div className="p-1">
                            <button
                              disabled={false}
                              onClick={() => {
                                setCheckoutFolderIssues(prev => {
                                  const _prev = new Map(prev);
                                  const issues = _prev.get(folder) || [];

                                  const liveIssue = checkoutIssuesLive.get(folder);

                                  issues.push(liveIssue);
                                  _prev.set(folder, issues);
                                  return _prev;
                                });
                              }}
                              className="block px-3 py-2.5 overflow-hidden text-sm font-semibold text-white border rounded-md shadow-sm bg-rose-500 border-rose-600"
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {isCheckingOut && !isCheckingOutFolders && (
            <div className="flex-1 w-full max-w-screen-sm mx-auto mt-4 md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-lg">
              {/* <p className="mt-3 text-xs font-semibold text-stone-950">
                How many floors does the property have?
              </p> */}
              {/* <div className="flex mt-1.5 gap-3 items-center">
                <Creatable
                  options={[
                    { value: "1", label: "Ground Floor" },
                    { value: "2", label: "1 Floor" },
                    { value: "3", label: "2 Floors" },
                    { value: "4", label: "3 Floors" },
                    { value: "4", label: "4 Floors" },
                    { value: "4", label: "5 Floors" },
                  ]
                    ?.sort((a, b) => a.value.localeCompare(b.value))
                    ?.filter(item => !customFolders?.includes(item.value))}
                  className="w-full px-3 py-2 text-sm font-medium bg-white border rounded shadow-sm border-stone-300 focus:outline-none placeholder:text-stone-400 text-stone-950"
                  styles={{
                    container: provided => ({
                      ...provided,
                      padding: 0,
                      border: 0,
                      zIndex: 999,
                    }),
                    control: provided => ({
                      ...provided,
                      borderColor: "#e7e5e4",
                      zIndex: 999,
                    }),
                  }}
                  isValidNewOption={e => {
                    if (e.length === 0) return false

                    return Number.parseInt(e) > 3
                  }}
                  placeholder="Select or start typing..."
                  onCreateOption={e => {
                    setCustomFolders(prev => [...new Set([...prev, e])])
                  }}
                  onChange={e => {
                    setCustomFolders(prev => [...new Set([...prev, e.value])])
                  }}
                />
              </div> */}
              <div className="flex flex-col justify-between gap-2 md:flex-row">
                <div>
                  <h1 className="font-medium text-stone-950">Inventory date</h1>
                  <div className="mt-2.5 min-w-[8rem]">
                    <div className="bg-white">
                      <DatePicker
                        dateFormat={"d MMMM yy"}
                        className="px-3 w-full py-2.5 text-sm bg-white border rounded border-stone-300 opacity-50 select-none"
                        selected={new Date(JSON.parse(checkoutInventory?.data)?.date)}
                        maxDate={new Date()}
                        calendarClassName="bg-white border border-stone-300 rounded shadow font-sans"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <h1 className="font-medium text-stone-950">Checkout date</h1>
                  <div className="mt-2.5">
                    <DatePicker
                      dateFormat={"d MMMM yy"}
                      className="px-3 py-2.5 text-sm bg-white border rounded shadow-sm border-stone-300"
                      selected={checkoutDate}
                      calendarClassName="bg-white border border-stone-300 rounded shadow font-sans"
                      onChange={date => setCheckoutDate(date)}
                    />
                  </div>
                </div>
                <div className="flex-1 max-w-[8rem] min-w-[8rem]">
                  <h1 className="font-medium text-stone-950">Tenant present?</h1>
                  <div className="mt-2.5">
                    <Select
                      onChange={e => {
                        setWasTenantPresent(e);
                      }}
                      className="z-10 px-3 py-3 text-sm font-medium bg-white rounded shadow-sm focus:outline-none placeholder:text-stone-400 text-stone-950"
                      options={[
                        {
                          value: true,
                          label: "Yes",
                        },
                        {
                          value: false,
                          label: "No",
                        },
                      ]}
                      styles={{
                        container: provided => ({
                          ...provided,
                          padding: 0,
                          zIndex: 999,
                        }),
                        control: provided => ({
                          ...provided,
                          borderColor: "#d5d3d1",
                          zIndex: 999,
                          borderWidth: 1,
                          paddingTop: "0.125rem",
                          paddingBottom: "0.125rem",
                          paddingLeft: "0.25rem",
                          paddingRight: "0.25rem",
                        }),
                      }}
                      value={wasTenantPresent}
                    />
                  </div>
                </div>
                <div>
                  <h1 className="font-medium text-stone-950">Arrears</h1>
                  <div className="mt-2.5">
                    <CurrencyInput
                      className="px-3 py-2.5 text-sm flex gap-1 bg-white border rounded shadow-sm border-stone-300 placeholder:text-stone-400"
                      placeholder="£ 0.00 (N/A)"
                      decimalsLimit={2}
                      onValueChange={(value, name, values) => setTenantArrears(value)}
                      prefix="£ "
                    />
                  </div>
                </div>
                <div>
                  <h1 className="font-medium text-stone-950">Early Release</h1>
                  <div className="mt-2.5">
                    <CurrencyInput
                      className="px-3 py-2.5 text-sm flex gap-1 bg-white border rounded shadow-sm border-stone-300 placeholder:text-stone-400"
                      placeholder="£ 0.00 (N/A)"
                      decimalsLimit={2}
                      onValueChange={(value, name, values) => setEarlyRelease(value)}
                      prefix="£ "
                    />
                  </div>
                </div>
              </div>
              {tenantArrears > 0 ||
                (earlyRelease > 0 && (
                  <div>
                    <h1 className="mt-6 font-medium text-stone-950">Financial Information</h1>
                    <div className="mt-2.5">
                      <textarea
                        className="w-full p-4 bg-white border rounded shadow border-stone-300"
                        value={
                          tenantArrears > 0 && !earlyRelease
                            ? `The tenant is in arrears of £${tenantArrears}.`
                            : tenantArrears <= 0 && earlyRelease > 0
                            ? `The tenant has been released early and is due a refund of £${earlyRelease}.`
                            : `The tenant is in arrears of £${tenantArrears}`
                        }
                      />
                    </div>
                  </div>
                ))}
              <h1 className="mt-6 font-medium text-stone-950">Meters</h1>
              <div className="mt-4 mb-16 overflow-hidden">
                <div className="overflow-hidden bg-white border rounded shadow-sm border-stone-300 no-scrollbar">
                  <table className="w-full text-sm align-top bg-white">
                    <tr className="text-xs text-left border-b border-stone-300">
                      <th className="px-4 py-2">Meter</th>
                      <th className="px-4 py-2">Inventory Smart</th>
                      <th className="px-4 py-2">Inventory Readings</th>
                      <th className="px-4 py-2">Inventory Comments</th>
                      <th className="px-4 py-2">Checkout Smart</th>
                      <th className="px-4 py-2">Checkout Readings</th>
                      <th className="px-4 py-2">Checkout Comments</th>
                    </tr>
                    {allCheckoutMeters?.map &&
                      allCheckoutMeters.map((meter, index) => (
                        <MeterRowCheckout
                          key={index}
                          meter={meter}
                          setMeters={setMeters}
                          checkoutMeters={checkoutMeters}
                          setCheckoutMeters={setCheckoutMeters}
                        />
                      ))}
                  </table>
                  <div className="flex items-center justify-center w-full py-4 bg-stone-100">
                    <button
                      onClick={() => {
                        let nextName = "";

                        const meterNames = meters.map(meter => meter.name);
                        if (!meterNames.includes("Electric")) {
                          nextName = "Electric";
                        } else if (!meterNames.includes("Gas")) {
                          nextName = "Gas";
                        } else if (!meterNames.includes("Water")) {
                          nextName = "Water";
                        } else {
                          nextName = "Meter #1";
                        }

                        while (meterNames.includes(nextName)) {
                          const number = Number.parseInt(nextName.replace("Meter #", ""));

                          nextName = `Meter #${number + 1}`;
                        }

                        setMeters(prev => [
                          ...prev,
                          {
                            name: nextName,
                            isSmart: false,
                            readings: nextName === "Electric" ? ["", "", ""] : [""],
                            location: "",
                            comment: "",
                            isPrepaid: false,
                          },
                        ]);
                      }}
                      className="flex items-center justify-center w-8 h-8 text-white border rounded-full shadow bg-rose-500 border-rose-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-6 h-6"
                      >
                        <path d="M10.75 6.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <div
            className={`flex-col flex-1 w-full h-full max-w-screen-sm mx-auto md:max-w-screen-md lg:max-w-screen-lg ${
              isCreatingInventory && !isSorting
                ? "flex"
                : "hidden pointer-events-none"
            }`}
          >
            <div
              className={`flex-col flex-1 mt-4 ${
                isCreatingInventory && !isSorting
                  ? "flex"
                  : "hidden pointer-events-none"
              }`}
            >
              <div
                className={`w-full h-full flex-1 flex items-center justify-center border-2 p-6 border-dashed rounded border-stone-300 ${
                  isProcessing ? "hover:cursor-wait" : "hover:cursor-pointer"
                }`}
                {...getRootProps()}
              >
                <input {...getInputProps()} disabled={isProcessing} />
                {isProcessing ? (
                  <div className="flex flex-col items-center justify-center w-full h-full">
                    <img src={processingFilesImage} className="h-48" />
                    <div className="flex gap-4 mt-6">
                      <h1 className="mt-6 text-sm font-semibold text-stone-950">
                        Processing photo {processingIndex + 1} of photos{" "}
                        {processingFilesCount}...
                      </h1>
                    </div>
                  </div>
                ) : isDragActive ? (
                  <div className="flex flex-col items-center justify-center w-full h-full">
                    <img src={uploadFilesImage} className="h-48" />
                    <h1 className="mt-6 text-sm font-semibold text-stone-950">
                      Let go to upload
                    </h1>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center w-full h-full">
                    <img src={dropFilesImage} className="h-48" />
                    <h1 className="mt-6 text-sm font-semibold text-stone-950">
                      Drag and drop your photos here
                    </h1>
                  </div>
                )}
              </div>
            </div>
          </div> */}
          {isForming && (
            <div className="flex-1 w-full max-w-screen-sm mx-auto mt-4 md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-lg">
              {/* <p className="mt-3 text-xs font-semibold text-stone-950">
                How many floors does the property have?
              </p> */}
              {/* <div className="flex mt-1.5 gap-3 items-center">
                <Creatable
                  options={[
                    { value: "1", label: "Ground Floor" },
                    { value: "2", label: "1 Floor" },
                    { value: "3", label: "2 Floors" },
                    { value: "4", label: "3 Floors" },
                    { value: "4", label: "4 Floors" },
                    { value: "4", label: "5 Floors" },
                  ]
                    ?.sort((a, b) => a.value.localeCompare(b.value))
                    ?.filter(item => !customFolders?.includes(item.value))}
                  className="w-full px-3 py-2 text-sm font-medium bg-white border rounded shadow-sm border-stone-300 focus:outline-none placeholder:text-stone-400 text-stone-950"
                  styles={{
                    container: provided => ({
                      ...provided,
                      padding: 0,
                      border: 0,
                      zIndex: 999,
                    }),
                    control: provided => ({
                      ...provided,
                      borderColor: "#e7e5e4",
                      zIndex: 999,
                    }),
                  }}
                  isValidNewOption={e => {
                    if (e.length === 0) return false

                    return Number.parseInt(e) > 3
                  }}
                  placeholder="Select or start typing..."
                  onCreateOption={e => {
                    setCustomFolders(prev => [...new Set([...prev, e])])
                  }}
                  onChange={e => {
                    setCustomFolders(prev => [...new Set([...prev, e.value])])
                  }}
                />
              </div> */}
              <div className="flex gap-4">
                <div className="min-w-[11rem]">
                  <h1 className="font-medium text-stone-950">Inventory date</h1>
                  <div className="mt-2.5">
                    <DatePicker
                      dateFormat={"d MMMM yy"}
                      className="px-3 py-2.5 text-sm bg-white border rounded shadow-sm border-stone-300"
                      selected={inventoryDate}
                      maxDate={new Date()}
                      calendarClassName="bg-white border border-stone-300 rounded shadow font-sans"
                      onChange={date => setInventoryDate(date)}
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <h1 className="font-medium text-stone-950">Tenancy</h1>
                  <div className="mt-2.5">
                    <Select
                      onChange={e => {
                        setTenants(e);
                        setTenancyId(e?.id);
                      }}
                      isDisabled={property?.tenancies?.length === 1}
                      className="z-10 w-full px-3 py-3 text-sm font-medium bg-white rounded shadow-sm focus:outline-none placeholder:text-stone-400 text-stone-950"
                      options={property?.tenancies
                        ?.map(tenancy => {
                          const names = tenancy?.tenants?.map(tenant => {
                            return tenant.agreement_name;
                          });

                          return names.join(", ");
                        })
                        ?.map((item, index) => ({
                          value: item,
                          label: item,
                          id: property?.tenancies?.[index]?.id,
                        }))}
                      styles={{
                        container: provided => ({
                          ...provided,
                          padding: 0,
                          zIndex: 999,
                        }),
                        control: provided => ({
                          ...provided,
                          borderColor: "#d5d3d1",
                          zIndex: 99,
                          borderWidth: 1,
                          paddingTop: "0.125rem",
                          paddingBottom: "0.125rem",
                          paddingLeft: "0.25rem",
                          paddingRight: "0.25rem",
                        }),
                      }}
                      value={
                        tenants?.value
                          ? tenants
                          : {
                              label: property?.tenancies?.[0]?.tenants
                                ?.map(tenant => tenant.agreement_name)
                                ?.join(", ")
                                ?.replaceAll("  ", " "),
                              value: property?.tenancies?.[0]?.tenants
                                ?.map(tenant => tenant.agreement_name)
                                ?.join(", ")
                                ?.replaceAll("  ", " "),
                            }
                      }
                    />
                  </div>
                </div>
              </div>
              <h1 className="mt-6 font-medium text-stone-950">Smoke Detection Systems</h1>
              <p className="mt-1 text-xs text-stone-500">
                Every floor in the property must have a working smoke alarm.
              </p>
              <div className="mt-4 overflow-hidden bg-white border rounded shadow-sm border-stone-300">
                <table className="w-full text-sm bg-white">
                  <tr className="text-xs text-left border-b border-stone-300">
                    <th className="px-4 py-2">Floor</th>
                    <th className="px-4 py-2">Present</th>
                    <th className="px-4 py-2">Tested</th>
                    <th className="px-4 py-2">Working</th>
                    <th className="px-4 py-2">Location</th>
                    <th className="px-4 py-2">Comment</th>
                    <th className="px-4 py-2"></th>
                  </tr>
                  {floors.map((floor, index) => (
                    <>
                      {floor?.alarms?.map((alarm, _index) => {
                        const isPresent = alarm?.isPresent;
                        const isTested = alarm?.isTested;
                        const isWorking = alarm?.isWorking;
                        const comment = alarm?.comment;
                        const location = alarm?.location;

                        return (
                          <tr key={_index}>
                            <td className="px-4 py-4 align-top border-t border-stone-300">
                              <div className="flex w-fit placeholder:text-stone-400">
                                {_index === 0 && (
                                  <input
                                    value={floor?.name}
                                    className="w-fit placeholder:text-stone-400 focus:outline-none"
                                    onChange={e => {
                                      setFloors(prev => {
                                        prev[index].name = e.target.value;

                                        return [...prev];
                                      });
                                    }}
                                  />
                                )}
                                {_index === 0 && (
                                  <button
                                    onClick={() => {
                                      setFloors(prev => {
                                        const currentFloor = prev[index];
                                        currentFloor.alarms = [
                                          ...currentFloor.alarms,
                                          {
                                            location: "",
                                            isPresent: false,
                                            isTested: false,
                                            isWorking: false,
                                            comment: "",
                                          },
                                        ];

                                        return [...prev];
                                      });
                                    }}
                                    className="flex items-center justify-center w-5 h-5 text-white border rounded-full shadow bg-stone-600 border-stone-700"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="w-3.5 h-3.5"
                                    >
                                      <path d="M10.75 6.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" />
                                    </svg>
                                  </button>
                                )}
                              </div>
                            </td>
                            <td className="px-4 py-4 align-top border-t border-stone-300">
                              <input
                                className="focus:outline-none"
                                type="checkbox"
                                name="present"
                                checked={floors[index].alarms[_index].isPresent}
                                onChange={e => {
                                  setFloors(prev => {
                                    prev[index].alarms[_index].isPresent = e.target.checked;

                                    return [...prev];
                                  });

                                  if (!e.target.checked) {
                                    setFloors(prev => {
                                      prev[index].alarms[_index].isTested = false;
                                      prev[index].alarms[_index].isWorking = false;

                                      return [...prev];
                                    });
                                  }
                                }}
                              />
                            </td>
                            <td className="px-4 py-4 align-top border-t border-stone-300">
                              <input
                                className={`focus:outline-none ${!isPresent ? "cursor-not-allowed" : ""}`}
                                disabled={!isPresent}
                                type="checkbox"
                                name="tested"
                                checked={isPresent ? isTested : false}
                                onChange={e => {
                                  setFloors(prev => {
                                    prev[index].alarms[_index].isTested = !prev[index]?.alarms[_index].isTested;

                                    return [...prev];
                                  });

                                  if (!e.target.checked) {
                                    setFloors(prev => {
                                      prev[index].alarms[_index].isWorking = false;

                                      return [...prev];
                                    });
                                  }
                                }}
                              />
                            </td>
                            <td className="px-4 py-4 align-top border-t border-stone-300">
                              <input
                                className={`focus:outline-none ${!isPresent || !isTested ? "cursor-not-allowed" : ""}`}
                                disabled={!isPresent || !isTested}
                                type="checkbox"
                                name="working"
                                checked={isPresent ? isWorking : false}
                                onChange={() => {
                                  setFloors(prev => {
                                    prev[index].alarms[_index].isWorking = !prev[index]?.alarms[_index].isWorking;

                                    return [...prev];
                                  });
                                }}
                              />
                            </td>
                            <td className="px-4 py-4 align-top border-t border-stone-300 placeholder:text-stone-400">
                              <input
                                value={location}
                                onChange={e => {
                                  setFloors(prev => {
                                    prev[index].alarms[_index].location = e.target.value;

                                    return [...prev];
                                  });
                                }}
                                placeholder="Add a location..."
                                className="bg-transparent w-fit placeholder:text-stone-400 focus:outline-none"
                              />
                            </td>
                            <td className="px-4 py-4 border-t border-stone-300 placeholder:text-stone-400">
                              <input
                                value={comment}
                                onChange={e => {
                                  setFloors(prev => {
                                    prev[index].alarms[_index].comment = e.target.value;

                                    return [...prev];
                                  });
                                }}
                                placeholder="Add a comment..."
                                className="w-fit placeholder:text-stone-400 focus:outline-none"
                              />
                            </td>
                            <td className="px-4 py-4 align-top border-t border-stone-300">
                              <button
                                className="text-red-600"
                                onClick={() => {
                                  setFloors(prev => {
                                    prev[index].alarms = prev[index].alarms.filter((_, i) => i !== _index);

                                    return [...prev];
                                  });
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ))}
                </table>
                <div className="flex items-center justify-center py-4 bg-stone-100">
                  <button
                    onClick={() => {
                      setFloors(prev => {
                        let nextIndex = prev.length;

                        const ordinal = convertor.toWordsOrdinal(nextIndex);
                        const firstCharacter = ordinal.charAt(0).toUpperCase();
                        const rest = ordinal.slice(1);

                        let newFloor = nextIndex === 0 ? "Ground Floor" : `${firstCharacter}${rest} Floor`;

                        while (prev.includes(newFloor)) {
                          nextIndex += 1;

                          const ordinal = convertor.toWordsOrdinal(nextIndex);
                          const firstCharacter = ordinal.charAt(0).toUpperCase();
                          const rest = ordinal.slice(1);

                          newFloor = nextIndex === 0 ? "Ground Floor" : `${firstCharacter}${rest} Floor`;
                        }

                        return [
                          ...prev,
                          {
                            name: newFloor,
                            alarms: [
                              {
                                isPresent: false,
                                isTested: false,
                                isWorking: false,
                                comment: "",
                              },
                            ],
                          },
                        ];
                      });
                    }}
                    className="flex items-center justify-center w-8 h-8 text-white border rounded-full shadow bg-rose-500 border-rose-600"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-6 h-6">
                      <path d="M10.75 6.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" />
                    </svg>
                  </button>
                </div>
              </div>
              <h1 className="mt-6 font-medium text-stone-950">Carbon Monoxide Detection Systems</h1>
              <p className="mt-1 text-xs text-stone-500">
                Every room with a combustion appliance must have a CO detection system.
              </p>
              <div className="mt-4 overflow-hidden bg-white border rounded shadow-sm border-stone-300">
                <table className="w-full text-sm bg-white">
                  <tr className="text-xs text-left border-b border-stone-300">
                    <th className="px-4 py-2">Room</th>
                    <th className="px-4 py-2">Combustion Applicance</th>
                    <th className="px-4 py-2">Present</th>
                    <th className="px-4 py-2">Tested</th>
                    <th className="px-4 py-2">Working</th>
                    <th className="px-4 py-2">Location</th>
                    <th className="px-4 py-2">Comment</th>
                  </tr>
                  {extraRooms
                    ?.filter(folder => !["Trash", "Keys", "Meters", ""].includes(folder))
                    .map((key, index) => {
                      const property = allProperties.find(property => property.id === Number.parseInt(id));
                      const rooms = property?.rooms.filter(
                        room => !["Trash", "Keys", "Meters", ""].includes(room.name)
                      );

                      return (
                        <RoomRow
                          room={key}
                          index={index}
                          setExtraRooms={setExtraRooms}
                          customFolders={customFolders}
                          rooms={rooms}
                        />
                      );
                    })}
                </table>
                <div className="flex items-center justify-center py-4 bg-stone-100">
                  <button
                    onClick={() => {
                      setExtraRooms(prev => {
                        let i = 1;
                        const prevNames = prev.map(room => room.name);

                        let newRoom = "Room 1";
                        while (prevNames.includes(newRoom)) {
                          i += 1;

                          newRoom = `Room ${i}`;
                        }

                        return [
                          ...prev,
                          {
                            name: newRoom,
                            isCombustable: false,
                            isPresent: false,
                            isWorking: false,
                            isTested: false,
                          },
                        ];
                      });
                    }}
                    className="flex items-center justify-center w-8 h-8 text-white border rounded-full shadow bg-rose-500 border-rose-600"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-6 h-6">
                      <path d="M10.75 6.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" />
                    </svg>
                  </button>
                </div>
              </div>
              <h1 className="mt-6 font-medium text-stone-950">Fire Resistance Labels</h1>
              <p className="mt-1 text-xs text-stone-500">
                All soft furnishings supplied with a rented property must have a manufacturer’s label stating their fire
                resistance. Note: this doesn’t apply to mattresses, bed bases, pillows, cushions, or bedcovers.
              </p>
              <div className="flex flex-col gap-4 mt-4 overflow-hidden">
                <div className="flex gap-4">
                  <div className="flex flex-col flex-1 w-full overflow-hidden text-left bg-white border rounded border-stone-300">
                    <div className="w-full p-3">
                      <h1 className="font-medium text-stone-950">Are soft furnishings present?</h1>
                      <div className="flex w-full gap-3 mt-4">
                        <button
                          onClick={() => {
                            setIsFurnished(true);
                          }}
                          className={
                            isFurnished === true
                              ? "flex-1 p-3 text-sm bg-rose-500 border rounded shadow border-rose-600 text-white font-semibold"
                              : "flex-1 p-3 text-sm bg-white border rounded shadow border-stone-300"
                          }
                        >
                          Yes
                        </button>
                        <button
                          onClick={() => {
                            setIsFurnished(false);
                            setIsFurnishingFireSafe(undefined);
                          }}
                          className={
                            isFurnished === false
                              ? "flex-1 p-3 text-sm bg-rose-500 border rounded shadow border-rose-600 text-white font-semibold"
                              : "flex-1 p-3 text-sm bg-white border rounded shadow border-stone-300"
                          }
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      isFurnished
                        ? "pointer-events-auto flex-1"
                        : "pointer-events-none opacity-50 flex-1 hover:cursor-not-allowed"
                    }
                  >
                    <div className="flex flex-col flex-1 w-full overflow-hidden text-left bg-white border rounded border-stone-300">
                      <div className="w-full p-3">
                        <h1 className="font-medium text-stone-950">Are fire resistance labels present on all?</h1>

                        <div className="flex w-full gap-3 mt-4">
                          <button
                            onClick={() => {
                              setIsFurnishingFireSafe(true);
                            }}
                            className={
                              isFurnishingFireSafe === true
                                ? "flex-1 p-3 text-sm bg-rose-500 border rounded shadow border-rose-600 text-white font-semibold"
                                : "flex-1 p-3 text-sm bg-white border rounded shadow border-stone-300"
                            }
                          >
                            Yes
                          </button>
                          <button
                            onClick={() => {
                              setIsFurnishingFireSafe(false);
                            }}
                            className={
                              isFurnishingFireSafe === false
                                ? "flex-1 p-3 text-sm bg-rose-500 border rounded shadow border-rose-600 text-white font-semibold"
                                : "flex-1 p-3 text-sm bg-white border rounded shadow border-stone-300"
                            }
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h1 className="mt-6 font-medium text-stone-950">Meters</h1>
              {/* <p className="mt-1 text-xs text-stone-500">
                <span className="font-medium text-red-500">
                  Please do NOT use the prepaid setting currently, it is
                  untested. Leave it unchecked.
                </span>
              </p> */}
              <div className="mt-4 overflow-hidden">
                <div className="overflow-hidden bg-white border rounded shadow-sm border-stone-300 no-scrollbar">
                  <table className="w-full text-sm align-top bg-white">
                    <tr className="text-xs text-left border-b border-stone-300">
                      <th className="px-4 py-2">Meter</th>
                      <th className="px-4 py-2">Smart</th>
                      <th className="px-4 py-2">Prepaid</th>
                      <th className="px-4 py-2">Readings</th>
                      <th className="px-4 py-2">Location</th>
                      <th className="px-4 py-2">Comment</th>
                    </tr>
                    {meters.map((meter, index) => (
                      <MeterRow key={index} meter={meter} setMeters={setMeters} meters={meters} />
                    ))}
                  </table>
                  <div className="flex items-center justify-center w-full py-4 bg-stone-100">
                    <button
                      onClick={() => {
                        let nextName = "";

                        const meterNames = meters.map(meter => meter.name);
                        if (!meterNames.includes("Electric")) {
                          nextName = "Electric";
                        } else if (!meterNames.includes("Gas")) {
                          nextName = "Gas";
                        } else if (!meterNames.includes("Water")) {
                          nextName = "Water";
                        } else {
                          nextName = "Meter #1";
                        }

                        while (meterNames.includes(nextName)) {
                          const number = Number.parseInt(nextName.replace("Meter #", ""));

                          nextName = `Meter #${number + 1}`;
                        }

                        setMeters(prev => [
                          ...prev,
                          {
                            name: nextName,
                            isSmart: false,
                            readings: nextName === "Electric" ? ["", "", ""] : [""],
                            location: "",
                            comment: "",
                            isPrepaid: false,
                          },
                        ]);
                      }}
                      className="flex items-center justify-center w-8 h-8 text-white border rounded-full shadow bg-rose-500 border-rose-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-6 h-6"
                      >
                        <path d="M10.75 6.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <h1 className="mt-6 font-medium text-stone-950">Clerk’s Comments</h1>
              <div className="mb-16">
                <textarea
                  value={clerkComments}
                  onChange={e => setClerkComments(e.target.value)}
                  className="block w-full h-64 p-4 mt-4 text-sm bg-white border rounded shadow-sm resize-none placeholder:text-stone-400 border-stone-300"
                  placeholder={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae lorem sit amet nunc volutpat sagittis ornare ut justo. Nunc blandit lectus eu volutpat ultrices. Maecenas venenatis finibus tortor. Quisque eget pharetra massa, sit amet imperdiet nisi. Mauris magna ante, ornare nec tortor in, auctor accumsan magna. Nulla facilisi. Vivamus vehicula arcu non tortor aliquam volutpat. Nunc vitae nulla vitae libero mollis ultricies.\n\nFusce neque eros, egestas at arcu ac, bibendum mollis quam. Maecenas vitae nibh finibus orci lobortis placerat. Duis tempus sapien lectus, tempor feugiat odio consequat quis. Fusce sagittis nunc quis felis luctus, non accumsan mi luctus. Praesent pharetra imperdiet ligula, in finibus metus aliquet non. Aenean convallis felis neque, maximus elementum sapien ultrices quis. Nulla a faucibus magna. Aliquam auctor pharetra pretium.`}
                />
              </div>
            </div>
          )}
          {isPreviewing && (
            <div className="grid w-full max-w-screen-sm mx-auto mt-10 mb-32 gap-x-8 gap-y-8 lg:grid-cols-2 md:max-w-screen-md lg:max-w-screen-lg">
              <div className="flex justify-center flex-1 w-full overflow-hidden border rounded shadow border-stone-300">
                <div
                  className="bg-white flex flex-col w-full aspect-[1/1.41] relative select-none preview"
                  id={`preview-page-1`}
                >
                  <div
                    className="absolute h-6"
                    style={{
                      position: "absolute",
                      top: `${(64 / 842) * 100}%`,
                      left: `${(54 / 595) * 100}%`,
                    }}
                  >
                    <img
                      className="object-contain w-full h-full"
                      src="https://www.huntleys.net/huntleys-charcoal.svg"
                    />
                  </div>
                  <p
                    className="text-[6px] text-stone-500"
                    style={{
                      position: "absolute",
                      top: `${(64 / 842) * 100}%`,
                      right: `${(54 / 595) * 100}%`,
                    }}
                  >
                    {inventoryDate
                      ?.toLocaleDateString("en-GB", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                      .split(",")
                      .slice(1)
                      .join(" ")}
                  </p>
                  <p
                    className="text-[6px] text-stone-500"
                    style={{
                      position: "absolute",
                      top: `${(76 / 842) * 100}%`,
                      right: `${(54 / 595) * 100}%`,
                    }}
                  >
                    Tenancy #{tenancyId || tenants?.id}
                  </p>
                  <p
                    className="pt-3.5 pb-3.5 mt-2 text-lg border-t border-b text-stone-950 border-stone-300"
                    style={{
                      position: "absolute",
                      top: `${(94 / 842) * 100}%`,
                      left: `${(54 / 595) * 100}%`,
                      right: `${(54 / 595) * 100}%`,
                    }}
                  >
                    {isImplicitCheckout ? "Checkout Report" : "Inventory & Condition Report"}
                  </p>
                  {/* <div
                    className="h-px bg-stone-300"
                    style={{
                      position: "absolute",
                      top: `${(160 / 842) * 100}%`,
                      left: `${(54 / 595) * 100}%`,
                      right: `${(54 / 595) * 100}%`,
                    }}
                  /> */}
                  {/* <div
                    className="aspect-[4/3] bg-stone-300 overflow-hidden"
                    style={{
                      marginLeft: `${(54 / 595) * 100}%`,
                      marginTop: `${(232 / 842) * 100}%`,
                      marginRight: `${(54 / 595) * 100}%`,
                    }}
                  >
                    <img
                      className="object-cover w-full h-full"
                      src={
                        files.find(
                          file =>
                            file.uuid ===
                            (foldersMap?.get("Exterior")?.[0] ||
                              foldersMap?.get(
                                [...folderOptions?.values()]?.filter(
                                  item =>
                                    !["Trash", "Meters", "Keys"].includes(item)
                                )?.[0]
                              )?.[0])
                        )?.preview || files?.[0]?.preview
                      }
                    />
                  </div> */}
                  <div
                    className="flex flex-col justify-end flex-1"
                    style={{
                      marginLeft: `${(54 / 595) * 100}%`,
                      marginRight: `${(54 / 595) * 100}%`,
                      marginBottom: `${(114 / 842) * 100}%`,
                    }}
                  >
                    <div className="grid w-full grid-cols-2 gap-4">
                      <div>
                        <p className="text-[6px] text-stone-950 font-medium">Address</p>
                        {fullAddress?.map((line, index) => (
                          <p className="text-[6px] mt-[1px] text-stone-500">{line}</p>
                        ))}
                      </div>
                      <div className="flex justify-end">
                        <img className="h-[2.75rem] aspect-square" src={qrCodeImage} />
                      </div>
                    </div>
                    <div className="grid w-full grid-cols-2 gap-3 pt-3 mt-2.5 border-t border-stone-300">
                      <div>
                        <p className="text-[6px] text-stone-950 font-medium">
                          {property?.landlords?.length > 1 ? "Landlords" : "Landlord"}
                        </p>
                        {property?.landlords
                          ?.map(landlord => landlord.agreement_name)
                          ?.map((landlord, index) => (
                            <p key={index} className="text-[6px] mt-[1px] text-stone-500">
                              {landlord}
                            </p>
                          ))}
                      </div>
                      <div>
                        <div>
                          <p className="text-[6px] text-stone-950 font-medium">
                            {tenants?.split
                              ? tenants?.split(", ")?.length > 1
                                ? "Tenants"
                                : "Tenant"
                              : tenants?.value?.split(", ")?.length > 1}
                          </p>
                          {tenants?.split
                            ? tenants?.split(", ").map((tenant, index) => (
                                <p key={index} className="text-[6px] mt-[1px] text-stone-500">
                                  {tenant?.trim()}
                                </p>
                              ))
                            : tenants?.value?.split(", ").map((tenant, index) => (
                                <p key={index} className="text-[6px] mt-[1px] text-stone-500">
                                  {tenant?.trim()}
                                </p>
                              ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="text-[6px] mt-[1px] text-stone-500 absolute border-t flex justify-between border-stone-300 pt-1.5"
                    style={{
                      bottom: 0,
                      right: 0,
                      left: 0,
                      marginBottom: `${(64 / 842) * 100}%`,
                      marginRight: `${(54 / 595) * 100}%`,
                      marginLeft: `${(54 / 595) * 100}%`,
                    }}
                  >
                    <p className="text-[6px] mt-[1px] text-stone-500">Produced by Huntleys</p>
                    <p className="text-[6px] mt-[1px] text-stone-500 text-right">
                      Page 1 of {photoPages?.length > 0 ? numberOfPhotoPages + 2 : 2}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-center flex-1 w-full overflow-hidden border rounded shadow border-stone-300">
                <div className="bg-white w-full relative aspect-[1/1.41] select-none preview" id={`preview-page-2`}>
                  <div
                    className="absolute"
                    style={{
                      top: `${(64 / 842) * 100}%`,
                      left: `${(54 / 595) * 100}%`,
                      right: `${(54 / 595) * 100}%`,
                    }}
                  >
                    {/* <p className="text-xs text-stone-950">Alarms & Meters</p> */}
                    {/* <p className="text-[6px] text-stone-950 font-medium mt-2">
                      Smoke Detection Systems
                    </p>
                    <p className="text-[6px] mt-1 text-stone-500">
                      All smoke detectors pictured in this report were
                      push-tested when this report was conducted and functioned
                      as expected.
                    </p> */}
                    <h1 className="text-[10px] font-medium text-stone-950">About this report</h1>
                    {isImplicitCheckout ? (
                      <p className="mt-1 text-[6px] text-stone-500">
                        Whilst every care is taken to ensure that this report provides a fair and accurate record of the
                        contents and condition of the property, it is not guaranteed. The landlord and the tenant are
                        responsible for reviewing and agreeing on the accuracy of this report's contents, and it is
                        recommended that both parties check it. It takes an evidence-based approach and relies heavily
                        on photos instead of opinions. It does not involve any further investigation or testing of any
                        kind. The person preparing the checkout is not a qualified surveyor, and the report should not
                        be used as such. Rooms that are locked or unable to be seen, such as lofts or cellars, are the
                        landlord's responsibility. This report offers no guarantee of equipment, contents, or safety.
                        Heavy items will not be moved, nor will appliances or plumbing be tested.
                      </p>
                    ) : (
                      <p className="mt-1 text-[6px] text-stone-500">
                        Whilst every care is taken to ensure that this report provides a fair and accurate record of the
                        contents and condition of the property, it is not guaranteed. The landlord and the tenant are
                        responsible for reviewing and agreeing on the accuracy of this report's contents, and it is
                        recommended that both parties check it. It takes an evidence-based approach and relies heavily
                        on photos instead of opinions. It does not involve any further investigation or testing of any
                        kind. The person preparing the inventory is not a qualified surveyor, and the report should not
                        be used as such. Rooms that are locked or unable to be seen, such as lofts or cellars, are the
                        landlord's responsibility. This report offers no guarantee of equipment, contents, or safety.
                        Heavy items will not be moved, nor will appliances or plumbing be tested.
                      </p>
                    )}
                    {isImplicitCheckout ? (
                      <p className="mt-1 text-[6px] text-stone-500">
                        All photos were taken on the day this report is dated. High-resolution images are available by
                        clicking the individual photos or scanning the QR code on the cover page.
                      </p>
                    ) : (
                      <p className="mt-1 text-[6px] text-stone-500">
                        All photos were taken on the day this report is dated. High-resolution images are available by
                        clicking the individual photos or scanning the QR code on the cover page. At the commencement of
                        the Tenancy, the tenant may take photos of any issues they feel are not adequately covered by
                        this report and submit them to let@huntleys.net, upon which receipt will be confirmed. These
                        additions have significantly more value the earlier they are provided. Should no comments be
                        received within seven days, it will be considered that its contents have been accepted.
                      </p>
                    )}
                    {!isImplicitCheckout && floors?.filter(floor => floor?.alarms?.length >= 1)?.length >= 1 && (
                      <>
                        <p className="text-[10px] mt-4 font-medium text-stone-950">Smoke Detection Systems</p>
                        <p className="mt-1 text-[6px] text-stone-500">
                          At least one smoke alarm should be installed on every storey used as living accommodation. The
                          following floors have been identified as requiring such alarms.
                        </p>
                        <table className="text-[6px] w-full border-stone-300 mt-2">
                          <tr className="border-b border-stone-300">
                            <th className="overflow-hidden text-left text-[6px]">Floor</th>
                            <th className="overflow-hidden text-left text-[6px]">Present</th>
                            <th className="overflow-hidden text-left text-[6px]">Push-tested</th>
                            <th className="overflow-hidden text-left text-[6px]">Response</th>
                            <th className="overflow-hidden text-left text-[6px]">Location</th>
                            <th className="overflow-hidden text-left text-[6px]">Comments</th>
                          </tr>
                          {floors?.map((floor, index) => (
                            <>
                              {floor?.alarms?.map((alarm, _index) => (
                                <tr key={_index} className={`${_index === 0 ? "border-t border-stone-300" : ""} `}>
                                  <td className="overflow-hidden text-left text-[6px] text-stone-500">
                                    {_index === 0 ? floor?.name : ""}
                                  </td>
                                  <td className="px-0 py-1 text-center text-stone-500">
                                    {alarm?.isPresent ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="w-1.5 h-1.5"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="w-1.5 h-1.5"
                                      >
                                        <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                      </svg>
                                    )}
                                  </td>
                                  <td
                                    className={`px-0 py-1  min-w-[50px] max-w-[50px] w-p[50px]  text-stone-500 ${
                                      alarm?.isTested || (!alarm?.isTested && alarm.isPresent)
                                        ? "text-left"
                                        : "text-left"
                                    }`}
                                  >
                                    {alarm?.isTested ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="w-1.5 h-1.5"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    ) : !alarm?.isTested && alarm.isPresent ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="w-1.5 h-1.5"
                                      >
                                        <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                      </svg>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td className="px-0 py-1 min-w-[50px] max-w-[50px] w-p[50px] text-left text-stone-500">
                                    {alarm?.isWorking ? "PASS" : !alarm?.isWorking && alarm?.isTested ? "FAIL" : "-"}
                                  </td>
                                  <td className="overflow-hidden text-left text-[6px] text-stone-500">
                                    {alarm.location || "-"}
                                  </td>
                                  <td className="overflow-hidden text-left text-[6px] text-stone-500">
                                    {alarm?.comment || "-"}
                                  </td>
                                </tr>
                              ))}
                            </>
                          ))}
                        </table>
                      </>
                    )}
                    {!isImplicitCheckout && extraRooms?.filter(room => room?.isCombustable)?.length >= 1 ? (
                      <>
                        <p className="text-[10px] text-stone-950 font-medium mt-4">Carbon Monoxide Detection Systems</p>
                        <p className="mt-1 text-[6px] text-stone-500">
                          Carbon monoxide alarms should be installed in every room used as living accommodation
                          containing a fixed combustion appliance (excluding gas cookers). The following rooms have been
                          identified as requiring such alarms.
                        </p>
                        <table className="align-top w-full mt-2 text-[6px] border-stone-300">
                          <tr className="border-b border-stone-300">
                            <th className="overflow-hidden text-left text-[6px]">Room</th>
                            <th className="overflow-hidden text-left text-[6px]">Present</th>
                            <th className="overflow-hidden text-left text-[6px]">Push-tested</th>
                            <th className="overflow-hidde text-left text-[6px]">Reponse</th>
                            <th className="overflow-hidden text-left text-[6px]">Location</th>
                            <th className="overflow-hidden text-left text-[6px]">Comments</th>
                          </tr>
                          {extraRooms
                            ?.filter(room => room?.isCombustable)
                            ?.map((room, index) => (
                              <tr className="border-t border-stone-300" key={index}>
                                <td className="overflow-hidden text-left text-[6px] text-stone-500">
                                  {room?.name || room}
                                </td>
                                <td className="px-0 py-1 text-center text-stone-500">
                                  {room?.isPresent ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="w-1.5 h-1.5"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="w-1.5 h-1.5"
                                    >
                                      <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                    </svg>
                                  )}
                                </td>
                                <td
                                  className={`px-0  min-w-[50px] max-w-[50px] w-p[50px]  py-1 text-stone-500 ${
                                    room?.isTested || (!room?.isTested && room?.isPresent) ? "text-left" : "text-left"
                                  }`}
                                >
                                  {room?.isTested ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="w-1.5 h-1.5"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  ) : !room?.isTested && room?.isPresent ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="w-1.5 h-1.5"
                                    >
                                      <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                    </svg>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td className="px-0 py-1 min-w-[50px] max-w-[50px] w-p[50px] text-left text-stone-500">
                                  {room?.isWorking ? "PASS" : !room.isWorking && room?.isTested ? "FAIL" : "-"}
                                </td>
                                <td className="overflow-hidden text-left text-[6px] text-stone-500">
                                  {room?.location || "-"}
                                </td>
                                <td className="overflow-hidden text-left text-[6px] text-stone-500">
                                  {room?.comment || "-"}
                                </td>
                              </tr>
                            ))}
                        </table>
                      </>
                    ) : isImplicitCheckout ? null : (
                      <>
                        <p className="mt-4 text-[10px] font-medium text-stone-950">Carbon Monoxide Detection Systems</p>
                        <p className="mt-1 text-[6px] text-stone-500">
                          This property has no combustion appliances and therefore doesn't require any carbon monoxide
                          detection systems.
                        </p>
                      </>
                    )}
                    {!isImplicitCheckout && (
                      <>
                        <p className="text-[10px] text-stone-950 font-medium mt-4">Fire Resistance Labels</p>
                        <p className="mt-1 text-[6px] text-stone-500">
                          All soft furnishings supplied by the landlord must have a manufacturer’s label stating their
                          fire resistance. This doesn’t apply to mattresses, bed bases, pillows, cushions, or bedcovers.
                          On the day this report is dated there were{" "}
                          {!isFurnished
                            ? "no such soft furnishings present."
                            : isFurnished && isFurnishingFireSafe === true
                            ? "soft furnishings present, and all had fire resistance labels."
                            : "soft furnishings present and some or all were missing fire resistance labels."}
                        </p>
                      </>
                    )}
                    {meters?.length > 0 && (
                      <>
                        <p className="mt-4 text-[10px] font-medium text-stone-950">Utility Meters</p>
                        {isImplicitCheckout ? (
                          <p className="mt-1 text-[6px] text-stone-500">
                            All readings were taken on the day the checkout was produced, which is{" "}
                            {inventoryDate?.toLocaleDateString("en-GB", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                            .
                          </p>
                        ) : (
                          <p className="mt-1 text-[6px] text-stone-500">
                            All readings were taken on the day the inventory was produced, which is{" "}
                            {inventoryDate?.toLocaleDateString("en-GB", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                            .
                          </p>
                        )}
                        <table className="text-[6px] w-full border-stone-300 mt-2">
                          <tr className="border-b border-stone-300">
                            <th className="overflow-hidden text-left text-[6px]">Meter</th>
                            <th className="overflow-hidden text-left text-[6px]">Smart</th>
                            <th className="overflow-hidden text-left text-[6px]">Readings</th>
                            <th />
                            <th className="overflow-hidden text-left text-[6px]">
                              <p className="pl-2">Location</p>
                            </th>
                            <th className="overflow-hidden text-left text-[6px]">
                              <p className="pl-2">Comments</p>
                            </th>
                          </tr>
                          {meters?.map((meter, index) => {
                            return (
                              <tr className="align-top border-t border-stone-300" key={index}>
                                <td className="overflow-hidden text-stone-500">{meter?.name}</td>
                                <td className="px-0 py-1 text-center align-middle text-stone-500">
                                  {meter?.isSmart ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="w-1.5 h-1.5"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="w-1.5 h-1.5"
                                    >
                                      <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                    </svg>
                                  )}
                                </td>
                                <td className="overflow-hidden text-left text-[6px] text-stone-500">
                                  {!meter.isPrepaid &&
                                  meter?.readings?.filter(reading => reading?.trim() !== "")?.length > 0 ? (
                                    meter?.readings?.map((reading, index) => {
                                      if (reading?.trim() === "") return null;

                                      return (
                                        <p key={index}>
                                          {meter?.readings?.length > 1 && `${["A", "B", "C"]?.[index]} `}
                                          {reading}
                                        </p>
                                      );
                                    })
                                  ) : meter.isPrepaid &&
                                    meter?.readings?.filter(reading => reading?.trim() !== "")?.length > 0 ? (
                                    meter?.readings?.map((reading, index) => {
                                      if (reading?.trim() === "") return null;

                                      return (
                                        <p key={index}>
                                          {new Intl.NumberFormat("en-GB", {
                                            style: "currency",
                                            currency: "GBP",
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }).format(reading)}
                                        </p>
                                      );
                                    })
                                  ) : (
                                    <p key={index}>-</p>
                                  )}
                                </td>
                                <td />
                                <td className="overflow-hidden text-left text-[6px] text-stone-500">
                                  <p className="pl-2">{meter?.location || "-"}</p>
                                </td>
                                <td className="overflow-hidden text-left text-[6px] text-stone-500">
                                  <p className="pl-2">{meter?.comment || "-"}</p>
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                        {clerkComments.trim() !== "" && (
                          <>
                            <p className="mt-4 text-[10px] font-medium text-stone-950">Clerk's Comments</p>
                            <p className="mt-1 text-[6px] text-stone-500">{clerkComments}</p>
                          </>
                        )}
                        {/* <table className="align-top text-[6px] w-full border-stone-300 mt-2">
                          <tr className="border-b border-stone-300">
                            <th className="overflow-hidden text-left text-[6px]">
                              Meter
                            </th>
                            <th className="overflow-hidden max-w-[37.5px] text-left text-[6px]">
                              Smart
                            </th>
                            <th className="overflow-hidden w-full text-left text-[6px]">
                              Readings
                            </th>
                            <th className="overflow-hidden text-left text-[6px]">
                              Location
                            </th>
                            <th className="overflow-hidden text-left text-[6px]">
                              Comments
                            </th>
                          </tr>
                          {meters?.map((meter, index) => {
                            if (
                              !meter?.readings ||
                              meter?.readings?.length === 0
                            )
                              return null

                            return (
                              <tr
                                className="align-top border-t border-stone-300"
                                key={index}
                              >
                                <td className="overflow-hidden text-stone-500">
                                  {meter?.name}
                                </td>
                                <td className="px-0 py-1 text-center align-middle text-stone-500">
                                  {meter?.isSmart ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="w-1.5 h-1.5"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="w-1.5 h-1.5"
                                    >
                                      <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                    </svg>
                                  )}
                                </td>
                                <td className="overflow-hidden text-left text-[6px] text-stone-500">
                                  {meter?.readings?.filter(
                                    reading => reading?.trim() !== ""
                                  )?.length > 0 ? (
                                    meter?.readings?.map((reading, index) => {
                                      if (reading?.trim() === "") return null

                                      return (
                                        <p key={index}>
                                          {meter?.readings?.length > 1 &&
                                            `${["A", "B", "C"]?.[index]} `}
                                          {reading}
                                        </p>
                                      )
                                    })
                                  ) : (
                                    <p key={index}>-</p>
                                  )}
                                </td>
                                <td className="overflow-hidden text-left text-[6px] text-stone-500">
                                  {meter?.location || "-"}
                                </td>
                                <td className="overflow-hidden text-left text-[6px] text-stone-500">
                                  {meter?.comment || "-"}
                                </td>
                              </tr>
                            )
                          })}
                        </table> */}
                        {/* <p className="mt-2 text-[6px] text-stone-500">
                          You can find out who your current utility suppliers
                          are using the following services. The inventory
                          provider is not responsible for the content of
                          external websites.
                        </p>
                        <p className="mt-0.5 text-[6px] text-stone-500">
                          Gas:{" "}
                          <span className="underline text-stone-600">
                            findmysupplier.energy
                          </span>
                        </p>
                        <p className="mt-0.5 text-[6px] text-stone-500">
                          Electricity:
                          <span className="underline text-stone-600">
                            energynetworks.org/operating-the-networks/whos-my-network-operator
                          </span>
                        </p>
                        <p className="mt-0.5 text-[6px] text-stone-500">
                          Water:{" "}
                          <span className="underline text-stone-600">
                            water.org.uk/customers/find-your-supplier
                          </span>
                        </p> */}
                      </>
                    )}
                  </div>
                  <div
                    className="text-[6px] mt-[1px] text-stone-500 absolute border-t flex justify-between border-stone-300 pt-1.5"
                    style={{
                      bottom: 0,
                      right: 0,
                      left: 0,
                      marginBottom: `${(64 / 842) * 100}%`,
                      marginRight: `${(54 / 595) * 100}%`,
                      marginLeft: `${(54 / 595) * 100}%`,
                    }}
                  >
                    <p className="text-[6px] mt-[1px] text-stone-500"></p>
                    <p className="text-[6px] mt-[1px] text-stone-500 text-right">
                      Page 2 of {photoPages?.length > 0 ? numberOfPhotoPages + 2 : 2}
                    </p>
                  </div>
                </div>
              </div>
              {photoPages?.length >= 1 &&
                photoPages?.map((photoPage, index) => (
                  <div className="flex justify-center flex-1 w-full h-auto">
                    <div className="bg-white w-full border shadow border-stone-300 rounded relative overflow-hidden aspect-[1/1.41] select-none preview">
                      <div
                        className="absolute top-0 bottom-0 left-0 right-0 bg-white preview"
                        id={`preview-page-${index + 3}`}
                      >
                        <div
                          className="absolute"
                          style={{
                            top: `${(64 / 842) * 100}%`,
                            left: `${(54 / 595) * 100}%`,
                            right: `${(54 / 595) * 100}%`,
                          }}
                        >
                          {photoPages?.[index]?.map((page, index) => {
                            const photos = page?.images;
                            const folder = page?.folder;
                            const showComment = page?.showComment || false;

                            return (
                              <div key={index} className="flex flex-col w-full h-full">
                                {page?.showFolderName && (
                                  <div className={`relative flex flex-col justify-center aspect-[20/3]`}>
                                    {index !== 0 && <div className="h-1"></div>}
                                    <div className="w-full h-px bg-stone-300" />
                                    <p
                                      className={`text-[10px] text-stone-950 ${
                                        index === 0 &&
                                        foldersMap?.get("Keys")?.length === 0 &&
                                        foldersMap?.get("Meters")?.length === 0
                                          ? "mt-0"
                                          : "mt-4"
                                      }`}
                                    >
                                      {folder}
                                    </p>
                                  </div>
                                )}
                                <div className={`grid grid-cols-5 gap-1.5 ${page?.showFolderName ? "mt-2" : "mt-1.5"}`}>
                                  {photos?.map((photo, index) => (
                                    <a key={index} href={`https://static.propertyjot.com/${photo}.jpg`} target="_blank">
                                      <div
                                        className="aspect-[4/3] bg-stone-300 bg-cover bg-center w-full h-full"
                                        style={{
                                          backgroundImage: `url(${files.find(file => file.uuid === photo)?.preview})`,
                                        }}
                                      />
                                    </a>
                                  ))}
                                </div>
                                {showComment && foldersCommentMap?.has(folder) && (
                                  <div className="relative">
                                    <div className="px-0 aspect-[20/4] mt-2 text-[6px]">
                                      <p className="font-medium text-stone-950">Comments</p>
                                      <p className="mt-0.5 border-b border-stone-300 pb-2 text-stone-500">
                                        {foldersCommentMap?.get(folder) || "-"}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                        <div
                          className="text-[6px] mt-[1px] text-stone-500 absolute border-t flex justify-between border-stone-300 pt-1.5"
                          style={{
                            bottom: 0,
                            right: 0,
                            left: 0,
                            marginBottom: `${(64 / 842) * 100}%`,
                            marginRight: `${(54 / 595) * 100}%`,
                            marginLeft: `${(54 / 595) * 100}%`,
                          }}
                        >
                          <p className="text-[6px] mt-[1px] text-stone-500"></p>
                          <p className="text-[6px] mt-[1px] text-stone-500 text-right">
                            Page {index + 3} of {numberOfPhotoPages + 2}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
          {isSorting && !isPreviewing && !isForming && !isCreatedInventory && (
            <div className={`${isSorting ? "block" : "hidden pointer-events-none"}`}>
              <div className="relative max-w-screen-sm pt-4 mx-auto sm:flex sm:flex-row md:max-w-screen-md lg:max-w-screen-lg">
                <div className="flex-[1] sm:flex-[1.5] md:flex-[1] sm:mr-4 sticky top-0">
                  <Dropbox
                    customFolders={customFolders}
                    setCustomFolders={setCustomFolders}
                    photos={files}
                    setPhotos={setFiles}
                    excludedPhotos={excludedPhotos}
                    selectedUUIDs={selectedFileUUIDs}
                    setExcludedPhotos={setExcludedPhotos}
                    setSelectedUUIDs={setSelectedFileUUIDs}
                    setLastSelectedUUID={setLastSelectedUUID}
                    foldersMap={foldersMap}
                    setFoldersMap={setFoldersMap}
                    setIsBlurVisible={setIsBlurVisible}
                    setCurrentFolder={setCurrentFolder}
                    onFolderSortEnd={onFolderSortEnd}
                  />
                </div>
                <div className="flex-[2] mt-6 sm:mt-0">
                  <div className={`gap-4 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5`}>
                    {files.map((file, index) => (
                      <Thumbnail
                        photos={files}
                        key={index}
                        file={file}
                        setPhotos={setFiles}
                        selectedUUIDs={selectedFileUUIDs}
                        setSelectedUUIDs={setSelectedFileUUIDs}
                        lastSelectedUUID={lastSelectedUUID}
                        setLastSelectedUUID={setLastSelectedUUID}
                        isShiftPressed={isShiftPressed}
                        excludedPhotos={excludedPhotos}
                        setExcludedPhotos={setExcludedPhotos}
                        draggingUUID={draggingUUID}
                        setDraggingUUID={setDraggingUUID}
                      />
                    ))}
                  </div>
                  <div
                    className={`w-full flex-1 flex items-center justify-center border-2 p-6 py-12 border-dashed rounded border-stone-300 mb-14 ${
                      false ? "hover:cursor-wait" : "hover:cursor-pointer"
                    } ${files?.filter(file => !excludedPhotos.includes(file.uuid)).length === 0 ? "mt-0" : "mt-6"}`}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} disabled={isProcessing} />
                    {false ? (
                      <div className="flex flex-col items-center justify-center w-full h-full">
                        <img src={processingFilesImage} className="h-32" alt="processing" />
                        <div className="flex gap-4 mt-6">
                          <h1 className="mt-6 text-sm font-semibold text-stone-950">
                            Processing photo {processingIndex + 1} of photos {processingFilesCount}...
                          </h1>
                        </div>
                      </div>
                    ) : isDragActive ? (
                      <div className="flex flex-col items-center justify-center w-full h-full">
                        <img src={uploadFilesImage} className="h-32" alt="upload" />
                        <h1 className="mt-6 text-sm font-semibold text-stone-950">Let go to upload</h1>
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center w-full h-full">
                        <img src={dropFilesImage} className="h-32" alt="drop" />
                        <h1 className="mt-6 text-sm font-semibold text-stone-950">Drag and drop your photos here</h1>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* {isSorting &&
          Math.min((uploadingIndex / files?.length) * 100, 100) !== 100 && (
            <div className="fixed bottom-0 right-0 pb-20 pr-4">
              <div className="inline-flex items-center gap-4 p-3 border rounded-lg shadow select-none bg-stone-900 border-stone-950">
                <div className="rounded-full w-14 h-14">
                  <CircularProgressbar
                    value={
                      isProcessing
                        ? Math.min(
                            (processingIndex / processingFilesCount) * 100,
                            100
                          )
                        : Math.min((uploadingIndex / files?.length) * 100, 100)
                    }
                    text={
                      isProcessing
                        ? `${Math.min(
                            Math.round(
                              (processingIndex / processingFilesCount) * 100
                            ),
                            100
                          )}%`
                        : `${Math.min(
                            Math.round((uploadingIndex / files?.length) * 100),
                            100
                          )}%`
                    }
                    styles={buildStyles({
                      textSize: "1.25rem",
                      textColor: "#10b981",
                      trailColor: "transparent",
                      pathColor: "#10b981",
                    })}
                  />
                </div>
                <div className="max-w-xs">
                  <h1 className="text-sm font-medium text-white">
                    {isProcessing ? "Processing..." : "Uploading..."}
                  </h1>
                  <p className="mt-1 text-xs text-stone-400">
                    {isProcessing
                      ? "As long as you keep this page open, even as a background tab, your photos will continue to be processed."
                      : "As long as you keep this page open, even as a background tab, your photos will continue to upload."}
                  </p>
                </div>
              </div>
            </div>
          )} */}
      </div>
      {dragging && (
        <div
          style={{
            position: "absolute",
            border: "1px dashed #000",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            left: selectionBox.x1,
            top: selectionBox.y1,
            width: selectionBox.x2 - selectionBox.x1,
            height: selectionBox.y2 - selectionBox.y1,
          }}
        ></div>
      )}
      {isBlurVisible && (
        <div
          className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center px-4 py-32 hover:cursor-alias backdrop-blur"
          onClick={e => {
            if (e.target === e.currentTarget) {
              setIsBlurVisible(false);
            }
          }}
        >
          <div className="z-50 flex flex-col w-full h-full max-w-screen-sm bg-white border rounded shadow no-scrollbar hover:cursor-auto border-stone-300 md:max-w-screen-md lg:max-w-screen-lg">
            <div className="flex justify-between p-4 border-b border-stone-300">
              <div>
                <div className="relative py-2">
                  <h1 className="text-2xl leading-none opacity-0 pointer-events-none select-none text-stone-950">
                    {currentFolder}
                  </h1>
                  <div className="absolute top-0 bottom-0 left-0 right-0">
                    <input
                      className="w-full h-full text-2xl leading-none text-stone-950"
                      value={currentFolder}
                      onChange={e => {
                        const newFoldername = e.target.value;

                        const newNameExists = foldersMap.has(newFoldername) || customFolders.includes(newFoldername);

                        if (newNameExists) return;
                        if (newFoldername.trim().length === 0) return;

                        setFoldersMap(prev => {
                          const newMap = new Map(prev);
                          const photos = newMap.get(currentFolder);
                          newMap.set(newFoldername, photos);
                          newMap.delete(currentFolder);
                          return newMap;
                        });

                        setExtraRooms(prev => {
                          let index;

                          const keys = prev.map(obj => obj.name);
                          keys.forEach((key, i) => {
                            if (key === currentFolder) index = i;
                          });

                          let newExtraRooms = [];

                          for (let idx = 0; idx < keys.length; idx++) {
                            const obj = prev[idx];
                            if (idx === index) {
                              newExtraRooms.push({
                                ...obj,
                                name: newFoldername,
                              });
                            } else {
                              newExtraRooms.push(obj);
                            }
                          }

                          return newExtraRooms;
                        });

                        setCurrentFolder(newFoldername);
                        setCustomFolders(prev => {
                          const newFolders = [...prev];
                          const index = newFolders.indexOf(currentFolder);
                          newFolders[index] = newFoldername;
                          return [...new Set(newFolders)];
                        });
                      }}
                    />
                  </div>
                </div>
                {currentFolder === "Trash" && (
                  <p className="mt-2 text-xs text-stone-600">
                    Photos in the trash will be permanently deleted once you complete this inventory.
                  </p>
                )}
              </div>
              <div>
                <div className="flex gap-3">
                  {currentFolder === "Trash" && foldersMap?.get("Trash")?.length >= 1 ? (
                    <button
                      onClick={() => {
                        setFoldersMap(prev => {
                          const newMap = new Map(prev);
                          newMap.delete(currentFolder);
                          return newMap;
                        });

                        setIsBlurVisible(false);
                      }}
                      className="block px-2 py-1.5 text-xs font-semibold text-white border rounded shadow-sm bg-red-500 border-red-600"
                    >
                      Empty
                    </button>
                  ) : !["Trash", "Meters", "Keys"].includes(currentFolder) ? (
                    <button
                      onClick={() => {
                        const currentImagesInFolder = foldersMap.get(currentFolder);

                        setExcludedPhotos(prev => prev.filter(p => !currentImagesInFolder?.includes(p)));

                        setFoldersMap(prev => {
                          const newMap = new Map(prev);

                          newMap.set(currentFolder, []);
                          return newMap;
                        });

                        setCustomFolders(prev => prev.filter(f => f !== currentFolder));

                        setIsBlurVisible(false);
                      }}
                      className="block px-2 py-1.5 text-xs font-semibold text-white border rounded shadow-sm bg-red-500 border-red-600"
                    >
                      Delete
                    </button>
                  ) : null}
                  <button
                    onClick={() => {
                      setIsBlurVisible(false);
                    }}
                    className="block px-2 py-1.5 text-xs font-semibold text-white border rounded shadow-sm bg-stone-700 border-stone-800"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-1 overflow-y-scroll no-scrollbar">
              <SortableList
                onSortEnd={onSortEnd}
                className="z-50 grid grid-cols-2 gap-6 p-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5"
                style={{ zIndex: 9999 }}
                draggedItemClassName="cursor-grabbing"
              >
                {foldersMap.get(currentFolder)?.map((uuid, index) => {
                  const file = files.find(file => file.uuid === uuid);

                  return (
                    <SortableItem key={uuid}>
                      <div className="relative z-50">
                        <div className="overflow-hidden cursor-grab select-none aspect-[4/3] z-50 border rounded shadow-sm border-stone-300">
                          <img
                            src={file.preview}
                            className="block object-cover w-full h-full bg-stone-300"
                            alt={file.name}
                            draggable={false}
                          />
                          <button
                            className="absolute bottom-0 right-0 flex items-center justify-center w-6 h-6 -mb-2 -mr-2 text-white border rounded-full shadow bg-stone-600 border-stone-700"
                            onClick={() => {
                              setFoldersMap(prev => {
                                const newMap = new Map(prev);
                                const photos = newMap.get(currentFolder);
                                newMap.set(
                                  currentFolder,
                                  photos.filter(photo => photo !== uuid)
                                );
                                return newMap;
                              });

                              setExcludedPhotos(prev => prev.filter(p => p !== uuid));
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="w-3 h-3"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2.232 12.207a.75.75 0 011.06.025l3.958 4.146V6.375a5.375 5.375 0 0110.75 0V9.25a.75.75 0 01-1.5 0V6.375a3.875 3.875 0 00-7.75 0v10.003l3.957-4.146a.75.75 0 011.085 1.036l-5.25 5.5a.75.75 0 01-1.085 0l-5.25-5.5a.75.75 0 01.025-1.06z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </SortableItem>
                  );
                })}
              </SortableList>
            </div>
            <div className="p-4 border-t border-stone-300">
              <h1 className="text-sm font-medium text-stone-950">Comments</h1>
              <textarea
                value={foldersCommentMap.get(currentFolder) || ""}
                onChange={e => {
                  setFoldersCommentMap(prev => {
                    const newMap = new Map(prev);
                    if (e.target.value.trim().length > 0) {
                      newMap.set(currentFolder, e.target.value);
                    } else {
                      newMap.delete(currentFolder);
                    }

                    return newMap;
                  });
                }}
                className="w-full mt-1 text-xs max-h-4 placeholder:text-stone-400 text-stone-500 focus:outline-none"
                placeholder="Add a comment..."
              />
            </div>
          </div>
        </div>
      )}
      {mailReport && (
        <>
          <div
            className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center py-10 backdrop-blur hover:cursor-pointer"
            onClick={() => {
              setEmailPeople([]);
              setMailReport(undefined);
            }}
          />
          <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center py-10 pointer-events-none backdrop-blur">
            <div className="flex flex-col w-full h-full max-w-screen-md p-10 bg-white border rounded shadow-lg pointer-events-auto border-stone-300">
              <div className="relative h-full max-w-screen-sm pt-4 mx-auto md:max-w-screen-md lg:max-w-screen-lg">
                <h1 className="font-medium text-stone-950">Who would you like to email access to this inventory?</h1>
                <p className="mt-1 text-xs text-stone-500">
                  Anyone with access will be tracked and the inventory report will be watermarked with their name, email
                  and other details when they view it.
                </p>
                <h1 className="mt-6 text-sm font-medium text-stone-950">Tenants</h1>
                <div className="mt-3 grid grid-cols-2 gap-2.5">
                  {reportPeople?.tenants?.map((tenant, index2) => (
                    <>
                      {tenant?.email ? (
                        <div className={`flex gap-3`} key={index2}>
                          <div>
                            <input
                              className={`focus-visible:outline-none`}
                              type="checkbox"
                              name="tested"
                              onChange={e => {
                                if (e?.target?.checked) {
                                  setEmailPeople(emailPeople => [...emailPeople, tenant]);
                                } else {
                                  setEmailPeople(emailPeople =>
                                    emailPeople.filter(_tenant => _tenant?.email !== tenant?.email)
                                  );
                                }
                              }}
                            />
                          </div>
                          <div>
                            <p className="text-sm">{tenant?.agreement_name}</p>
                            <p className="text-xs mt-0.5 text-stone-600">{tenant?.email}</p>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ))}
                </div>
                <h1 className="mt-6 text-sm font-medium text-stone-950">Landlords</h1>
                <div className="mt-3 grid grid-cols-4 gap-2.5">
                  {reportPeople?.landlords
                    ?.filter(landlord => landlord?.email)
                    ?.map(landlord => (
                      <div className={`flex gap-3`}>
                        <div>
                          <input
                            className={`focus-visible:outline-none`}
                            type="checkbox"
                            name="tested"
                            onChange={e => {
                              if (e?.target?.checked) {
                                setEmailPeople(emailPeople => [...emailPeople, landlord]);
                              } else {
                                setEmailPeople(emailPeople =>
                                  emailPeople.filter(_landlord => _landlord?.email !== landlord?.email)
                                );
                              }
                            }}
                          />
                        </div>
                        <div>
                          <p className="text-sm">{landlord?.agreement_name}</p>
                          <p className="text-xs mt-0.5 text-stone-600">{landlord?.email}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="pt-4 border-t border-stone-300">
                <button
                  disabled={emailPeople?.length === 0}
                  className={
                    emailPeople?.length === 0
                      ? `block px-2 py-1.5 text-xs font-semibold text-stone-500 border rounded shadow-sm bg-stone-300 border-stone-400 hover:cursor-not-allowed`
                      : "block px-2 py-1.5 text-xs font-semibold text-white border rounded shadow-sm bg-rose-500 border-rose-600"
                  }
                  onClick={async () => {
                    try {
                      const response = await axios.post(
                        `https://api.propertyjot.com/deliver/${mailReport?.uuid}`,
                        {
                          emails: emailPeople,
                        },
                        {
                          headers: {
                            Authorization: localStorage.getItem("token"),
                          },
                        }
                      );
                      if (response?.status === 200) {
                        setEmailPeople([]);
                        setMailReport(undefined);
                      }
                    } catch (err) {
                      console.error(err);
                    }

                    setEmailPeople([]);
                    setMailReport(undefined);
                  }}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {isVerifying && (
        <>
          <div className="fixed top-0 bottom-0 left-0 right-0 z-50 bg-opacity-25 bg-stone-950 backdrop-blur"></div>
          <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center p-6">
            <div className="w-full max-w-screen-sm p-6 bg-white rounded-sm shadow">
              <span className="inline-block px-2.5 py-1 shadow text-xs font-bold text-white bg-red-500 border rounded-full border-rose-600">
                Don't close this tab
              </span>
              <PacmanLoader color="#134e4a" className="mt-6" />
              <h1 className="mt-4 text-2xl font-medium text-stone-950">
                {isVerifyingImages
                  ? "Checking images..."
                  : isReuploadingImage
                  ? "Reuploading missing image..."
                  : "Verifying..."}
              </h1>
              <p className="mt-2 text-sm text-stone-500">
                Just checking to make sure everything is in order. This includes checks to make sure{" "}
                <b>all photos have uploaded successfully</b> and that <b>all required information has been provided</b>.
              </p>
            </div>
          </div>
        </>
      )}
    </DndProvider>
  );
}

function DropItem({
  folder,
  selectedUUIDs,
  setSelectedUUIDs,
  setLastSelectedUUID,
  setExcludedPhotos,
  foldersMap,
  setFoldersMap,
  setIsBlurVisible,
  setCurrentFolder,
  setCustomFolders,
}) {
  const [{ isOver }, ref] = useDrop(
    () => ({
      accept: "PHOTO",
      drop: item => {
        setExcludedPhotos(prev => [...new Set([...prev, item.uuid, ...selectedUUIDs])]);

        setFoldersMap(prev => {
          const newMap = new Map(prev);
          const photos = newMap.get(folder) || [];

          newMap.set(folder, [...new Set([...photos, item.uuid, ...selectedUUIDs])]);

          return newMap;
        });

        setSelectedUUIDs([]);
        setLastSelectedUUID(null);
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        dropResult: monitor.getDropResult(),
      }),
    }),
    [selectedUUIDs]
  );

  const [isActive, setIsActive] = useState(false);

  return (
    <div className="flex gap-2">
      <button
        onClick={() => {
          setIsBlurVisible(true);
          setIsActive(false);
          setCurrentFolder(folder);
        }}
        className={
          foldersMap?.get(folder)?.length >= 1
            ? `flex flex-1 items-center gap-2 p-3 rounded border-green-300 border-dashed border-2 ${
                isOver || isActive
                  ? "bg-green-100 text-stone-950"
                  : "hover:bg-green-100 focus:bg-green-100 active:bg-green-100"
              }`
            : `flex flex-1 items-center gap-2 p-3 rounded border-stone-300 border-dashed border-2 ${
                isOver || isActive
                  ? "bg-stone-100 text-stone-950"
                  : "hover:bg-stone-100 focus:bg-stone-100 active:bg-stone-100"
              }`
        }
        ref={ref}
      >
        <div className="relative">
          <h1 className="text-4xl">📁</h1>
        </div>
        <div className={`text-xs font-medium ${isOver ? "text-stone-950" : "text-stone-500"} `}>
          <div className="relative left-0">
            <h1 className="block text-left">{folder}</h1>
            <p className="block mt-0.5 text-left text-[0.625rem]">
              {!foldersMap?.has(folder) || foldersMap?.get(folder)?.length === 0
                ? "No"
                : `${foldersMap?.get(folder)?.length}`}{" "}
              {foldersMap?.get(folder)?.length === 1 ? "photo" : "photos"}
            </p>
          </div>
        </div>
      </button>
      <div className="flex w-4 grid-cols-2 h-18 bg-stone-200 rounded-xs"></div>
    </div>
  );
}

function KeysDrop({
  selectedUUIDs,
  setSelectedUUIDs,
  setLastSelectedUUID,
  setExcludedPhotos,
  foldersMap,
  setFoldersMap,
  setCurrentFolder,
  setIsBlurVisible,
}) {
  const [{ isOver }, ref] = useDrop(
    () => ({
      accept: "PHOTO",
      drop: item => {
        setExcludedPhotos(prev => [...new Set([...prev, item.uuid, ...selectedUUIDs])]);

        setFoldersMap(prev => {
          const newMap = new Map(prev);
          const photos = newMap.get("Keys") || [];

          newMap.set("Keys", [...new Set([...photos, item.uuid, ...selectedUUIDs])]);

          return newMap;
        });

        setSelectedUUIDs([]);
        setLastSelectedUUID(null);
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        dropResult: monitor.getDropResult(),
      }),
    }),
    [selectedUUIDs]
  );

  const folder = "Keys";

  const [isActive, setIsActive] = useState(false);

  return (
    <button
      onClick={() => {
        setIsBlurVisible(true);
        setIsActive(false);
        setCurrentFolder(folder);
      }}
      className={
        foldersMap?.get(folder)?.length >= 1
          ? `flex items-center gap-2 p-3 rounded border-green-300 border-dashed border-2 ${
              isOver || isActive
                ? "bg-green-100 text-stone-950"
                : "hover:bg-green-100 focus:bg-green-100 active:bg-green-100"
            }`
          : `flex items-center gap-2 p-3 rounded border-amber-300 border-dashed border-2 ${
              isOver || isActive
                ? "bg-amber-100 text-amber-950"
                : "hover:bg-amber-100 focus:bg-amber-100 active:bg-amber-100"
            }`
      }
      ref={ref}
    >
      <div className="relative">
        <h1 className="text-4xl">🔑</h1>
      </div>
      <div className={`text-xs font-medium ${isOver ? "text-stone-950" : "text-stone-500"} `}>
        <div className="relative left-0">
          <h1 className="block text-left">{folder}</h1>
          <p className="block mt-0.5 text-left text-[0.625rem]">
            {!foldersMap?.has(folder) || foldersMap?.get(folder)?.length === 0
              ? "Empty"
              : `${foldersMap?.get(folder)?.length} ${foldersMap?.get(folder)?.length === 1 ? "photo" : "photos"}`}
          </p>
        </div>
      </div>
    </button>
  );
}

function MetersDrop({
  selectedUUIDs,
  setSelectedUUIDs,
  setLastSelectedUUID,
  setExcludedPhotos,
  foldersMap,
  setFoldersMap,
  setCurrentFolder,
  setIsBlurVisible,
}) {
  const [{ isOver }, ref] = useDrop(
    () => ({
      accept: "PHOTO",
      drop: item => {
        setExcludedPhotos(prev => [...new Set([...prev, item.uuid, ...selectedUUIDs])]);

        setFoldersMap(prev => {
          const newMap = new Map(prev);
          const photos = newMap.get("Meters") || [];

          newMap.set("Meters", [...new Set([...photos, item.uuid, ...selectedUUIDs])]);

          return newMap;
        });

        setSelectedUUIDs([]);
        setLastSelectedUUID(null);
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        dropResult: monitor.getDropResult(),
      }),
    }),
    [selectedUUIDs]
  );

  const folder = "Meters";

  const [isActive, setIsActive] = useState(false);

  return (
    <button
      onClick={() => {
        setIsBlurVisible(true);
        setIsActive(false);
        setCurrentFolder(folder);
      }}
      className={
        foldersMap?.get(folder)?.length >= 1
          ? `flex items-center gap-2 p-3 rounded border-green-300 border-dashed border-2 ${
              isOver || isActive
                ? "bg-green-100 text-stone-950"
                : "hover:bg-green-100 focus:bg-green-100 active:bg-green-100"
            }`
          : `flex items-center gap-2 p-3 rounded border-amber-300 border-dashed border-2 ${
              isOver || isActive
                ? "bg-amber-100 text-amber-950"
                : "hover:bg-amber-100 focus:bg-amber-100 active:bg-amber-100"
            }`
      }
      ref={ref}
    >
      <div className="relative">
        <h1 className="text-4xl">📏</h1>
      </div>
      <div className={`text-xs font-medium ${isOver ? "text-stone-950" : "text-stone-500"} `}>
        <div className="relative left-0">
          <h1 className="block text-left">{folder}</h1>
          <p className="block mt-0.5 text-left text-[0.625rem]">
            {!foldersMap?.has(folder) || foldersMap?.get(folder)?.length === 0
              ? "Empty"
              : `${foldersMap?.get(folder)?.length} ${foldersMap?.get(folder)?.length === 1 ? "photo" : "photos"}`}
          </p>
        </div>
      </div>
    </button>
  );
}

function TrashDrop({
  selectedUUIDs,
  setSelectedUUIDs,
  setLastSelectedUUID,
  setExcludedPhotos,
  foldersMap,
  setFoldersMap,
  setCurrentFolder,
  setIsBlurVisible,
}) {
  const [{ isOver }, ref] = useDrop(
    () => ({
      accept: "PHOTO",
      drop: item => {
        setExcludedPhotos(prev => [...new Set([...prev, item.uuid, ...selectedUUIDs])]);

        setFoldersMap(prev => {
          const newMap = new Map(prev);
          const photos = newMap.get("Trash") || [];

          newMap.set("Trash", [...new Set([...photos, item.uuid, ...selectedUUIDs])]);

          return newMap;
        });

        setSelectedUUIDs([]);
        setLastSelectedUUID(null);
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        dropResult: monitor.getDropResult(),
      }),
    }),
    [selectedUUIDs]
  );

  const folder = "Trash";

  const [isActive, setIsActive] = useState(false);

  return (
    <button
      onClick={() => {
        setIsBlurVisible(true);
        setIsActive(false);
        setCurrentFolder(folder);
      }}
      className={`flex items-center gap-2 p-3 rounded border-red-400 border-dashed border-2 ${
        isOver || isActive ? "bg-stone-100 text-stone-950" : "hover:bg-red-100 focus:bg-red-100 active:bg-red-100"
      }`}
      ref={ref}
    >
      <div className="relative">
        <h1 className="text-4xl">🗑️</h1>
      </div>
      <div className={`text-xs font-medium ${isOver ? "text-stone-950" : "text-stone-500"} `}>
        <div className="relative left-0">
          <h1 className="block text-left">{folder}</h1>
          <p className="block mt-0.5 text-left text-[0.625rem]">
            {!foldersMap?.has(folder) || foldersMap?.get(folder)?.length === 0
              ? "Empty"
              : `${foldersMap?.get(folder)?.length} ${foldersMap?.get(folder)?.length === 1 ? "photo" : "photos"}`}
          </p>
        </div>
      </div>
    </button>
  );
}

function CheckoutImageDrop({
  checkoutIssuePics,
  setCheckoutIssuePics,
  checkoutIssueExcludedPics,
  setCheckoutIssueExcludedPics,
  folder,
  index,
  setSelectedCheckoutUUIDs,
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop: acceptedFiles => {
      setCheckoutIssuePics(prev => {
        const fl = acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            uuid: uuidv4(),
          })
        );

        setSelectedCheckoutUUIDs(prev => [...new Set([...prev, ...fl.map(file => `${file.uuid}-${folder}-${index}`)])]);

        const newMap = new Map(prev);
        newMap.set(`${folder}-${index}`, [...(newMap.get(`${folder}-${index}`) || []), ...fl]);

        return newMap;
      });
    },
  });

  return (
    <div
      className={`w-full flex-1 flex items-center justify-center border-2 p-4 border-dashed rounded border-rose-300 ${
        false ? "hover:cursor-wait" : "hover:cursor-pointer"
      } ${
        checkoutIssuePics
          ?.get(`${folder}-${index}`)
          ?.filter(file => !checkoutIssueExcludedPics?.get(`${folder}-${index}`)?.includes(file.uuid)).length === 0
          ? "mt-0"
          : "mt-6"
      }`}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {false ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <img src={processingFilesImage} className="h-10" alt="processing" />
          {/* <div className="flex gap-4">
            <h1 className="mt-2 text-sm font-semibold text-stone-950">
              Processing photo {processingIndex + 1} of photos{" "}
              {processingFilesCount}...
            </h1>
          </div> */}
        </div>
      ) : isDragActive ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <img src={uploadFilesImage} className="h-10" alt="upload" />
          <h1 className="mt-2 text-sm font-semibold text-stone-950">Let go to upload</h1>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-full pointer-events-none">
          <img src={dropFilesImage} className="h-10" alt="drop" />
          <h1 className="mt-2 text-sm font-semibold text-stone-950">Drag and drop your checkout photos here</h1>
        </div>
      )}
    </div>
  );
}

function CheckoutFileDrop({ folder, index, setCheckoutIssueDocuments }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "application/pdf",
    onDrop: acceptedFiles => {
      const file = acceptedFiles[0];

      setCheckoutIssueDocuments(prev => {
        const newMap = new Map(prev);
        const updatedFile = Object.assign(file, {
          uuid: uuidv4(),
          file: file,
        });

        newMap.set(`${folder}-${index}`, [...(newMap.get(`${folder}-${index}`) || []), updatedFile]);
        return newMap;
      });
    },
  });

  return (
    <div
      className={`w-full flex-1 flex items-center justify-center border-2 p-4 py-12 border-dashed rounded border-rose-300 ${
        false ? "hover:cursor-wait" : "hover:cursor-pointer"
      }`}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {false ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <img src={processingFilesImage} className="h-10" alt="processing" />
          {/* <div className="flex gap-4">
            <h1 className="mt-2 text-sm font-semibold text-stone-950">
              Processing photo {processingIndex + 1} of photos{" "}
              {processingFilesCount}...
            </h1>
          </div> */}
        </div>
      ) : isDragActive ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <img src={uploadFilesImage} className="h-10" alt="upload" />
          <h1 className="mt-2 text-sm font-semibold text-stone-950">Let go to upload</h1>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-full pointer-events-none">
          <img src={dropFilesImage} className="h-10" alt="drop" />
          <h1 className="mt-2 text-sm font-semibold text-stone-950">Drag and drop your checkout documents here</h1>
        </div>
      )}
    </div>
  );
}

function Dropbox({
  customFolders,
  setCustomFolders,
  onFolderSortEnd,
  excludedPhotos,
  setExcludedPhotos,
  selectedUUIDs,
  setSelectedUUIDs,
  setLastSelectedUUID,
  foldersMap,
  setFoldersMap,
  setIsBlurVisible,
  setCurrentFolder,
}) {
  return (
    <>
      <div className="flex-[1] bg-white sticky top-10">
        <div className="flex flex-col h-full max-h-[60.5vh] border rounded shadow-sm select-none border-stone-300">
          <div className="p-3">
            <h1 className="font-medium text-stone-950">Create a folder</h1>
            <p className="mt-1 text-xs text-stone-500">
              A folder is a group of photos, usually named after a room or area of the property.
            </p>
            <p className="mt-3 text-xs font-semibold text-stone-950">Folder name</p>
            <div className="flex mt-1.5 gap-3 items-center">
              <Creatable
                options={[...new Set([...folderOptions])]
                  ?.sort((a, b) => a.value.localeCompare(b.value))
                  ?.filter(item => !customFolders?.includes(item.value))}
                className="w-full px-3 py-2 text-sm font-medium bg-white border rounded shadow-sm border-stone-300 focus:outline-none placeholder:text-stone-400 text-stone-950"
                styles={{
                  container: provided => ({
                    ...provided,
                    padding: 0,
                    border: 0,
                    zIndex: 999,
                  }),
                  control: provided => ({
                    ...provided,
                    borderColor: "#e7e5e4",
                    zIndex: 999,
                  }),
                }}
                isValidNewOption={e => {
                  return !customFolders?.includes(e) && e?.trim()?.length > 0 && e !== "Trash";
                }}
                placeholder="Select or start typing..."
                onCreateOption={e => {
                  if (customFolders?.includes(e)) {
                    return;
                  }

                  setCustomFolders(prev => [...new Set([e, ...prev])]);
                }}
                onChange={e => {
                  setCustomFolders(prev => [...new Set([e.value, ...prev])]);
                }}
              />
            </div>
          </div>
          <div className="w-full h-px bg-stone-300"></div>
          <SortableList
            onSortEnd={onFolderSortEnd}
            className="flex flex-col gap-3 p-3 overflow-y-scroll no-scrollbar"
            draggedItemClassName="cursor-grabbing"
          >
            {customFolders?.map(folder => (
              <SortableItem key={folder}>
                <div className="bg-white">
                  <DropItem
                    key={folder}
                    folder={folder}
                    selectedUUIDs={selectedUUIDs}
                    setLastSelectedUUID={setLastSelectedUUID}
                    setExcludedPhotos={setExcludedPhotos}
                    setSelectedUUIDs={setSelectedUUIDs}
                    foldersMap={foldersMap}
                    setFoldersMap={setFoldersMap}
                    setIsBlurVisible={setIsBlurVisible}
                    setCurrentFolder={setCurrentFolder}
                    setCustomFolders={setCustomFolders}
                    customFolders={customFolders}
                  />
                </div>
              </SortableItem>
            ))}
            <KeysDrop
              selectedUUIDs={selectedUUIDs}
              setLastSelectedUUID={setLastSelectedUUID}
              setExcludedPhotos={setExcludedPhotos}
              setSelectedUUIDs={setSelectedUUIDs}
              foldersMap={foldersMap}
              setFoldersMap={setFoldersMap}
              setCurrentFolder={setCurrentFolder}
              setIsBlurVisible={setIsBlurVisible}
            />
            <MetersDrop
              selectedUUIDs={selectedUUIDs}
              setLastSelectedUUID={setLastSelectedUUID}
              setExcludedPhotos={setExcludedPhotos}
              setSelectedUUIDs={setSelectedUUIDs}
              foldersMap={foldersMap}
              setFoldersMap={setFoldersMap}
              setCurrentFolder={setCurrentFolder}
              setIsBlurVisible={setIsBlurVisible}
            />
            <TrashDrop
              selectedUUIDs={selectedUUIDs}
              setLastSelectedUUID={setLastSelectedUUID}
              setExcludedPhotos={setExcludedPhotos}
              setSelectedUUIDs={setSelectedUUIDs}
              foldersMap={foldersMap}
              setFoldersMap={setFoldersMap}
              setCurrentFolder={setCurrentFolder}
              setIsBlurVisible={setIsBlurVisible}
            />
          </SortableList>
        </div>
      </div>
    </>
  );
}

function Thumbnail({
  file,
  photos,
  selectedUUIDs,
  setSelectedUUIDs,
  setLastSelectedUUID,
  lastSelectedUUID,
  isShiftPressed,
  excludedPhotos,
  draggingUUID,
  setDraggingUUID,
}) {
  const [{ isDragging, didDrop }, drag, preview] = useDrag(() => ({
    type: "PHOTO",
    item: {
      uuid: file.uuid,
    },
    collect: monitor => ({
      didDrop: monitor.didDrop(),
      isDragging: monitor.isDragging(),
    }),
  }));

  const [isButtonHovering, setIsButtonHovering] = useState(false);

  // useEffect(() => {
  //   preview(getEmptyImage(), {
  //     captureDraggingState: true,
  //     anchorX: 0,
  //     anchorY: 0,
  //   })
  // }, [])

  const isSelected = selectedUUIDs?.includes(file.uuid);

  useEffect(() => {
    if (isDragging) {
      setDraggingUUID(file?.uuid);
    } else if (!isDragging && draggingUUID === file?.uuid) {
      setDraggingUUID(null);
    }
  }, [isDragging, draggingUUID]);

  if (excludedPhotos.includes(file.uuid)) {
    return (
      <div className="relative hidden" draggable={false}>
        <div ref={node => drag(node)} />
      </div>
    );
  }

  if ((isDragging || (draggingUUID && selectedUUIDs.includes(file.uuid))) && !didDrop) {
    return (
      <div className="relative">
        <div className="aspect-[4/3] rounded bg-stone-200" ref={node => drag(node)} />
      </div>
    );
  }

  return (
    <>
      <DragPreviewImage connect={preview} src={file.dragPreview} />
      <div
        className="relative z-0"
        onClick={e => {
          if (e.target === e.currentTarget || e.target.tagName === "IMG") {
            if (isSelected) {
              setSelectedUUIDs(prev => prev.filter(uuid => uuid !== file.uuid));

              return;
            }

            setLastSelectedUUID(file.uuid);

            if (lastSelectedUUID && isShiftPressed) {
              const lastSelectedIndex = photos.findIndex(photo => photo.uuid === lastSelectedUUID);

              let newUUIDs = [...selectedUUIDs];

              const curentIndex = photos.findIndex(photo => photo.uuid === file.uuid);

              if (curentIndex > lastSelectedIndex) {
                for (let i = lastSelectedIndex; i <= curentIndex; i++) {
                  newUUIDs.push(photos[i].uuid);
                }
              } else if (curentIndex < lastSelectedIndex) {
                for (let i = lastSelectedIndex; i >= curentIndex; i--) {
                  newUUIDs.push(photos[i].uuid);
                }
              }

              setSelectedUUIDs(prev => [...new Set([...prev, ...newUUIDs])]);
            } else {
              setSelectedUUIDs(prev => [...new Set([...prev, file.uuid])]);
            }
          }
        }}
      >
        <div
          className={[
            "aspect-[4/3] rounded overflow-hidden hover:cursor-grab",
            selectedUUIDs?.length > 0 &&
              !isSelected &&
              !isButtonHovering &&
              "bg-transparent hover:opacity-100 transition-all",
          ]?.join(" ")}
          ref={node => drag(node)}
        >
          <img src={file.preview} className="block object-cover w-full h-full bg-stone-300" alt={file.name} />
        </div>
        {!isSelected ? (
          <button
            className="absolute w-5 h-5 bg-white border rounded-full shadow-xl focus:outline-none -top-1 -right-1 border-stone-300 hover:bg-stone-100"
            onMouseOver={() => setIsButtonHovering(true)}
            onMouseLeave={() => setIsButtonHovering(false)}
            onClick={() => {
              setLastSelectedUUID(file.uuid);

              if (lastSelectedUUID && isShiftPressed) {
                const lastSelectedIndex = photos.findIndex(photo => photo.uuid === lastSelectedUUID);

                let newUUIDs = [...selectedUUIDs];

                const curentIndex = photos.findIndex(photo => photo.uuid === file.uuid);

                if (curentIndex > lastSelectedIndex) {
                  for (let i = lastSelectedIndex; i <= curentIndex; i++) {
                    newUUIDs.push(photos[i].uuid);
                  }
                } else if (curentIndex < lastSelectedIndex) {
                  for (let i = lastSelectedIndex; i >= curentIndex; i--) {
                    newUUIDs.push(photos[i].uuid);
                  }
                }

                setSelectedUUIDs(prev => [...new Set([...prev, ...newUUIDs])]);
              } else {
                setSelectedUUIDs(prev => [...new Set([...prev, file.uuid])]);
              }
            }}
          />
        ) : (
          <button
            className="absolute flex items-center justify-center w-5 h-5 bg-white border rounded-full shadow-xl focus:outline-none -top-1 -right-1 border-stone-300 hover:bg-stone-100"
            onMouseOver={() => setIsButtonHovering(true)}
            onMouseLeave={() => setIsButtonHovering(false)}
            onClick={() => {
              setSelectedUUIDs(prev => prev.filter(uuid => uuid !== file.uuid));
            }}
          >
            <div className="w-3.5 h-3.5 rounded-full bg-rose-600"></div>
          </button>
        )}
      </div>
    </>
  );
}

function MeterRowCheckout({ meter, setMeters, checkoutMeters, setCheckoutMeters }) {
  const [isPresent, setIsPresent] = useState(false);

  const metername = meter?.name;
  const location = meter?.location;
  const comment = meter?.comment;
  const isSmart = meter?.isSmart;
  const isCheckoutSmart = checkoutMeters?.find(checkoutMeter => checkoutMeter.name === metername)?.isSmart || false;

  return (
    <tr>
      <td className="px-4 py-4 align-top border-t border-stone-300">
        <div className="w-fit placeholder:text-stone-400">
          <input
            disabled
            value={metername}
            className="bg-transparent w-fit placeholder:text-stone-400 focus:outline-none"
            onChange={e => {
              setMeters(prev => {
                const newMeters = [...prev];
                const meter = newMeters.find(meter => meter.name === metername);
                meter.name = e.target.value;
                return newMeters;
              });
            }}
          />
        </div>
      </td>

      <td className="px-4 py-4 align-top border-t border-stone-300">
        <div>
          {/* <input
            disabled
            className={`focus-visible:outline-none`}
            type="checkbox"
            name="tested"
            checked={isSmart}
            onChange={e => {
              setMeters(prev => {
                const newMeters = [...prev];
                const meter = newMeters.find(meter => meter.name === metername);
                if (meter) {
                  meter.isSmart = e.target.checked;
                }

                return newMeters;
              });
            }}
          /> */}
          <p className="font-medium text-amber-800">{isSmart ? "Yes" : "No"}</p>
        </div>
      </td>

      <td className="px-4 py-4 align-top border-t bg-amber-100 border-stone-300">
        <div className="w-fit">
          {meter?.readings?.map((reading, index) => (
            <p className="font-medium text-amber-800" key={index}>
              {reading}
            </p>
          ))}
        </div>
      </td>
      <td className="px-4 py-4 align-top border-t border-stone-300 placeholder:text-stone-400 bg-amber-100">
        {comment ? (
          <p className="font-medium text-amber-800">{comment}</p>
        ) : (
          <p className="font-medium text-amber-800">-</p>
        )}
      </td>

      <td className="px-4 py-4 align-top border-t border-stone-300">
        <div>
          <input
            className={`focus-visible:outline-none`}
            type="checkbox"
            name="tested"
            checked={isCheckoutSmart}
            onChange={e => {
              setCheckoutMeters(prev => {
                const newMeters = [...prev];
                const meter = newMeters.find(meter => meter.name === metername);
                if (meter) {
                  meter.isSmart = e.target.checked;
                }

                return newMeters;
              });
            }}
          />
        </div>
      </td>

      <td className="px-4 py-4 align-top border-t border-stone-300">
        <div className="flex flex-col gap-1">
          <input
            value={""}
            placeholder="A 123456"
            className="w-fit placeholder:text-stone-400 focus:outline-none"
            onChange={e => {
              setCheckoutMeters(prev => {
                const newMeters = [...prev];
                const meter = newMeters.find(meter => meter.name === metername);

                let newReadings = meter.readings;
                newReadings[0] = e.target.value;

                meter.readings = newReadings.filter(reading => reading !== ",");
                return newMeters;
              });
            }}
          />
          <input
            value={""}
            placeholder="B 123456"
            className="w-fit placeholder:text-stone-400 focus:outline-none"
            onChange={e => {
              setCheckoutMeters(prev => {
                const newMeters = [...prev];
                const meter = newMeters.find(meter => meter.name === metername);

                let newReadings = meter.readings;
                newReadings[1] = e.target.value;

                meter.readings = newReadings;
                return newMeters;
              });
            }}
          />
          <input
            value={""}
            placeholder="C 123456"
            className="w-fit placeholder:text-stone-400 focus:outline-none"
            onChange={e => {
              setCheckoutMeters(prev => {
                const newMeters = [...prev];
                const meter = newMeters.find(meter => meter.name === metername);

                let newReadings = meter.readings;
                newReadings[2] = e.target.value;

                meter.readings = newReadings;
                return newMeters;
              });
            }}
          />
        </div>
      </td>

      <td className="px-4 py-4 align-top border-t border-stone-300">
        <div className="flex flex-col gap-1">
          <input
            value={""}
            placeholder="Add a comment..."
            className="w-fit placeholder:text-stone-400 focus:outline-none"
            onChange={e => {
              setMeters(prev => {
                const newMeters = [...prev];
                const meter = newMeters.find(meter => meter.name === metername);

                let newReadings = meter.readings;
                newReadings[0] = e.target.value;

                meter.readings = newReadings.filter(reading => reading !== ",");
                return newMeters;
              });
            }}
          />
        </div>
      </td>

      <td className="px-4 py-4 border-t border-stone-300">
        <button
          className="text-red-600"
          onClick={() => {
            setMeters(prev => prev.filter(meter => meter.name !== metername));
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
            <path
              fillRule="evenodd"
              d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </td>
    </tr>
  );
}

function MeterRow({ meter, setMeters, meters }) {
  const [isPresent, setIsPresent] = useState(false);

  const metername = meter?.name;
  const location = meter?.location;
  const comment = meter?.comment;
  const isSmart = meter?.isSmart;
  const isPrepaid = meter?.isPrepaid;

  return (
    <tr>
      <td className="px-4 py-4 align-top border-t border-stone-300">
        <div className="w-fit placeholder:text-stone-400">
          <input
            value={metername}
            className="w-fit placeholder:text-stone-400 focus:outline-none"
            onChange={e => {
              setMeters(prev => {
                const newMeters = [...prev];
                const meter = newMeters.find(meter => meter.name === metername);
                meter.name = e.target.value;
                return newMeters;
              });
            }}
          />
        </div>
      </td>
      <td className="px-4 py-4 align-top border-t border-stone-300">
        <div>
          <input
            className={`focus-visible:outline-none`}
            type="checkbox"
            name="is_smart"
            checked={isSmart}
            onChange={e => {
              setMeters(prev => {
                const newMeters = [...prev];
                const meter = newMeters.find(meter => meter.name === metername);
                meter.isSmart = e.target.checked;
                return newMeters;
              });
            }}
          />
        </div>
      </td>
      <td className="px-4 py-4 align-top border-t border-stone-300">
        <div>
          <input
            className={`focus-visible:outline-none`}
            type="checkbox"
            name="prepaid"
            checked={isPrepaid}
            onChange={e => {
              setMeters(prev => {
                const newMeters = [...prev];
                const meter = newMeters.find(meter => meter.name === metername);
                let newReadings = [];

                for (const _ of meter.readings) {
                  newReadings.push("");
                }

                meter.readings = newReadings;
                meter.isPrepaid = e.target.checked;
                return newMeters;
              });
            }}
          />
        </div>
      </td>
      <td className="px-4 py-4 align-top border-t border-stone-300">
        {meter?.readings?.length === 3 ? (
          <div className="flex gap-1">
            {isPrepaid && <p className="font-semibold text-rose-600">£</p>}
            <div className="flex flex-col gap-1">
              {!isPrepaid ? (
                <input
                  value={meter?.readings?.[0]}
                  placeholder={isPrepaid ? "123.45" : "A 123456"}
                  className="w-fit placeholder:text-stone-400 focus:outline-none"
                  onChange={e => {
                    setMeters(prev => {
                      let value = e.target.value;

                      const newMeters = [...prev];

                      let newReadings = meter.readings;
                      newReadings[0] = value;

                      meter.readings = newReadings.filter(reading => reading !== ",");

                      return newMeters;
                    });
                  }}
                />
              ) : (
                <CurrencyInput
                  placeholder="123.45"
                  defaultValue={meter?.readings?.[0]}
                  decimalsLimit={2}
                  onValueChange={(value, name, values) => {
                    setMeters(meters => {
                      const newMeters = [...meters];

                      const meter = newMeters.find(meter => meter.name === metername);

                      let newReadings = meter.readings;
                      newReadings[0] = value;

                      meter.readings = newReadings;

                      return newMeters;
                    });
                  }}
                />
              )}
              {!isPrepaid && (
                <>
                  <input
                    value={meter?.readings?.[1]}
                    placeholder={isPrepaid ? "123.45 (B)" : "B 123456"}
                    className="w-fit placeholder:text-stone-400 focus:outline-none"
                    onChange={e => {
                      setMeters(prev => {
                        const newMeters = [...prev];
                        const formattedValue = isPrepaid ? formatCurrencyInput(e.target.value) : e.target.value;

                        const meter = newMeters.find(meter => meter.name === metername);

                        let newReadings = meter.readings;
                        newReadings[1] = formattedValue;

                        meter.readings = newReadings;
                        return newMeters;
                      });
                    }}
                  />
                  <input
                    value={meter?.readings?.[2]}
                    placeholder={isPrepaid ? "123.45 (C)" : "C 123456"}
                    className="w-fit placeholder:text-stone-400 focus:outline-none"
                    onChange={e => {
                      setMeters(prev => {
                        const newMeters = [...prev];
                        const formattedValue = isPrepaid ? formatCurrencyInput(e.target.value) : e.target.value;

                        const meter = newMeters.find(meter => meter.name === metername);

                        let newReadings = meter.readings;
                        newReadings[2] = formattedValue;

                        meter.readings = newReadings;
                        return newMeters;
                      });
                    }}
                  />
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="flex gap-1">
            {isPrepaid && <p className="font-semibold text-rose-600">£</p>}
            <input
              value={meter?.readings?.[0]}
              placeholder={isPrepaid ? "123.45" : "123456"}
              className="w-fit placeholder:text-stone-400 focus:outline-none"
              onChange={e => {
                setMeters(prev => {
                  const newMeters = [...prev];
                  const formattedValue = isPrepaid ? formatCurrencyInput(e.target.value) : e.target.value;

                  const meter = newMeters.find(meter => meter.name === metername);

                  let newReadings = [formattedValue];

                  meter.readings = newReadings;
                  return newMeters;
                });
              }}
            />
          </div>
        )}
      </td>
      <td className="px-4 py-4 align-top bg-transparent border-t border-stone-300 placeholder:text-stone-400">
        <input
          value={location}
          onChange={e => {
            setMeters(prev => {
              const newMeters = [...prev];
              const meter = newMeters.find(meter => meter.name === metername);
              meter.location = e.target.value;
              return newMeters;
            });
          }}
          placeholder="Add the location..."
          className="bg-transparent disabled:bg-transparent w-fit placeholder:text-stone-400 focus:outline-none"
        />
      </td>
      <td className="px-4 py-4 align-top border-t border-stone-300 placeholder:text-stone-400">
        <input
          value={comment}
          onChange={e => {
            setMeters(prev => {
              const newMeters = [...prev];
              const meter = newMeters.find(meter => meter.name === metername);
              meter.comment = e.target.value;
              return newMeters;
            });
          }}
          placeholder="Add a comment..."
          className="w-fit placeholder:text-stone-400 focus:outline-none"
        />
      </td>
      <td className="px-4 py-4 border-t border-stone-300">
        <button
          className="text-red-600"
          onClick={() => {
            setMeters(prev => prev.filter(meter => meter.name !== metername));
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
            <path
              fillRule="evenodd"
              d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </td>
    </tr>
  );
}

function CheckoutImageThumb({ folder, v, isSaved, file, index, selectedCheckoutUUIDs, setSelectedCheckoutUUIDs }) {
  const isSelected = selectedCheckoutUUIDs?.includes(`${file.uuid}-${folder}-${v}`) || false;

  if (isSaved && !isSelected) {
    return null;
  }

  return (
    <div className="relative select-none aspect-square" key={index}>
      <img
        loading="lazy"
        className="block object-cover w-full h-full min-w-0 border rounded-sm shadow-sm hover:cursor-pointer bg-stone-200 border-stone-300"
        src={URL.createObjectURL(file)}
        onClick={() => {
          window.open(URL.createObjectURL(file), "_blank");
        }}
      />
      {!isSelected && !isSaved ? (
        <button
          className="absolute w-5 h-5 bg-white border rounded-full shadow-xl focus:outline-none -top-1 -right-1 border-stone-300 hover:bg-stone-100"
          onClick={() => {
            setSelectedCheckoutUUIDs(prev => [...new Set([...prev, `${file.uuid}-${folder}-${v}`])]);
          }}
        />
      ) : !isSaved ? (
        <button
          className="absolute flex items-center justify-center w-5 h-5 bg-white border rounded-full shadow-xl focus:outline-none -top-1 -right-1 border-stone-300 hover:bg-stone-100"
          onClick={() => {
            setSelectedCheckoutUUIDs(prev => [...new Set(prev.filter(uuid => uuid !== `${file.uuid}-${folder}-${v}`))]);
          }}
        >
          <div className="w-3.5 h-3.5 rounded-full bg-rose-600"></div>
        </button>
      ) : null}
    </div>
  );
}

function RoomRow({ room, index, setExtraRooms, customFolders, rooms }) {
  if (typeof room?.name !== "string" || room?.name === "") return null;

  const isCombustable = room?.isCombustable;
  const roomName = typeof room?.name === "string" ? room.name : typeof room === "string" ? room : "";
  const comment = room?.comment;
  const isPresent = room?.isPresent;
  const isTested = room?.isTested;
  const isWorking = room?.isWorking;
  const location = room?.location;

  return (
    <tr>
      <td className="px-4 py-4 align-top border-t border-stone-300">
        <div className="w-fit placeholder:text-stone-400">
          <input
            disabled={rooms.includes(roomName)}
            value={roomName}
            className="bg-transparent w-fit placeholder:text-stone-400 disabled:bg-transparent focus:outline-none"
            onChange={e => {
              setExtraRooms(prev => {
                const newRooms = [...prev];
                const newName = e.target.value;
                if (rooms.includes(newName)) {
                  return newRooms;
                }

                const room = newRooms.find(room => room.name === roomName);
                if (room) {
                  room.name = newName;
                }

                return newRooms;
              });
            }}
          />
        </div>
      </td>
      <td className="px-4 py-4 align-top border-t border-stone-300">
        <input
          className="focus:outline-none"
          type="checkbox"
          name="present"
          checked={isCombustable}
          onChange={e => {
            setExtraRooms(prev => {
              const newRooms = [...prev];
              const room = newRooms.find(room => room.name === roomName);
              room.isCombustable = e.target.checked;

              if (!e.target.checked) {
                room.isPresent = false;
                room.isTested = false;
                room.isWorking = false;
              }

              return newRooms;
            });
          }}
        />
      </td>
      <td className="px-4 py-4 align-top border-t border-stone-300">
        <input
          className={`focus:outline-none ${!isCombustable ? "cursor-not-allowed" : ""}`}
          disabled={!isCombustable}
          type="checkbox"
          name="tested"
          checked={isCombustable ? isPresent : false}
          onChange={e => {
            setExtraRooms(prev => {
              const newRooms = [...prev];
              const room = newRooms.find(room => room.name === roomName);
              room.isPresent = e.target.checked;

              if (!e.target.checked) {
                room.isTested = false;
                room.isWorking = false;
              }

              return newRooms;
            });
          }}
        />
      </td>
      <td className="px-4 py-4 align-top border-t border-stone-300">
        <input
          className={`${!isCombustable || !isPresent ? "cursor-not-allowed" : ""}`}
          disabled={!isCombustable || !isPresent}
          type="checkbox"
          name="tested"
          checked={isCombustable ? isTested : false}
          onChange={e => {
            setExtraRooms(prev => {
              const newRooms = [...prev];
              const room = newRooms.find(room => room.name === roomName);
              room.isTested = e.target.checked;

              if (!e.target.checked) {
                room.isWorking = false;
              }

              return newRooms;
            });
          }}
        />
      </td>
      <td className="px-4 py-4 align-top border-t border-stone-300">
        <input
          className={`${!isPresent || !isTested ? "cursor-not-allowed" : ""}`}
          disabled={!isPresent || !isTested}
          type="checkbox"
          name="working"
          checked={isPresent ? isWorking : false}
          onChange={e => {
            setExtraRooms(prev => {
              const newRooms = [...prev];
              const room = newRooms.find(room => room.name === roomName);
              room.isWorking = e.target.checked;
              return newRooms;
            });
          }}
        />
      </td>
      <td className="px-4 py-4 align-top border-t border-stone-300 placeholder:text-stone-400">
        <input
          value={location}
          disabled={!isCombustable}
          onChange={e => {
            setExtraRooms(prev => {
              const newRooms = [...prev];
              const room = newRooms.find(room => room.name === roomName);
              room.location = e.target.value;
              return newRooms;
            });
          }}
          placeholder="Add the location..."
          className="bg-transparent w-fit placeholder:text-stone-400"
        />
      </td>
      <td className="px-4 py-4 align-top bg-transparent border-t disabled:bg-white border-stone-300 placeholder:text-stone-400">
        <input
          disabled={!isCombustable}
          value={comment}
          onChange={e => {
            setExtraRooms(prev => {
              const newRooms = [...prev];
              const room = newRooms.find(room => room.name === roomName);
              room.comment = e.target.value;
              return newRooms;
            });
          }}
          placeholder="Add a comment..."
          className="bg-transparent w-fit placeholder:text-stone-400"
        />
      </td>
      <td className="px-4 py-4 align-top border-t border-stone-300">
        {!customFolders?.includes(roomName) && !rooms.includes(roomName) && (
          <button
            className="text-red-600"
            onClick={() => {
              setExtraRooms(prev => {
                return prev.filter(room => room.name !== roomName);
              });
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
              <path
                fillRule="evenodd"
                d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        )}
      </td>
    </tr>
  );
}
