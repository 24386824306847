import { useEffect, useLayoutEffect, useState } from "react";
import { Menu } from "./Menu";
import axios from "axios";
import { Link } from "react-router-dom";
import PacmanLoader from "react-spinners/PacmanLoader";

export function Reports() {
  const [user, setUser] = useState({});
  const isAdmin =
    user?.email?.endsWith("@huntleys.net") &&
    (user?.email ===
      [
        "t",
        "e",
        "n",
        ".",
        "s",
        "y",
        "e",
        "l",
        "t",
        "n",
        "u",
        "h",
        "@",
        "w",
        "e",
        "r",
        "d",
        "n",
        "a",
      ]
        .reverse()
        .join("") ||
      user?.email ===
        [
          "t",
          "e",
          "n",
          ".",
          "s",
          "y",
          "e",
          "l",
          "t",
          "n",
          "u",
          "h",
          "@",
          "r",
          "e",
          "f",
          "y",
          "k",
        ]
          .reverse()
          .join(""));

  useLayoutEffect(() => {
    const user = localStorage.getItem("user");

    if (user) {
      setUser(JSON.parse(user));
    }
  }, []);

  const [inventories, setInventories] = useState([]);
  const [sort, setSort] = useState("newest");

  const [isLoading, setIsLoading] = useState(true);

  const [invLimit, setInvLimit] = useState(10);

  useEffect(() => {
    async function getInventories() {
      try {
        const response = await axios.get(
          "https://api.propertyjot.com/inventories",
          {
            headers: { Authorization: localStorage.getItem("token") },
          }
        );

        if (response.status === 200) {
          if (
            response.data &&
            Array.isArray(response.data) &&
            response.data.length > 0
          ) {
            const objs = response.data.map((v, i) => {
              return {
                id: v.id,
                uuid: v.uuid,
                data: JSON.parse(v.data),
                archived: v.archived,
              };
            });

            objs.forEach((v, i) => {
              let count = 0;

              if (v.data?.folders) {
                const folders = Object.keys(v.data.folders);

                for (let i = 0; i < folders.length; i++) {
                  count += v.data.folders[folders[i]].length;
                }

                v.photoCount = count;
              }
            });

            setInventories(objs);
          }
        }
      } catch (err) {
        console.log(err);
      }

      setIsLoading(false);
    }

    try {
      getInventories();
    } catch (err) {
      console.log(err);
    }
  }, []);

  const archivedInventories = inventories?.filter(v => v?.archived === true);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-screen h-screen no-scrollbar">
        <PacmanLoader color="#e11d48" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-stone-50 no-scrollbar">
      <Menu />
      <div className="flex justify-between px-4 py-10 no-scrollbar">
        <div className="justify-between w-full max-w-screen-sm gap-3 mx-auto md:max-w-screen-md lg:max-w-screen-lg no-scrollbar">
          <h1 className="text-3xl font-medium text=stone-950">Reports</h1>
          <p className="mt-2 text-sm text-stone-500">
            Here's a list of all your reports that haven't been archived.
            They're sorted from newest to oldest by default. Archived
            inventories are shown below separately.
            {inventories?.length
              ? ` Currently, you have ${inventories.length} inventories.`
              : ""}
          </p>
          <div className="flex flex-col w-full gap-6 mt-4">
            <table className="table">
              <thead>
                <tr className="border-b border-stone-300">
                  <th className="py-2 text-left">Property</th>
                  <th className="py-2 text-left">Type</th>
                  <th className="py-2 text-left">Date</th>
                  <th className="py-2 text-left">
                    <button
                      className="flex items-center gap-1"
                      onClick={() => {
                        if (sort?.startsWith("photos")) {
                          if (sort?.endsWith("largest")) {
                            setSort("newest");
                            setInventories(inventories =>
                              inventories.sort(
                                (a, b) =>
                                  new Date(b.data.date) - new Date(a.data.date)
                              )
                            );
                          } else if (sort?.endsWith("smallest")) {
                            setSort("photos_largest");
                            setInventories(inventories =>
                              inventories.sort(
                                (a, b) => b.photoCount - a.photoCount
                              )
                            );
                          }
                        } else {
                          setSort("photos_smallest");
                          setInventories(inventories =>
                            inventories.sort(
                              (a, b) => a.photoCount - b.photoCount
                            )
                          );
                        }
                      }}
                    >
                      <p>Photos</p>
                      {sort === "photos_smallest" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : sort === "photos_largest" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            fillRule="evenodd"
                            d="M9.47 6.47a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.06l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : (
                        <div className="w-5 h-5" />
                      )}
                    </button>
                  </th>
                  <th className="py-2 text-left">Tools</th>
                </tr>
              </thead>
              <tbody>
                {inventories
                  ?.filter(v => !v?.archived)
                  ?.slice(0, invLimit)
                  ?.map((v, i) => (
                    <tr
                      key={v?.uuid}
                      className={
                        i !== invLimit - 1 || i !== inventories.length - 1
                          ? "border-b text-stone-500 border-stone-300"
                          : "text-stone-500"
                      }
                    >
                      <td className="py-2 text-sm">
                        <div>
                          <Link
                            className="transition-colors hover:text-stone-950"
                            to={`/property/${v?.data?.property?.id}`}
                          >
                            <div>
                              {v.data?.property?.address
                                ?.split(",")
                                ?.slice(0, 3)
                                .map((v, i) => (
                                  <p key={i}>{v.trim()}</p>
                                ))}
                            </div>
                          </Link>
                        </div>
                      </td>
                      <td className="py-2 text-sm">
                        {v.data?.type === "inventory" ? (
                          <span className="px-2 py-1.5 font-semibold text-white rounded-sm shadow-sm bg-rose-500 border-rose-600">
                            Inventory
                          </span>
                        ) : (
                          <span className="px-2 py-1.5 font-semibold text-white rounded-sm shadow-sm bg-indigo-500 border-indigo-600">
                            Checkout
                          </span>
                        )}
                      </td>
                      <td className="py-2 text-sm">{v.data?.date}</td>
                      <td className="py-2 text-sm">{v?.photoCount || 0}</td>
                      <td className="py-2 text-sm">
                        <div className="flex gap-2">
                          <Link
                            target="_blank"
                            to={`/property/${v?.data?.property?.id}`}
                            className="flex items-center justify-center w-8 h-8 border rounded-full shadow-sm bg-rose-500 border-rose-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-4 h-4 text-white"
                            >
                              <path d="M19.006 3.705a.75.75 0 1 0-.512-1.41L6 6.838V3a.75.75 0 0 0-.75-.75h-1.5A.75.75 0 0 0 3 3v4.93l-1.006.365a.75.75 0 0 0 .512 1.41l16.5-6Z" />
                              <path
                                fillRule="evenodd"
                                d="M3.019 11.114 18 5.667v3.421l4.006 1.457a.75.75 0 1 1-.512 1.41l-.494-.18v8.475h.75a.75.75 0 0 1 0 1.5H2.25a.75.75 0 0 1 0-1.5H3v-9.129l.019-.007ZM18 20.25v-9.566l1.5.546v9.02H18Zm-9-6a.75.75 0 0 0-.75.75v4.5c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75V15a.75.75 0 0 0-.75-.75H9Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </Link>
                          <Link
                            target="_blank"
                            to={`https://static.propertyjot.com/${
                              v?.uuid
                            }/${v?.data?.property?.address
                              ?.split(",")[0]
                              ?.trim()}.pdf`}
                            className="flex items-center justify-center w-8 h-8 border rounded-full shadow-sm bg-rose-500 border-rose-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-4 h-4 text-white"
                            >
                              <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625Z" />
                              <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                            </svg>
                          </Link>
                          {true && (
                            <button
                              disabled={!isAdmin}
                              onClick={() => {
                                try {
                                  axios.post(
                                    "https://api.propertyjot.com/inventory/archive",
                                    {
                                      uuid: v.uuid,
                                    },
                                    {
                                      headers: {
                                        Authorization:
                                          localStorage.getItem("token"),
                                      },
                                    }
                                  );

                                  setInventories(inventories => {
                                    const newInventories = [...inventories];
                                    const index = newInventories.findIndex(
                                      inv => inv.uuid === v.uuid
                                    );

                                    newInventories[index].archived = true;
                                    return newInventories;
                                  });
                                } catch (err) {
                                  console.log(err);
                                }
                              }}
                              className={
                                isAdmin
                                  ? "flex items-center justify-center w-8 h-8 border rounded-full shadow-sm bg-rose-500 border-rose-600"
                                  : "flex items-center justify-center w-8 h-8 border rounded-full bg-stone-300 border-stone-300"
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className={
                                  isAdmin
                                    ? "w-5 h-5 text-white"
                                    : "w-5 h-5 text-stone-400"
                                }
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {invLimit < inventories.length && (
              <div className="flex items-center justify-center">
                <button
                  onClick={() => {
                    setInvLimit(prev => prev + 10);
                  }}
                  className="px-3 py-1 text-sm font-medium text-white rounded-full shadow-sm border-rose-600 bg-rose-500"
                >
                  Load more...
                </button>
              </div>
            )}
          </div>
          {true && (
            <>
              <h1 className="text-3xl font-medium text=stone-950 mt-12">
                Archived
              </h1>
              <p className="mt-2 text-sm text-stone-500">
                Here's a list of all your archived inventories. These are
                deleted 30 days after being archived.
              </p>
              <div className="flex flex-col w-full gap-6 mt-4 mb-16">
                <table className="table">
                  <thead>
                    <tr className="border-b border-stone-300">
                      <th className="py-2 text-left">Property</th>
                      <th className="py-2 text-left">Type</th>
                      <th className="py-2 text-left">Date</th>
                      <th className="py-2 text-left">
                        <button
                          className="flex items-center gap-1"
                          onClick={() => {
                            if (sort?.startsWith("photos")) {
                              if (sort?.endsWith("largest")) {
                                setSort("newest");
                                setInventories(inventories =>
                                  inventories.sort(
                                    (a, b) =>
                                      new Date(b.data.date) -
                                      new Date(a.data.date)
                                  )
                                );
                              } else if (sort?.endsWith("smallest")) {
                                setSort("photos_largest");
                                setInventories(inventories =>
                                  inventories.sort(
                                    (a, b) => b.photoCount - a.photoCount
                                  )
                                );
                              }
                            } else {
                              setSort("photos_smallest");
                              setInventories(inventories =>
                                inventories.sort(
                                  (a, b) => a.photoCount - b.photoCount
                                )
                              );
                            }
                          }}
                        >
                          <p>Photos</p>
                          {sort === "photos_smallest" ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          ) : sort === "photos_largest" ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                fillRule="evenodd"
                                d="M9.47 6.47a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.06l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          ) : (
                            <div className="w-5 h-5" />
                          )}
                        </button>
                      </th>
                      <th className="py-2 text-left">Tools</th>
                    </tr>
                  </thead>
                  <tbody>
                    {archivedInventories?.map((v, i) => (
                      <tr
                        key={v?.uuid}
                        className={
                          i !== archivedInventories.length - 1
                            ? "border-b text-stone-500 border-stone-300"
                            : "text-stone-500"
                        }
                      >
                        <td className="py-2 text-sm">
                          <div>
                            <Link
                              className="transition-colors hover:text-stone-950"
                              to={`/property/${v?.data?.property?.id}`}
                            >
                              <div>
                                {v.data?.property?.address
                                  ?.split(",")
                                  ?.slice(0, 3)
                                  .map((v, i) => (
                                    <p key={i}>{v.trim()}</p>
                                  ))}
                              </div>
                            </Link>
                          </div>
                        </td>
                        <td className="py-2 text-sm">
                          {v.data?.type === "inventory" ? (
                            <span className="px-2 py-1.5 font-semibold text-white rounded-sm shadow-sm bg-rose-500 border-rose-600">
                              Inventory
                            </span>
                          ) : (
                            <span className="px-2 py-1.5 font-semibold text-white rounded-sm shadow-sm bg-indigo-500 border-indigo-600">
                              Checkout
                            </span>
                          )}
                        </td>
                        <td className="py-2 text-sm">{v.data?.date}</td>
                        <td className="py-2 text-sm">{v?.photoCount || 0}</td>
                        <td className="py-2 text-sm">
                          <div className="flex gap-2">
                            <Link
                              target="_blank"
                              to={`/property/${v?.data?.property?.id}`}
                              className="flex items-center justify-center w-8 h-8 border rounded-full shadow-sm bg-rose-500 border-rose-600"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-4 h-4 text-white"
                              >
                                <path d="M19.006 3.705a.75.75 0 1 0-.512-1.41L6 6.838V3a.75.75 0 0 0-.75-.75h-1.5A.75.75 0 0 0 3 3v4.93l-1.006.365a.75.75 0 0 0 .512 1.41l16.5-6Z" />
                                <path
                                  fillRule="evenodd"
                                  d="M3.019 11.114 18 5.667v3.421l4.006 1.457a.75.75 0 1 1-.512 1.41l-.494-.18v8.475h.75a.75.75 0 0 1 0 1.5H2.25a.75.75 0 0 1 0-1.5H3v-9.129l.019-.007ZM18 20.25v-9.566l1.5.546v9.02H18Zm-9-6a.75.75 0 0 0-.75.75v4.5c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75V15a.75.75 0 0 0-.75-.75H9Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </Link>
                            <Link
                              target="_blank"
                              to={`https://static.propertyjot.com/${
                                v?.uuid
                              }/${v?.data?.property?.address
                                ?.split(",")[0]
                                ?.trim()}.pdf`}
                              className="flex items-center justify-center w-8 h-8 border rounded-full shadow-sm bg-rose-500 border-rose-600"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-4 h-4 text-white"
                              >
                                <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625Z" />
                                <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                              </svg>
                            </Link>
                            {true && (
                              <button
                                disabled={!isAdmin}
                                onClick={() => {
                                  // unarchive
                                  try {
                                    axios.post(
                                      "https://api.propertyjot.com/inventory/archive",
                                      {
                                        uuid: v.uuid,
                                      },
                                      {
                                        headers: {
                                          Authorization:
                                            localStorage.getItem("token"),
                                        },
                                      }
                                    );

                                    setInventories(inventories => {
                                      const newInventories = [...inventories];
                                      const index = newInventories.findIndex(
                                        inv => inv.uuid === v.uuid
                                      );

                                      newInventories[index].archived = false;
                                      return newInventories;
                                    });
                                  } catch (err) {
                                    console.log(err);
                                  }
                                }}
                                className={
                                  isAdmin
                                    ? "flex items-center justify-center w-8 h-8 border rounded-full shadow-sm bg-rose-500 border-rose-600"
                                    : "flex items-center justify-center w-8 h-8 border rounded-full bg-stone-300 border-stone-300"
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className={
                                    isAdmin
                                      ? "w-5 h-5 text-white"
                                      : "w-5 h-5 text-stone-400"
                                  }
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                                  />
                                </svg>
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* {invLimit < inventories.length && (
                  <div className="flex items-center justify-center">
                    <button
                      onClick={() => {
                        setInvLimit(prev => prev + 10);
                      }}
                      className="px-3 py-1 text-sm font-medium text-white rounded-full shadow-sm border-rose-600 bg-rose-500"
                    >
                      Load more...
                    </button>
                  </div>
                )} */}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
